import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  padding: 0.8rem 0;
  border-radius: 0.3rem;
  background: var(--white);

  > h2 {
    font-size: 0.8rem;
    font-weight: normal;
    text-transform: uppercase;
    color: var(--gray-60);
    padding: 0 1rem;
    margin-bottom: 0.5rem;
  }
`
export const TemplateContainer = styled.div``

export const Template = styled.button`
  display: flex;
  width: 100%;
  flex-direction: column;
  background: transparent;
  text-align: left;
  padding: 0.4rem 1rem;
  background: var(--white);

  &:hover {
    background: var(--gray-5);
  }

  h3 {
    color: var(--black);
    font-size: 0.85rem;
  }

  p {
    color: var(--gray-60);
    font-size: 0.8rem;
  }

  & + button {
    margin-top: 0.5rem;
  }
`
