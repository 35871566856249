import React, { createContext, Dispatch, ReactNode, SetStateAction, useContext, useState } from 'react'
import { useImmerReducer } from 'use-immer'

import {
  BlockType,
  BuildingBlock,
  LibraryMaterials,
  LibraryMaterialsSection,
  MaterialsBeforeClass
} from '@somostera/tera-database'

import { OrderByName } from 'core/utils/object'

import { findAllBuildingBlock, findBuildingBlockById } from 'modules/buildingblock/services/build'
import { buildingblockReducer, State } from 'modules/buildingblock/reducers/building'
import { TextBeforeClass } from '@somostera/tera-database/dist/BuildBlock/Domain/types/TextBeforeClass'

export interface MediaData {
  id: string
  url: string
  name: string
  size: string
  type: string
}

export interface LibraryMaterialsWithId extends LibraryMaterials {
  id: string
}

export interface Dictionary {
  [key: string]: string
}

interface BuildingBlockContextData {
  isLoading: boolean
  buildingblock: BuildingBlock[]
  getAllBuildingBlock: () => Promise<void>
  updateBuildingBlock: (selectedRows: BuildingBlock[]) => void
  selectedRows: BuildingBlock[]
  setLoading: (isLoading: boolean) => void
  detailsName: string
  setDetailsName: Dispatch<SetStateAction<string>>
  detailsTag: string[]
  setDetailsTag: Dispatch<SetStateAction<string[]>>
  outcomesTag: string[]
  setOutcomesTag: Dispatch<SetStateAction<string[]>>
  detailsDescription: string
  setDetailsDescription: Dispatch<SetStateAction<string>>
  contentDescription: string
  setContentDescription: Dispatch<SetStateAction<string>>
  files: MediaData[]
  filesOldContent: TextBeforeClass[]
  contentAfterClass: string | undefined
  setContentAfterClass: Dispatch<SetStateAction<string>>
  setFiles: Dispatch<SetStateAction<MediaData[]>>
  filesUpload: LibraryMaterialsWithId[]
  setFilesUpload: Dispatch<SetStateAction<LibraryMaterialsWithId[]>>
  filesClass: LibraryMaterialsWithId[]
  setFilesClass: Dispatch<SetStateAction<LibraryMaterialsWithId[]>>
  filesComplementary: LibraryMaterialsWithId[]
  setFilesComplementary: Dispatch<SetStateAction<LibraryMaterialsWithId[]>>
  setFilesOldContent: Dispatch<SetStateAction<TextBeforeClass[]>>
  clearFormInfo: () => void
  detailsPageChange: boolean
  setDetailsPageChange: Dispatch<SetStateAction<boolean>>
  contentsPageChange: boolean
  setContentsPageChange: Dispatch<SetStateAction<boolean>>
  canShowDialogLeavingPage: boolean
  setCanShowDialogLeavingPage: Dispatch<SetStateAction<boolean>>
  handleSave: boolean
  setHandleSave: Dispatch<SetStateAction<boolean>>
  findBlockById: (blockId: string) => Promise<void>
  prepareDataToSave: (blockType: string | undefined) => BuildingBlock
}

interface BuildProviderProps {
  children: ReactNode
}

const BuildingBlockContext = createContext<BuildingBlockContextData>({} as BuildingBlockContextData)

export const BuildProvider = ({ children }: BuildProviderProps) => {
  const [detailsName, setDetailsName] = useState('')
  const [detailsTag, setDetailsTag] = useState<string[]>([])
  const [outcomesTag, setOutcomesTag] = useState<string[]>([])
  const [detailsDescription, setDetailsDescription] = useState('')
  const [contentDescription, setContentDescription] = useState('')

  const [files, setFiles] = useState<MediaData[]>([] as MediaData[])
  const [filesUpload, setFilesUpload] = useState<LibraryMaterialsWithId[]>([] as LibraryMaterialsWithId[])
  const [filesClass, setFilesClass] = useState<LibraryMaterialsWithId[]>([] as LibraryMaterialsWithId[])
  const [filesComplementary, setFilesComplementary] = useState<LibraryMaterialsWithId[]>([] as LibraryMaterialsWithId[])
  const [filesOldContent, setFilesOldContent] = useState<TextBeforeClass[]>([] as TextBeforeClass[])
  const [contentAfterClass, setContentAfterClass] = useState('')

  const [detailsPageChange, setDetailsPageChange] = useState(false)
  const [contentsPageChange, setContentsPageChange] = useState(false)

  const [canShowDialogLeavingPage, setCanShowDialogLeavingPage] = useState(false)
  const [handleSave, setHandleSave] = useState(false)

  const [{ isLoading, buildingblock, selectedRows }, setState] = useImmerReducer(buildingblockReducer, {
    isLoading: true,
    teraId: '',
    buildingblock: [] as BuildingBlock[],
    selectedRows: [] as BuildingBlock[],
    buildingBlockToUpdate: [] as BuildingBlock[]
  } as State)

  async function findBlockById(blockId: string) {
    setState({ type: 'ASYNC_CALL' })
    try {
      const buildingBlock = await findBuildingBlockById(blockId)

      const materialsBeforeClass =
        buildingBlock.contentBeforeClass &&
        buildingBlock.contentBeforeClass.map((beforeClass) => 'materials' in beforeClass && beforeClass)

      const oldMaterialsBeforeClass: TextBeforeClass[] = []
      if (buildingBlock.contentBeforeClass) {
        buildingBlock.contentBeforeClass.forEach((beforeClass) => {
          if ('title' in beforeClass && beforeClass) {
            oldMaterialsBeforeClass.push(beforeClass)
          }
        })
      }

      setFilesOldContent(oldMaterialsBeforeClass)

      if (buildingBlock.contentAfterClass) {
        setContentAfterClass(buildingBlock.contentAfterClass)
      }

      // const materialsClass  =
      //   buildingBlock.LibraryMaterials &&
      //   buildingBlock.LibraryMaterials.map((filesClass) => 'files' in filesClass && filesClass)

      const filesUploadBeforeClass: LibraryMaterialsWithId[] =
        (materialsBeforeClass &&
          materialsBeforeClass[0] &&
          materialsBeforeClass[0].materials.map((material, index) => ({
            id: `${index}`,
            ...material
          }))) ||
        []

      const classMaterials =
        buildingBlock?.downloads?.filter(
          (download) =>
            download.section === LibraryMaterialsSection.CLASS_MATERIALS || typeof download.section === 'undefined'
        ) || []

      const classMaterialWithId = classMaterials.map(
        (material, index) =>
          ({
            id: `${index}`,
            ...material
          } as LibraryMaterialsWithId)
      )

      const deepeningMaterials =
        buildingBlock?.downloads?.filter(
          (download) => download.section === LibraryMaterialsSection.DEEPENING_MATERIALS
        ) || []

      const deepeningMaterialWithId = deepeningMaterials.map(
        (material, index) =>
          ({
            id: `${index}`,
            ...material
          } as LibraryMaterialsWithId)
      )

      setDetailsName(buildingBlock.name || '')
      setDetailsDescription(buildingBlock.description || '')
      setDetailsTag(buildingBlock.skills || [])
      setOutcomesTag(buildingBlock.outcomes || [])
      setContentDescription(materialsBeforeClass && materialsBeforeClass[0] ? materialsBeforeClass[0].text : '')
      setFiles(
        materialsBeforeClass &&
          materialsBeforeClass[0] &&
          materialsBeforeClass[0].image &&
          materialsBeforeClass[0].image.url
          ? [{ id: '0', ...materialsBeforeClass[0].image }]
          : []
      )
      setFilesUpload(filesUploadBeforeClass)
      setFilesClass(classMaterialWithId)
      setFilesComplementary(deepeningMaterialWithId)

      setState({ type: 'ASYNC_CALL_SUCCEED' })
    } catch (error) {
      setState({ type: 'ASYNC_CALL_FAILED', payload: { error } })
      console.log(error)
    }
  }

  async function getAllBuildingBlock() {
    setState({ type: 'ASYNC_CALL' })
    try {
      const allBuildingBlock = await findAllBuildingBlock()
      const OrderBuildingBlock = OrderByName(allBuildingBlock)

      setState({ type: 'SET_BUILDINGBLOCK', payload: { buildingblock: OrderBuildingBlock } })
      setState({ type: 'ASYNC_CALL_SUCCEED' })
    } catch (error) {
      setState({ type: 'ASYNC_CALL_FAILED', payload: { error } })
      console.log(error)
    }
  }

  async function updateBuildingBlock(selectedRows: BuildingBlock[]) {
    setState({ type: 'UPDATE_SELECTED_ROWS', payload: { selectedRows } })
  }

  function setLoading(isLoading: boolean) {
    if (isLoading) {
      setState({ type: 'ASYNC_CALL' })
    } else {
      setState({ type: 'ASYNC_CALL_SUCCEED' })
    }
  }

  function clearFormInfo() {
    setDetailsTag([])
    setDetailsName('')
    setDetailsDescription('')
    setContentDescription('')
    setFiles([])
    setFilesUpload([])
    setFilesClass([])
    setFilesComplementary([])
    setFilesOldContent([])
  }

  function prepareDataToSave(blockType: string | undefined) {
    const contentBeforeClass: MaterialsBeforeClass[] = [
      {
        image: files[0] || '',
        text: contentDescription,
        materials: filesUpload.map((file) => ({
          name: file.name,
          url: file.url,
          type: file.type,
          sizeInMb: file.sizeInMb
        }))
      }
    ]

    const materialsClass: LibraryMaterials[] = filesClass.map((file) => ({
      name: file.name,
      url: file.url,
      type: file.type,
      sizeInMb: file.sizeInMb,
      section: LibraryMaterialsSection.CLASS_MATERIALS
    }))
    const deepeningClass: LibraryMaterials[] = filesComplementary.map((file) => ({
      name: file.name,
      url: file.url,
      type: file.type,
      sizeInMb: file.sizeInMb,
      section: LibraryMaterialsSection.DEEPENING_MATERIALS
    }))

    const typeDictionary: Dictionary = {
      Masterclass: BlockType.MASTERCLASS,
      'Tera debate': BlockType.TERA_DEBATE,
      'Estudo de caso': BlockType.MASTERCLASS_CASE_STUDY,
      Mentoria: BlockType.MENTORSHIP,
      Workshop: BlockType.WORKSHOP_ROLEPLAY,
      Artigos: BlockType.ARTICLE,
      Aula: BlockType.CLASS,
      'Ao vivo': BlockType.LIVE
    }

    return {
      name: detailsName,
      description: detailsDescription,
      skills: detailsTag,
      outcomes: outcomesTag,
      type: typeDictionary[blockType || 'Artigos'] as BlockType,
      contentBeforeClass,
      downloads: [...materialsClass, ...deepeningClass]
    } as BuildingBlock
  }

  return (
    <BuildingBlockContext.Provider
      value={{
        isLoading,
        buildingblock,
        getAllBuildingBlock,
        updateBuildingBlock,
        selectedRows,
        setLoading,
        detailsName,
        setDetailsName,
        detailsTag,
        setDetailsTag,
        outcomesTag,
        setOutcomesTag,
        detailsDescription,
        setDetailsDescription,
        contentDescription,
        setContentDescription,
        files,
        setFiles,
        filesUpload,
        setFilesUpload,
        filesClass,
        setFilesClass,
        filesComplementary,
        filesOldContent,
        contentAfterClass,
        setContentAfterClass,
        setFilesComplementary,
        setFilesOldContent,
        clearFormInfo,
        detailsPageChange,
        setDetailsPageChange,
        contentsPageChange,
        setContentsPageChange,
        canShowDialogLeavingPage,
        setCanShowDialogLeavingPage,
        handleSave,
        setHandleSave,
        findBlockById,
        prepareDataToSave
      }}
    >
      {children}
    </BuildingBlockContext.Provider>
  )
}

export const useBuildingBlock = (): BuildingBlockContextData => {
  return useContext(BuildingBlockContext)
}
