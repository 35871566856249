import axios from 'axios'

export const api = axios.create({
  baseURL: process.env.REACT_APP_FIREBASE_CLOUD_FUNCTIONS_URL
})

export const getExpertsToAllocate = async (
  teraId: string,
  token: string
): Promise<{ journeyBlockId: string; expertIdToAllocate: string }[]> => {
  try {
    const response = await api.get(`/bigQuery-expertCastingPrediction?teraId=${teraId}`, {
      headers: {
        __session: token
      }
    })
    return response.data
  } catch (error) {
    console.error(error)
    return []
  }
}
