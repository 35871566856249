import styled, { css } from 'styled-components'

interface ContainerProps {
  isFocused: boolean
  isInvalid: boolean
}

export const Container = styled.div<ContainerProps>`
  align-items: center;
  border: 1.5px solid var(--gray-80);
  border-radius: 0.25rem;
  display: flex;
  height: 2rem;

  ${(props) =>
    props.isFocused &&
    css`
      border-color: var(--blue-primary);
    `}

  ${(props) =>
    props.isInvalid &&
    css`
      border-color: var(--orange);
    `}

  input {
    background: transparent;
    border: 0;
    font-size: 0.875rem;
    line-height: 1rem;
    height: 100%;
    min-height: 2rem;
    outline: 0;
    width: 100%;

    &::placeholder {
      color: var(--gray-60);
    }
  }

  svg {
    margin: 0 0.25rem;
    // position: absolute;
    // left: 0.625rem;
    // top: calc(50% - 12px);

    &:hover {
      filter: brightness(0.8);
    }
  }
`

export const InputInformationContainer = styled.div`
  align-items: baseline;
  display: flex;
  justify-content: space-between;

  label {
    color: var(--gray-80);
    display: block;
    font-weight: bold;
    font-size: 1rem;
    line-height: 1.25rem;
    text-transform: uppercase;
    margin-top: 1.5rem;
  }

  p {
    color: var(--gray-80);
    font-weight: 300;
    font-size: 1rem;
    line-height: 1.25rem;
  }
`

export const Error = styled.p`
  color: var(--orange);
  font-size: 1rem;
  line-height: 1.25rem;
  margin-top: 0.5rem;
`
