import styled from 'styled-components'
import Modal from 'react-modal'
import { Tabs } from 'react-tabs'

export const Container = styled(Modal)`
  width: 41rem;
`

export const CustomTabs = styled(Tabs)`
  > ul {
    border-bottom: 1px solid var(--gray-20);
    display: flex;
    list-style: none;
    padding: 0.75rem 0 0.5rem 1rem;

    > li {
      background: var(--white);
      color: var(--gray-100);
      cursor: pointer;
      font-size: 1rem;
      height: 1.75rem;
      min-width: 3rem;
      padding: 0.25rem 0.5rem;
      text-align: center;

      & + li {
        margin-left: 0.5rem;
      }

      &.react-tabs__tab--selected {
        background: var(--gray-20);
        border-radius: 0.25rem;
        font-weight: bold;
      }

      svg {
        margin-right: 0.25rem;
      }
    }
  }
`

export const Form = styled.form``

export const UploadContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 21rem;
  max-height: 21rem;
  min-height: 20.25rem;
  overflow-y: auto;
  padding: 1.5rem 3rem;

  span {
    color: var(--gray-80);
    display: block;
    font-weight: bold;
    font-size: 1rem;
    line-height: 1.25rem;
    text-transform: uppercase;
  }

  div + span {
    margin-top: 1.5rem;
  }

  img {
    height: auto;
    margin: 1.5rem auto;
    width: 17rem;
  }

  video {
    margin: 1.5rem auto;
  }

  hr {
    background: var(--gray-20);
    height: 2px;
    margin: 2rem 0;
  }
`

export const ButtonsContainer = styled.div`
  align-items: center;
  border-top: 1px solid var(--gray-20);
  display: flex;
  justify-content: space-between;
  margin: 0;
  min-height: 5.25rem;
  padding: 1rem 4rem 2rem 3rem;
  width: 100%;

  > button {
    align-items: center;
    border-radius: 0.25rem;
    display: flex;
    font-size: 0.875rem;
    font-weight: bold;
    height: 3rem;
    justify-content: center;
    line-height: 120%;
    padding: 0.5rem 1rem;
    text-transform: uppercase;
    width: 8rem;
  }
`

export const ConfirmButton = styled.button`
  background: var(--blue-primary);
  color: var(--white);
`

export const CancelButton = styled.button`
  background: var(--white);
  border: 2px solid var(--gray-80);
`
