import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
  :root {
    --black: #000000;
    --gray-100: #383838;
    --gray-80: #575757;
    --gray-60: #929292;
    --gray-50: #4f4f4f;
    --gray-40: #bfbfbf;
    --gray-20: #e3e3e3;
    --gray-10: #f6f6f6;
    --gray-5: #f3f3f3;
    --white: #ffffff;
    --green-primary: #8dfe7e;
    --blue-primary: #04a0c3;
    --blue-to-purple: linear-gradient(98.68deg, #032bdb 0%, #1DEAFB 0.01%, #9200ec 89.06%);
    --green-to-blue: linear-gradient(98.68deg, #8dfe7e 0%, #8dfe7e 0.01%, #04a0c3 100%);
    --soft-green: linear-gradient(98.68deg, #8dfe7e 0.01%, #039682 100%)
    --soft-blue: linear-gradient(98.68deg, #00ddfa 0%, #1deafb 0.01%, #04a0c3 100%);
    --deep-blue: linear-gradient(98.68deg, #04a0c3 0%, #04a0c3 0.01%, <div id="032bdb"></div> 100%);
    --black-to-gray: linear-gradient(101.46deg, #000000 1.51%, #383838 99.39%);

    /* success */
    --green-60: #03ab5a;
    /* error */
    --red: #df1b00;
    /* attention */
    --yellow: #faae02;

    --orange: #f07300;
    --orange-20: #ff3600;
    --green-20: #55e800;
    --green-80: #008976;
    --blue-10: #BBE6FF;
    --blue-20: #00ddfa;
    --blue-60: #032bdb;
    --purple: #9200ec;
    --pink: #fa07f0;

    --blue-20-to-purple: linear-gradient(92.43deg, #00ddfa 0.25%, #9200ec 100%);
    --yellow-to-pink: linear-gradient(92.43deg, #faae02 0.25%, #fa07f0 100%);
    --green-pastel-to-blue-pastel: linear-gradient(92.43deg, #caffab 0.25%, #bbe6ff 100%);
    --blue-pastel-to-purple-pastel: linear-gradient(92.43deg, #b3e2fd 0.25%, #bbe6ff 0.26%, #eac9ff 100%);

    --yellow-pastel: #ffdc99;
    --orange-pastel: #ffdc99;
    --green-pastel: #caffab;
    --blue-pastel-opacity: rgba(187, 230, 255, 0.3);
    --blue-pastel: #bbe6ff;
    --purple-pastel: #eac9ff;

    --skin-20: #ffddba;
    --skin-40: #e2a851;
    --skin-60: #a53f1c;
    --skin-80: #783b04;
    --skin-100: #4b1705;

     //DESIGN SYSTEM COLORS

    --loContrast: 'hsl(0, 0%, 100%)';
    --hiContrast: 'hsl(0, 0%, 0%)';
    --contrast: 'hsl(0, 0%, 0%)';
    --link: 'hsl(113, 88%, 19%)';
    --focus: 'hsl(246, 51%, 47%)';
    --hover: 'hsl(112, 74%, 94%)';

    --wasabi: 'hsl(113, 98%, 75%)';
    --wasabiHighlight: 'hsl(113, 89%, 54%)';
    --wasabiLoContrast: 'hsl(113, 70%, 91%)';
    --wasabiHiContrast: 'hsl(113, 88%, 19%)';

    --neutral1: hsl(0, 0%, 99%);
    --neutral2: hsl(0, 0%, 97%);
    --neutral3: hsl(0, 0%, 95%);
    --neutral4: hsl(0, 0%, 93%);
    --neutral5: hsl(0, 0%, 91%);
    --neutral6: hsl(0, 0%, 89%);
    --neutral7: hsl(0, 0%, 86%);
    --neutral8: hsl(0, 0%, 78%);
    --neutral9: hsl(0, 0%, 56%);
    --neutral10: hsl(0, 0%, 52%);
    --neutral11: hsl(0, 0%, 44%);
    --neutral12: hsl(0, 0%, 9%);

    --success1: hsl(120, 60%, 99%);
    --success2: hsl(120, 60%, 97%);
    --success3: hsl(120, 54%, 95%);
    --success4: hsl(120, 45%, 91%);
    --success5: hsl(122, 42%, 86%);
    --success6: hsl(124, 38%, 80%);
    --success7: hsl(125, 37%, 70%);
    --success8: hsl(131, 38%, 56%);
    --success9: hsl(131, 41%, 46%);
    --success10: hsl(132, 43%, 42%);
    --success11: hsl(133, 50%, 32%);
    --success12: hsl(128, 29%, 15%);

    --error1: hsl(0, 100%, 99%);
    --error2: hsl(7, 100%, 98%);
    --error3: hsl(7, 100%, 97%);
    --error4: hsl(8, 100%, 94%);
    --error5: hsl(7, 91%, 91%);
    --error6: hsl(9, 86%, 86%);
    --error7: hsl(10, 77%, 79%);
    --error8: hsl(10, 72%, 71%);
    --error9: hsl(10, 78%, 54%);
    --error10: hsl(10, 72%, 50%);
    --error11: hsl(10, 82%, 44%);
    --error12: hsl(10, 51%, 14%);

    --warning1: hsl(40, 60%, 99%);
    --warning2: hsl(40, 100%, 96%);
    --warning3: hsl(44, 100%, 92%);
    --warning4: hsl(43, 100%, 87%);
    --warning5: hsl(42, 100%, 82%);
    --warning6: hsl(38, 100%, 76%);
    --warning7: hsl(36, 86%, 67%);
    --warning8: hsl(35, 85%, 55%);
    --warning9: hsl(39, 100%, 57%);
    --warning10: hsl(35, 100%, 55%);
    --warning11: hsl(30, 100%, 34%);
    --warning12: hsl(20, 79%, 17%);

  --black: hsl(0, 0%, 0%);
  --white: hsl(0, 0%, 100%);

  --dark1: hsl(0, 0%, 9%);
  --dark2: hsl(0, 0%, 11%);
  --dark3: hsl(0, 0%, 14%);
  --dark4: hsl(0, 0%, 16%);
  --dark5: hsl(0, 0%, 18%);
  --dark6: hsl(0, 0%, 20%);
  --dark7: hsl(0, 0%, 24%);
  --dark8: hsl(0, 0%, 31%);
  --dark9: hsl(0, 0%, 44%);
  --dark10: hsl(0, 0%, 49%);
  --dark11: hsl(0, 0%, 63%);
  --dark12: hsl(0, 0%, 93%);

  --light1: hsl(0, 0%, 99%);
  --light2: hsl(0, 0%, 97%);
  --light3: hsl(0, 0%, 95%);
  --light4: hsl(0, 0%, 93%);
  --light5: hsl(0, 0%, 91%);
  --light6: hsl(0, 0%, 89%);
  --light7: hsl(0, 0%, 86%);
  --light8: hsl(0, 0%, 78%);
  --light9: hsl(0, 0%, 56%);
  --light10: hsl(0, 0%, 52%);
  --light11: hsl(0, 0%, 44%);
  --light12: hsl(0, 0%, 9%);
  }

  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  html {
    @media (max-width: 1080px) {
      font-size: 93.75%; // 15px
    }

    @media (max-width: 720px) {
      font-size: 87.5%; // 14px
    }
  }

  body {
    background: #fcfcfc;
    color: var(--gray-100);
    -webkit-font-smoothing: antialiased;
  }

  body, input, textarea, button, select {
    font-family: 'Rubik', sans-serif;
    font-weight: 400;
  }

  button {
    border: none;
    cursor: pointer;
  }

  .react-modal-overlay {
    align-items: center;
    background: rgba(0, 0, 0, 0.4);
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 11;
  }

  .react-modal-content {
    background: var(--white);
    min-height: 192px;
   // height: 100%;
    max-width: 500px;
    width: 100%;
    position: relative;
    z-index: 12;
    left:  120px;
  }

  .react-modal-close {
    background: transparent;
    border: 0;
    position: absolute;
    right: 1rem;
    top: 1rem;
    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.8);
    }
  }

  .react-tooltip {
    background: var(--gray-100);
    border: 0;
    border-radius: 0.125rem;
    font-family: 'Rubik';
    font-size: 0.75rem;
    line-height: 0.875rem;
    padding: 4px 8px;

    p {
      color: var(--white);
    }

    span {
      color: var(--gray-60);
    }
  }
`
