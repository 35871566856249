import React, { useEffect, useState } from 'react'
import { InitializeQuizService, QuizOptions } from '@somostera/tera-database'

import { db } from 'core/config/firebase'

import { useToast } from 'core/hooks/useToast'

import { InformationModal as RemoveIncorrectOptionSuccessModal } from 'core/components/InformationModal'

import { Button } from 'core/components/Button'

import { QuizInput } from 'modules/contents/components/QuizInput'

import {
  Container,
  Question,
  Description,
  QuizInputContainer,
  ButtonContainer,
  NextButtonDisabledContainer
} from './styles'
import { PlusCircle } from '@somostera/tera-icons'

interface QuizTemplateProps {
  quizId?: string
}

export function QuizTemplate({ quizId }: QuizTemplateProps) {
  const quizService = InitializeQuizService.initialize(db)
  const { addToast } = useToast()

  const [showRemoveIncorrectOptionSuccessModal, setShowRemoveIncorrectOptionSuccessModal] = useState(false)

  const [showIncorrectOptional1, setShowIncorrectOptional1] = useState(false)
  const [showIncorrectOptional2, setShowIncorrectOptional2] = useState(false)

  const [question, setQuestion] = useState('')
  const [description, setDescription] = useState('')
  const [correctOption, setCorrectOption] = useState<QuizOptions>({} as QuizOptions)
  const [correctOptionComment, setCorrectOptionComment] = useState('')
  const [incorrectOption1, setIncorrectOption1] = useState<QuizOptions>({} as QuizOptions)
  const [incorrectOption2, setIncorrectOption2] = useState<QuizOptions>({} as QuizOptions)
  const [incorrectOption3, setIncorrectOption3] = useState<QuizOptions>({} as QuizOptions)
  const [incorrectOption4, setIncorrectOption4] = useState<QuizOptions>({} as QuizOptions)

  const handleAddIncorrectOption = async () => {
    if (showIncorrectOptional1) {
      if (quizId) {
        const quizOption = await quizService.addOptionToQuiz(quizId, [
          {
            option: '',
            itCorrect: false,
            index: 5
          }
        ])
        setShowIncorrectOptional2(true)
        setIncorrectOption4(quizOption[0])
      }
    } else {
      if (quizId) {
        const quizOption = await quizService.addOptionToQuiz(quizId, [
          {
            option: '',
            itCorrect: false,
            index: 4
          }
        ])
        setShowIncorrectOptional1(true)
        setIncorrectOption3(quizOption[0])
      }
    }
  }

  const quizData = {
    title: question,
    subtitle: description,
    comment: correctOptionComment
  }

  const quizOptions = [correctOption, incorrectOption1, incorrectOption2, incorrectOption3, incorrectOption4]

  const updateQuiz = async () => {
    if (quizId) {
      try {
        quizService.update(quizId, quizData)
      } catch (error) {
        console.error(error)
        addToast({
          type: 'error',
          title: 'Erro',
          description: 'Não foi possível atualizar as informações do Quiz'
        })
      }
    }
  }

  const updateQuizOptions = async () => {
    if (quizId) {
      const validOptions = quizOptions.filter(
        (quizOption) => JSON.stringify(quizOption) !== '{}' && quizOption?.option !== ''
      )
      try {
        await quizService.updateOptionToQuiz(quizId, validOptions)
      } catch (error) {
        console.error(error)
        addToast({
          type: 'error',
          title: 'Erro',
          description: 'Não foi possível atualizar as opções Quiz'
        })
      }
    }
  }

  useEffect(() => {
    setShowIncorrectOptional1(false)
    setIncorrectOption3({} as QuizOptions)
    setShowIncorrectOptional2(false)
    setIncorrectOption4({} as QuizOptions)

    async function getQuizInfo() {
      if (quizId) {
        try {
          const quiz = await quizService.findById(quizId)

          setQuestion(quiz.title)
          setDescription(quiz.subtitle)
          setCorrectOptionComment(quiz.comment)

          quiz.options?.forEach((option) => {
            switch (option.index) {
              case 1:
                setCorrectOption(option)
                break
              case 2:
                setIncorrectOption1(option)
                break
              case 3:
                setIncorrectOption2(option)
                break
              case 4:
                setIncorrectOption3(option)
                setShowIncorrectOptional1(true)
                break
              case 5:
                setIncorrectOption4(option)
                setShowIncorrectOptional2(true)
                break
            }
          })
        } catch (error) {
          console.error(error)
          addToast({
            type: 'error',
            title: 'Erro',
            description: 'Não foi possível carregar as informações do Quiz'
          })
        }
      }
    }
    getQuizInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quizId])

  return (
    <>
      <RemoveIncorrectOptionSuccessModal
        messageType="Confirmation"
        messageTitle="Alternativa deletada"
        isOpen={showRemoveIncorrectOptionSuccessModal}
        onRequestClose={() => setShowRemoveIncorrectOptionSuccessModal(false)}
      />
      <Container>
        <header>
          <Question
            name="question"
            placeholder="Escreva a pergunta"
            onChange={(event) => setQuestion(event.target.value)}
            value={question}
            onBlur={updateQuiz}
          />

          <Description
            name="description"
            placeholder="Adicione uma descrição/subtítulo para a pergunta..."
            onChange={(event) => setDescription(event.target.value)}
            value={description}
            onBlur={updateQuiz}
          />
        </header>

        <QuizInputContainer>
          <QuizInput
            label="alternativa correta"
            onChange={(event) =>
              setCorrectOption({
                ...correctOption,
                option: event.target.value
              })
            }
            value={correctOption.option}
            onBlur={updateQuizOptions}
            quizInfo={{ quizId, quizOptionId: correctOption.id }}
          />

          <QuizInput
            label="comentário sobre a alternativa correta"
            hint={{
              message:
                'Este comentário irá aparecer somente quando a estudante acertar o quiz. Procure discorrer sobre a questão, para complementar a alternativa correta.'
            }}
            onChange={(event) => setCorrectOptionComment(event.target.value)}
            value={correctOptionComment}
            onBlur={updateQuiz}
          />

          <hr />

          <QuizInput
            label="alternativas incorretas"
            hint={{
              message:
                'Ao errar, a estudante vai receber uma mensagem automática para que ela seja motivada a fazer outra tentativa. (Mínimo de 2 alternativas incorretas. Máximo 4).',
              obs: 'As alternativas são randômicas.'
            }}
            onChange={(event) =>
              setIncorrectOption1({
                ...incorrectOption1,
                option: event.target.value
              })
            }
            value={incorrectOption1.option}
            onBlur={updateQuizOptions}
            quizInfo={{ quizId, quizOptionId: incorrectOption1.id }}
          />
          <QuizInput
            onChange={(event) =>
              setIncorrectOption2({
                ...incorrectOption2,
                option: event.target.value
              })
            }
            value={incorrectOption2.option}
            onBlur={updateQuizOptions}
            quizInfo={{ quizId, quizOptionId: incorrectOption2.id }}
          />

          {showIncorrectOptional1 && (
            <QuizInput
              showIncorrectOption={setShowIncorrectOptional1}
              showConfirmRemoveIncorrectOptionModal={setShowRemoveIncorrectOptionSuccessModal}
              onChange={(event) =>
                setIncorrectOption3({
                  ...incorrectOption3,
                  option: event.target.value
                })
              }
              value={incorrectOption3.option}
              onBlur={updateQuizOptions}
              quizInfo={{ quizId, quizOptionId: incorrectOption3.id }}
            />
          )}

          {showIncorrectOptional2 && (
            <QuizInput
              showIncorrectOption={setShowIncorrectOptional2}
              showConfirmRemoveIncorrectOptionModal={setShowRemoveIncorrectOptionSuccessModal}
              onChange={(event) =>
                setIncorrectOption4({
                  ...incorrectOption4,
                  option: event.target.value
                })
              }
              value={incorrectOption4.option}
              onBlur={updateQuizOptions}
              quizInfo={{ quizId, quizOptionId: incorrectOption4.id }}
            />
          )}
        </QuizInputContainer>

        {!(showIncorrectOptional1 && showIncorrectOptional2) && (
          <ButtonContainer>
            <Button
              type="button"
              buttonStyle={{
                backgroundColor: 'transparent',
                color: 'var(--gray-100)',
                fontSize: '0.75rem',
                fontWeight: 'normal',
                textDecoration: 'underline',
                height: '2rem',
                justifyContent: 'space-between'
              }}
              onClick={handleAddIncorrectOption}
            >
              <PlusCircle height="1.2rem" color="var(--blue-primary)" />
              <span style={{ marginLeft: '0.3rem' }}>Adicionar alternativa</span>
            </Button>
          </ButtonContainer>
        )}

        <hr />

        <NextButtonDisabledContainer>
          <Button
            type="button"
            buttonStyle={{
              backgroundColor: 'var(--black)',
              color: 'var(--white)',
              fontSize: '0.9rem',
              fontWeight: 'bold',
              height: '2.3rem',
              width: '9rem',
              cursor: 'not-allowed',
              transform: 'translate(-1px, -1px)'
            }}
          >
            Avançar
          </Button>
        </NextButtonDisabledContainer>
      </Container>
    </>
  )
}
