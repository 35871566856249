import React, { ReactNode, useState, useEffect } from 'react'
import axios from 'axios'
import { InformationCircle, Loading, CheckCircleFilled, ExclamationMarkTriangleFilled } from '@somostera/tera-icons'

import { AddressFormValues } from '../../../@types/network'
import { useNetwork } from '../../../hooks/useNetwork'

import { useForm } from 'core/hooks/useForm'
import { Input } from 'core/components/Input'

import { Form, HorizontalPanel, SaveButton, FindZipCodePanel, SearchAddressContainer } from './styles'

export const AddressComponent = () => {
  const { personAddress, updatePersonAddress, isNewExpert, setCanShowDialogLeavingPage } = useNetwork()
  const [searchAddress, setSearchAddress] = useState('none')

  const handleSaveForm = () => {
    updatePersonAddress(data)
    setCanShowDialogLeavingPage(false)
  }

  interface SearchAddress {
    [key: string]: ReactNode
  }

  const searchAddressDictionary: SearchAddress = {
    foundedAddress: (
      <SearchAddressContainer status="foundedAddress">
        <CheckCircleFilled color="var(--green-60)" size={16} />
        <p>Endereço encontrado!</p>
      </SearchAddressContainer>
    ),
    foundedAddressNoStreet: (
      <SearchAddressContainer status="foundedAddress">
        <CheckCircleFilled color="var(--green-60)" size={16} />
        <p>Endereço encontrado!</p>
      </SearchAddressContainer>
    ),
    notFoundedAddress: (
      <SearchAddressContainer status="notFoundedAddress">
        <ExclamationMarkTriangleFilled color="var(--yellow)" size={20} />
        <p>Endereço não encontrado!</p>
      </SearchAddressContainer>
    ),
    loadingAddress: (
      <SearchAddressContainer status="loadingAddress">
        <Loading size={24} color="var(--gray-60)" />
        <p>Verificando endereço.</p>
      </SearchAddressContainer>
    ),
    none: <div />
  }

  const handleFindZipCode = async () => {
    if (searchAddress === 'none' || searchAddress === 'notFoundedAddress') {
      setSearchAddress('loadingAddress')
      setFullData({
        zipCode: '',
        street: '',
        city: '',
        state: ''
      })

      axios
        .get(`https://brasilapi.com.br/api/cep/v2/${data.zipCode}`)
        .then((response) => {
          if (response.data) {
            const { street, city, state } = response.data
            setFullData({
              zipCode: data.zipCode,
              street: street,
              city: city,
              state: state
            })
            street !== undefined ? setSearchAddress('foundedAddress') : setSearchAddress('foundedAddressNoStreet')
            //  handleFormBlur()
          }
        })
        // TODO: tratar erro
        .catch((error) => {
          setSearchAddress('notFoundedAddress')
          console.error(error)
        })
    }
  }

  const { data, errors, handleChange, setFullData, handleSubmit, setValue, reset } = useForm<AddressFormValues>({
    initialValues: {
      zipCode: personAddress?.zipCode || '',
      street: personAddress?.street || '',
      number: personAddress?.number || '',
      complement: personAddress?.complement || '',
      city: personAddress?.city || '',
      state: personAddress?.state || ''
    },
    onSubmit: handleFindZipCode,
    validations: {
      street: {
        custom: {
          isValid: (value: string) => value !== undefined || value !== '',
          message: 'Preencha o campo obrigatório.'
        }
      },
      number: {
        custom: {
          isValid: (value: string) => value !== undefined || value !== '',
          message: 'Preencha o campo obrigatório.'
        }
      },
      city: {
        custom: {
          isValid: (value: string) => value !== undefined || value !== '',
          message: 'Preencha o campo obrigatório.'
        }
      },
      state: {
        custom: {
          isValid: (value: string) => value !== undefined || value !== '',
          message: 'Preencha o campo obrigatório.'
        }
      }
    }
  })

  useEffect(() => {
    if (personAddress && data.zipCode !== personAddress.zipCode) {
      setFullData({
        zipCode: personAddress.zipCode,
        street: personAddress.street,
        number: personAddress.number,
        complement: personAddress.complement,
        city: personAddress.city,
        state: personAddress.state
      })
    }
    personAddress.zipCode !== '' && personAddress.street !== ''
      ? setSearchAddress('foundedAddress')
      : personAddress.zipCode !== ''
      ? setSearchAddress('foundedAddress')
      : ''
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personAddress])

  useEffect(() => {
    if (isNewExpert && personAddress.zipCode === undefined) {
      setFullData({
        zipCode: '',
        street: '',
        number: '',
        complement: '',
        city: '',
        state: ''
      })
    }
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNewExpert])

  useEffect(() => {
    if (data.zipCode === '' && searchAddress !== 'loadingAddress' && !personAddress.city && !personAddress.state) {
      reset()
      setSearchAddress('none')
    }
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.zipCode])

  const handleFormChange = (inputName: any) => (e: any) => {
    setValue(inputName, e.target.value)
    if (e.target.value !== '') setCanShowDialogLeavingPage(true)
    else {
      setCanShowDialogLeavingPage(false)
      handleChange(inputName)
    }
  }

  console.log(`TESTE`, personAddress.city)

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <HorizontalPanel>
          <Input
            name="zipCode"
            type="text"
            label="cep"
            placeholder="Digite seu CEP"
            onChange={handleFormChange('zipCode')}
            onBlur={handleFindZipCode}
            value={data.zipCode}
            errors={errors.zipCode}
            containerStyle={{ width: '17rem' }}
          />

          {searchAddressDictionary[searchAddress]}
        </HorizontalPanel>

        <FindZipCodePanel>
          <InformationCircle color="var(--blue-primary)" size={18} />
          <a href="https://buscacepinter.correios.com.br/app/endereco/index.php" target="blank">
            Não sei o CEP
          </a>
        </FindZipCodePanel>

        {(searchAddress === 'foundedAddress' ||
          searchAddress === 'foundedAddressNoStreet' ||
          searchAddress === 'notFoundedAddress' ||
          !!personAddress?.city ||
          !!personAddress?.state) && (
          <>
            <HorizontalPanel>
              <Input
                name="street"
                type="text"
                label="Endereço"
                placeholder="Digite seu endereço"
                onChange={handleFormChange('street')}
                value={data.street}
                errors={errors.street}
                containerStyle={{ width: '23rem' }}
                disabled={searchAddress === 'foundedAddress'}
                readOnly={searchAddress === 'foundedAddress'}
              />

              <Input
                name="number"
                type="text"
                label="Número"
                placeholder="Digite o número"
                onChange={handleFormChange('number')}
                value={data.number}
                errors={errors.number}
                containerStyle={{ width: '11rem' }}
              />
            </HorizontalPanel>

            <Input
              name="complement"
              type="text"
              label="Complemento"
              placeholder="Ex. apartamento, bloco..."
              onChange={handleFormChange('complement')}
              value={data.complement}
              errors={errors.complement}
              containerStyle={{ width: '35rem' }}
            />

            <HorizontalPanel>
              <Input
                name="city"
                type="text"
                label="Cidade"
                placeholder="Digite a sua cidade"
                onChange={handleFormChange('city')}
                value={data.city}
                errors={errors.city}
                containerStyle={{ width: '23rem' }}
                disabled={searchAddress === 'foundedAddress' || searchAddress === 'foundedAddressNoStreet'}
                readOnly={searchAddress === 'foundedAddress' || searchAddress === 'foundedAddressNoStreet'}
              />

              <Input
                name="state"
                type="text"
                label="Estado"
                placeholder="UF"
                onChange={handleFormChange('state')}
                value={data.state}
                errors={errors.state}
                containerStyle={{ width: '11rem' }}
                disabled={searchAddress === 'foundedAddress' || searchAddress === 'foundedAddressNoStreet'}
                readOnly={searchAddress === 'foundedAddress' || searchAddress === 'foundedAddressNoStreet'}
              />
            </HorizontalPanel>
          </>
        )}

        <button type="submit" hidden />
        <SaveButton width={isNewExpert ? '5.5rem' : '12rem'} type="button" onClick={() => handleSaveForm()}>
          {isNewExpert ? 'Próximo' : 'Salvar e Continuar'}
        </SaveButton>
      </Form>
    </>
  )
}
