import styled from 'styled-components'

export const TooltipContainer = styled.div`
  background: var(--gray-100);
  border: 0;
  border-radius: 0.125rem;
  font-family: 'Rubik';
  font-size: 0.75rem;
  line-height: 0.875rem;
  padding: 4px 8px;

  p {
    color: var(--white);
  }

  span {
    color: var(--gray-60);
  }
`
