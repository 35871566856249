import React, { InputHTMLAttributes } from 'react'

import { Container, SelectContainer, Error, SelectInformationContainer } from './styles'

export type SelectItem = {
  value: string
  name: string
}

interface SelectProps extends InputHTMLAttributes<HTMLSelectElement> {
  name?: string
  label?: string
  placeholder?: string
  defaultValue?: string
  width?: string
  items: SelectItem[]
  selectedValue: string
  selectStyle?: object
  errors?: string
}

export const Select = ({
  name,
  label,
  placeholder,
  defaultValue = '',
  width,
  items,
  selectedValue,
  onChange,
  onBlur,
  errors,
  selectStyle = {},
  disabled = false
}: SelectProps) => {
  return (
    <Container>
      <SelectInformationContainer>{!!label && <label>{label}</label>}</SelectInformationContainer>
      <SelectContainer
        name={name}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        $width={width || undefined}
        style={selectStyle}
        value={selectedValue}
        isInvalid={!!errors}
        disabled={disabled}
      >
        {!defaultValue && (
          <option value="default" disabled hidden>
            {placeholder || '-'}
          </option>
        )}
        {items.map((item) => (
          <option key={item.value} value={item.value}>
            {String(item.name)}
          </option>
        ))}
      </SelectContainer>
      {!!errors && <Error>{errors}</Error>}
    </Container>
  )
}
