import axios from 'axios'

export abstract class ArticlesService {
  static async getAll(filterText: string, token: string) {
    const postURl = process.env.REACT_APP_TERA_CLOUD_URL + 'url-elastic-service'

    return await axios.post(
      postURl,
      {
        query: filterText,
        index: 'articles',
        size: 2000
      },
      {
        headers: { __session: token }
      }
    )
  }

  static async getAllDraft(filterText: string, token: string) {
    const postURl = process.env.REACT_APP_TERA_CLOUD_URL + 'url-elastic-service'

    return await axios.post(
      postURl,
      {
        query: filterText,
        index: 'articles-draft',
        size: 2000
      },
      {
        headers: { __session: token }
      }
    )
  }
}
