import React from 'react'

import { PlusCircle } from '@somostera/tera-icons'

import { Container } from './styles'

interface UploadMaterialsProps {
  onRequestUpload: () => void
  text: string
}

export function UploadMaterials({ onRequestUpload, text }: UploadMaterialsProps) {
  return (
    <Container>
      <button type="button" onClick={onRequestUpload}>
        <PlusCircle size={16} color="var(--white)" />
        {text}
      </button>
    </Container>
  )
}
