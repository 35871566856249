import React, { useCallback, useEffect, useState } from 'react'
import { InputSelect } from 'modules/lives/components/Select'
import { useNavigate, useParams } from 'react-router-dom'
import { useAuth } from 'core/hooks/useAuth'
import LeftIcon from 'core/components/Icon/LeftIcon'
import { PlusCircle } from '@somostera/tera-icons'
import Trash from 'core/components/Icon/Trash'
import Envelope from 'core/components/Icon/Envelope'
import { InputDate } from 'modules/lives/components/InputDate'
import { PeopleService } from 'modules/lives/services/people'
import {
  ConfirmationModal as PublishConfirmationModal,
  ConfirmationModal as CancelModal
} from 'modules/courses/components/ConfirmationModal'
import {
  Container,
  FormContainer,
  Footer,
  Alert,
  ExpertsContainer,
  InviteButton,
  HourContainer,
  InputSelectContainer
} from './styles'
import { useSpringRef } from 'react-spring'
import { Lives } from '..'
import { LivesService } from 'modules/lives/services/lives'

interface ExpertData {
  value: string
  label: string
  status?: string
}

export function NewScheduleLive() {
  const navigate = useNavigate()
  const { userFirebase } = useAuth()

  const { liveId } = useParams()

  const [date, setDate] = useState('')
  const [startTime, setStartTime] = useState('')
  const [endTime, setEndTime] = useState('')
  const [experts, setExperts] = useState([])
  const [facilitator, setFacilitator] = useState([])
  const [liveScheduleId, setLiveScheduleId] = useState('')
  const [sendInviteText, setSendInviteText] = useState('Enviar Convites')

  const [selectedsExperts, setSelectedsExperts] = useState<ExpertData[]>([])
  const [selectedsStatus, setSelectedsStatus] = useState<ExpertData[]>([])
  const [selectedsStatusFacilitator, setSelectedsStatusFacilitator] = useState()
  const [setlectedFacilitator, setSelectedFacilitator] = useState()
  const [isConfirmPublishModalOpen, setIsConfirmPublishModalOpen] = useState(false)
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false)

  const [step, setStep] = useState(1)

  const data = {
    date,
    startTime,
    endTime,
    selectedsExperts,
    facilitator
  }

  function handleAddExpert(expert, index: number) {
    const newSelectedsExperts = [...selectedsExperts]

    if (!newSelectedsExperts.some((expertSelected) => expertSelected.id === expert.id)) {
      setSelectedsExperts((prevState) => [...prevState, expert])
      return
    } else {
      newSelectedsExperts[index] = {
        value: expert.value,
        label: expert.label
      }
    }

    setSelectedsExperts(newSelectedsExperts)
  }

  function handleAddStatus(status, index: number) {
    const newSelecteds = [...selectedsStatus]

    if (newSelecteds.length - 1 < index) {
      setSelectedsStatus((prevState) => [...prevState, status])
      return
    } else {
      newSelecteds[index] = {
        value: status.value,
        label: status.label
      }
    }

    setSelectedsStatus(newSelecteds)
  }

  async function getAllExperts() {
    const token = await userFirebase.getIdToken()
    const response = await PeopleService.getAll(token, 'expert')
    setExperts(response.data)
  }

  async function getAllFacilitator() {
    const token = await userFirebase.getIdToken()
    const response = await PeopleService.getAll(token, 'facilitator')
    setFacilitator(response.data)
  }

  useEffect(() => {
    getAllExperts()
    getAllFacilitator()
  }, [])

  const expertsOptions = experts
    .map((expert) => ({
      value: expert.id,
      label: expert.name
    }))
    .filter((exp) => exp.label?.length > 0)

  const statusOptions = [
    { value: 'aceito', label: 'Convite aceito' },
    { value: 'recusado', label: 'Convite recusado' },
    { value: 'enviar-convite', label: 'Enviar convite' },
    { value: 'selecionado', label: 'Expert selecionado' }
  ]

  const facilitatorOptions = facilitator
    .map((facilitator) => ({
      value: facilitator.id,
      label: facilitator.name
    }))
    .filter((fac) => fac.label?.length > 0)
  function addExpert() {
    setSelectedsExperts([...selectedsExperts, { value: '1', label: 'Selecione o expert' }])
    setSelectedsStatus([...selectedsStatus, { value: '1', label: 'Selecione o status' }])
  }

  function removeExpert(index: number) {
    const newSelectedsExperts = [...selectedsExperts]
    const newSelectedsStatus = [...selectedsStatus]
    newSelectedsExperts.splice(index, 1)
    newSelectedsStatus.splice(index, 1)
    setSelectedsExperts(newSelectedsExperts)
    setSelectedsStatus(newSelectedsStatus)
  }

  function joinDate(date, time) {
    const partialDate = date.split('-')
    const partialTime = time.split(':')

    const year = partialDate[0]
    const month = partialDate[1] - 1
    const day = partialDate[2]

    const hour = partialTime[0]
    const minutes = partialTime[1]

    const dateTimeUTC = new Date(year, month, day, hour, minutes, 0, 0)

    dateTimeUTC.setMilliseconds(0)

    dateTimeUTC.setUTCHours(dateTimeUTC.getUTCHours() - 3)

    const dateTimeUTCFormated = dateTimeUTC.toISOString()

    return dateTimeUTCFormated
  }

  async function handleSchedule() {
    const token = await userFirebase.getIdToken()

    const initialFormatedDate = joinDate(date, startTime.value)
    const endFormatedDate = joinDate(date, endTime.value)

    const data = {
      liveId,
      startDate: initialFormatedDate,
      endDate: endFormatedDate
    }

    const response = await LivesService.schedulesLive(token, liveId!, data)
    setLiveScheduleId(response.data)
  }

  async function handleSendInvite() {
    const token = await userFirebase.getIdToken()
    const expertsData = selectedsExperts.length > 0 ? selectedsExperts.map((expert) => expert.value) : []
    const statusData = selectedsStatus.length > 0 ? selectedsStatus.map((status) => status.value) : []

    const expertsLiveData = expertsData.map((expert, index) => ({
      liveId,
      liveScheduleId,
      peopleId: expert,
      status: statusData[index],
      role: 'expert'
    }))

    let data = []

    try {
      data = [
        ...expertsLiveData,
        {
          liveId,
          liveScheduleId,
          peopleId: setlectedFacilitator.value,
          status: selectedsStatusFacilitator.value,
          role: 'facilitator'
        }
      ]
    } catch {
      data = [
        ...expertsLiveData,
      ]
    }

    const dataFormated = data.filter(d => d.peopleId !== '1')

    if (data.length > 0) await LivesService.sendInviteLive(token, liveId!, liveScheduleId, dataFormated)

    setSendInviteText('Convites enviado')
  }

  function handlePrevOrNext() {
    if (step === 1) {
      !liveScheduleId && handleSchedule()
      setStep(2)
    }
    if (step === 2) {
      setIsConfirmPublishModalOpen(true)
      handleSendInvite()
    }
  }

  const handleCloseConfirmPublishModal = useCallback(() => {
    setIsConfirmPublishModalOpen(false)
  }, [])

  async function handleCancel() {
    try {
      navigate(`/lives`)
    } catch (error) {
      console.error(error)
    }
  }

  const handleCloseCancelModal = useCallback(() => {
    setIsCancelModalOpen(false)
  }, [])

  function getHoursArray() {
    const hoursArray = []

    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 15) {
        const formattedHour = hour.toString().padStart(2, '0')
        const formattedMinute = minute.toString().padStart(2, '0')
        const timeSlot = `${formattedHour}:${formattedMinute}`
        hoursArray.push(timeSlot)
      }
    }

    return hoursArray
  }

  const hours = getHoursArray()

  const hoursOptions = hours.map((key) => ({
    value: key,
    label: key
  }))

  function disableNextStep() {
    if (date === '' || startTime === '' || endTime === '') return true
    return false
  }

  return (
    <>
      <PublishConfirmationModal
        confirmButtonText="ok, fechar"
        messageType="Confirmation"
        messageTitle="Agendamento criado com sucesso"
        messageDescription="O calendário de agendamentos foi atualizado. "
        onRequestClose={handleCloseConfirmPublishModal}
        isOpen={isConfirmPublishModalOpen}
        onConfirm={handleCancel}
      />

      <CancelModal
        confirmButtonText="Voltar para edição"
        cancelButtonText="Sair sem salvar"
        messageType="RedWarning"
        messageTitle="Deseja sair sem salvar?"
        messageDescription="As alterações feitas serão perdidas."
        onRequestClose={handleCloseConfirmPublishModal}
        isOpen={isCancelModalOpen}
        onCancel={() => handleCancel()}
        onConfirm={() => {
          handleCloseCancelModal()
        }}
      />
      <Container>
        <section>
          <h2>Novo agendamento</h2>

          {step === 1 && (
            <FormContainer>
              <h3>Configurações</h3>
              <InputDate label="Dia" type="date" name="date" value={date} onChange={setDate} required />
              <HourContainer>
                <section>
                  <InputSelect
                    label="horário de início"
                    placeholder="Começa às"
                    options={hoursOptions}
                    onChange={(event) => {
                      setStartTime(event)
                    }}
                    value={{
                      value: startTime?.value || null,
                      label: startTime?.value ? startTime?.value : 'Começa às'
                    }}
                    required
                  />
                </section>
                <section>
                  <InputSelect
                    label="horário final"
                    placeholder="Termina às"
                    options={hoursOptions}
                    onChange={(event) => {
                      setEndTime(event)
                    }}
                    value={{
                      value: endTime?.value || null,
                      label: endTime?.value ? endTime?.value : 'Termina às'
                    }}
                    required
                  />
                </section>
              </HourContainer>
            </FormContainer>
          )}

          {step === 2 && (
            <FormContainer>
              <h3>Convite</h3>
              <Alert>
                <LeftIcon />
                <h4>Você pode enviar os convites mais tarde, se preferir.</h4>
              </Alert>

              {selectedsExperts.length === 0 ? (
                <ExpertsContainer>
                  <InputSelectContainer>
                    <InputSelect
                      type="expert"
                      label={'Experts'}
                      placeholder="Selecione o expert"
                      options={expertsOptions}
                      onChange={(event) => {
                        handleAddExpert(event, 0)
                      }}
                      value={{
                        value: selectedsExperts.length > 0 ? selectedsExperts[0]?.value : '1',
                        label: selectedsExperts.length > 0 ? selectedsExperts[0]?.label : 'Selecione o expert'
                      }}
                    />
                  </InputSelectContainer>
                  <InputSelectContainer>
                    <InputSelect
                      type="expert"
                      label="Status do convite"
                      placeholder="Selecione o expert"
                      options={statusOptions}
                      onChange={(event) => {
                        handleAddStatus(event, 0)
                      }}
                      value={{
                        value: selectedsStatus.length > 0 ? selectedsStatus[0]?.value : '1',
                        label: selectedsStatus.length > 0 ? selectedsStatus[0]?.label : 'Selecione o status'
                      }}
                    />
                  </InputSelectContainer>
                  <button type="button" onClick={addExpert}>
                    <PlusCircle color="var(--gray-100)" size={22} />
                  </button>
                </ExpertsContainer>
              ) : (
                selectedsExperts.map((expert, index) => (
                  <ExpertsContainer key={index}>
                    <InputSelectContainer>
                      <InputSelect
                        type="expert"
                        label={index === 0 ? 'Experts' : ''}
                        placeholder="Selecione o expert"
                        options={expertsOptions}
                        onChange={(event) => {
                          handleAddExpert(event, index)
                        }}
                        value={{
                          value: expert.value ? expert.value : '1',
                          label: expert.label ? expert.label : 'Selecione o expert'
                        }}
                      />
                    </InputSelectContainer>
                    <InputSelectContainer>
                      <InputSelect
                        type="expert"
                        label={index === 0 ? 'Status do Convite' : ''}
                        placeholder="Selecione o expert"
                        options={statusOptions}
                        onChange={(event) => {
                          handleAddStatus(event, index)
                        }}
                        value={{
                          value: selectedsStatus[index]?.value ? selectedsStatus[index]?.value : '1',
                          label: selectedsStatus[index]?.label ? selectedsStatus[index]?.label : 'Selecione o status'
                        }}
                      />
                    </InputSelectContainer>
                    {index === 0 ? (
                      <button type="button" onClick={addExpert}>
                        <PlusCircle color="var(--gray-100)" size={22} />
                      </button>
                    ) : (
                      <button onClick={() => removeExpert(index)}>
                        <Trash color="var(--red)" size={22} />
                      </button>
                    )}
                  </ExpertsContainer>
                ))
              )}
              <ExpertsContainer>
                <InputSelectContainer>
                  <InputSelect
                    label="Facilitador"
                    placeholder="Selecione o facilitador"
                    options={facilitatorOptions}
                    onChange={(event) => {
                      setSelectedFacilitator(event)
                    }}
                    value={{
                      value: setlectedFacilitator?.value ? setlectedFacilitator?.value : '1',
                      label: setlectedFacilitator?.label ? setlectedFacilitator?.label : 'Selecione o facilitador'
                    }}
                  />
                </InputSelectContainer>
                <InputSelectContainer>
                  <InputSelect
                    type="expert"
                    label={'Status do Convite'}
                    placeholder="Selecione o expert"
                    options={statusOptions}
                    onChange={(event) => {
                      setSelectedsStatusFacilitator(event)
                    }}
                    value={{
                      value: selectedsStatusFacilitator?.value ? selectedsStatusFacilitator?.value : '1',
                      label: selectedsStatusFacilitator?.label
                        ? selectedsStatusFacilitator?.label
                        : 'Selecione o status'
                    }}
                  />
                </InputSelectContainer>
              </ExpertsContainer>

              {/* <InviteButton onClick={handleSendInvite}>
                <Envelope />
                {sendInviteText}
              </InviteButton> */}
            </FormContainer>
          )}
        </section>
      </Container>
      <Footer>
        <button onClick={() => setIsCancelModalOpen(true)}>Cancelar</button>
        <button onClick={handlePrevOrNext} disabled={disableNextStep()}>{step === 1 ? 'Próximo' : 'Salvar'}</button>
      </Footer>
    </>
  )
}
