import React from 'react'
import { CheckCircleFilled } from '@somostera/tera-icons'
import { useNavigate } from 'react-router-dom'

import { Container } from './styles'
import { useNetwork } from '../../hooks/useNetwork'

import { InformationModal as SuccessSaveModal } from 'core/components/InformationModal'
import { InformationModal as ErrorSaveModal } from 'core/components/InformationModal'

import { useNavigatingAway } from 'core/hooks/useNavigatingAway'
import { DialogLeavingPage } from 'core/components/DialogLeavingPage'
import { Accordion, AccordionItem, AccordionTrigger, AccordionContent } from 'core/components/Accordion'

export const PersonProfile = () => {
  const {
    personProfileSections,
    isSuccessSaveModalOpen,

    setIsSuccessSaveModalOpen,
    canShowDialogLeavingPage,
    setCanShowDialogLeavingPage,
    isNewExpert,
    setIsNewExpert,
    isErrorSaveModalOpen,
    setIsErrorSaveModalOpen,
    accordionSectionOpen,
    setAccordionSectionOpen
  } = useNetwork()

  const [showDialogLeavingPage, confirmNavigation, cancelNavigation] = useNavigatingAway(canShowDialogLeavingPage)
  const navigate = useNavigate()

  const onCloseSuccessSaveModal = () => {
    setIsSuccessSaveModalOpen(false)
    setAccordionSectionOpen([(parseInt(accordionSectionOpen[0]) + 1).toString()])
    if (isNewExpert) {
      navigate(`/network`)
      setIsNewExpert(false)
    }
  }

  const onCloseErrorSaveModal = () => {
    setIsErrorSaveModalOpen(false)
    if (isNewExpert) {
      navigate(`/network`)
      setIsNewExpert(false)
    }
  }

  return (
    <Container>
      <Accordion
        value={accordionSectionOpen}
        onValueChange={setAccordionSectionOpen}
        defaultValue={accordionSectionOpen}
        type="multiple"
      >
        {Object.values(personProfileSections).map((section, index) => (
          <AccordionItem key={index} value={section.idSection}>
            <AccordionTrigger>
              <div>
                {section.filled ? (
                  <CheckCircleFilled color="var(--blue-primary)" size={20} />
                ) : (
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="10" cy="10" r="9" stroke="var(--gray-40)" strokeWidth="2" />
                  </svg>
                )}
                <h4>{section.title}</h4>
              </div>
            </AccordionTrigger>
            <AccordionContent>{section.component}</AccordionContent>
          </AccordionItem>
        ))}
      </Accordion>
      <SuccessSaveModal
        messageType="Confirmation"
        messageTitle="Dados salvos com sucesso!"
        isOpen={isSuccessSaveModalOpen}
        onRequestClose={() => onCloseSuccessSaveModal()}
      />
      <ErrorSaveModal
        messageType="Warning"
        messageTitle="Ocorreu um erro ao salvar os dados!"
        isOpen={isErrorSaveModalOpen}
        onRequestClose={() => onCloseErrorSaveModal()}
      />
      <DialogLeavingPage
        showDialog={showDialogLeavingPage}
        setShowDialog={setCanShowDialogLeavingPage}
        confirmNavigation={confirmNavigation}
        cancelNavigation={cancelNavigation}
      />
    </Container>
  )
}
