import { doc, getDoc } from 'firebase/firestore'
import { GenericConverter, Journey, PeopleCalendarStatus } from '@somostera/tera-models-ts'

import { db } from 'core/config/firebase'
import { getPersonById } from 'core/services/people'
import { getLocaleDateString, getLocaleHourString } from 'core/utils/date'

import { getValidExpertsForClass } from './experts'
import { getAllJourneys, getClassJourneyBlocks } from './journeys'

import { InitialCastingPageData, JourneyCastingPageData } from '../@types/casting'

interface ExpertsStatus {
  [expertStatus: string]: number
}

const JOURNEYS_COLLECTION_NAME = 'journeys'

export const getInitialCastingPageData = async (): Promise<InitialCastingPageData[]> => {
  const allJourneys = await getAllJourneys()

  return Promise.all(
    allJourneys.map(async (journey) => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const classJourneyBlocks = await getClassJourneyBlocks(journey.id!)
      const expertsStatusCounter = {
        [PeopleCalendarStatus.ACCEPTED]: 0,
        [PeopleCalendarStatus.SEND_INVITE]: 0,
        [PeopleCalendarStatus.SELECTED]: 0
      } as ExpertsStatus

      for (const block of classJourneyBlocks) {
        const experts = await getValidExpertsForClass(block.blockId)

        if (experts && block.expertStatus && block.expertStatus !== PeopleCalendarStatus.REJECTED) {
          expertsStatusCounter[block.expertStatus]++
        }
      }

      return {
        journeyId: journey.id,
        teraId: journey.teraId,
        startsAt: getLocaleDateString(journey.startsAt) || '',
        endsAt: getLocaleDateString(journey.endsAt) || '',
        ...expertsStatusCounter
      } as InitialCastingPageData
    })
  )
}

export const getJourneyCastingPageData = async (journeyId: string): Promise<JourneyCastingPageData> => {
  const journeyBlocks = await getClassJourneyBlocks(journeyId)

  const journeySnapshot = await getDoc(
    doc(db, JOURNEYS_COLLECTION_NAME, journeyId).withConverter(new GenericConverter<Journey>())
  )

  if (!journeySnapshot.exists()) {
    throw new Error('Sem turmas com esse id')
  }

  const journeyCastingData = await Promise.all(
    journeyBlocks.map(async (block) => {
      const { id, blockId, name, date, startTime, expertId, expertStatus } = block

      const expert = expertId ? await getPersonById(expertId) : undefined
      const validExpertsForClass = (await getValidExpertsForClass(blockId)).map((validExpertForClass) => ({
        expertId: validExpertForClass.id,
        expertName: validExpertForClass.name
      }))

      return {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        journeyBlockId: id!,
        blockId,
        name,
        date: getLocaleDateString(date),
        time: getLocaleHourString(startTime),
        expertId: expert?.id,
        expertName: expert?.name,
        expertStatus,
        validExpertsForClass
      }
    })
  )

  return {
    teraId: journeySnapshot.data().teraId,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    journeyCastingData: journeyCastingData.sort((journeyA, journeyB) => journeyA.date - journeyB.date)
  }
}
