import React, { ReactNode } from 'react'
import { NetworkData } from '../../@types/network'
import { Container } from './styles'

interface NameCellProps {
  row: NetworkData
  children: ReactNode
}
export const ContainerCell = ({ row, children }: NameCellProps) => {
  const newFoundationUrl = `/network/person/${row.id}`
  return <Container to={newFoundationUrl}>{children}</Container>
}
