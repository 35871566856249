/* eslint-disable prettier/prettier */
import styled, { css } from 'styled-components'

export const TypeTooltip = styled.span`
  background: #8ffe81;
  padding: 4px 16px;
  border-radius: 16px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;

  color: #000000;
`

export const OptionalTooltip = styled.span`
  background: #FFB224 !important;
  padding: 4px 16px;
  margin-left: 1rem;
  border-radius: 16px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;

  color: #000000;
`

export const OptionsContainer = styled.div`
  cursor: pointer;
  position: relative;
  display: inline-block;
  margin-left: auto;

  div {
    visibility: hidden;
    display: block;
    position: absolute;
    right: 0rem;
    top: -.5rem;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    padding: 12px 16px;
    z-index: 1;
    transition: opacity 1s ease-out;
  }

  :active div, div:hover {
    cursor: pointer;
    visibility: visible;
    opacity: 1;
  }
`

export const MainContainer = styled.div``

export const Container = styled.div`
  background-color: #fcfcfc;
  padding: 12px 20px 12px 14px;
  border-radius: 4px;

  display: flex;

  gap: 12px;
  height: ${(props) => props.height}px;
  max-width: 640px;

  ${(props) =>
    props.isDragging &&
    css`
      border: 2px dashed rgba(0, 0, 0, 0.2);
      padding-top: 31px;
      border-radius: 0;
      background: transparent;
      cursor: grabbing;
      p,
      span,
      img,
      header,
      h2,
      svg {
        opacity: 0;
      }
    `}

  div {
    h2 {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 900;
      font-size: 16px;
      line-height: 100%;

      letter-spacing: -0.01em;
      text-transform: uppercase;

      color: #171717;

      margin-top: 12px;
    }

    p {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      margin-top: 8px;

      color: #171717;
    }
  }
`

export const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
  }
`
export const IconContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  gap: 12px;
`
