import styled from 'styled-components'

export const AddLinkContainer = styled.form`
  min-height: 10rem;
  padding: 1.5rem 2rem;
  min-width: 30rem;
`

export const ButtonContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin: 1rem 0;

  button {
    align-items: center;
    color: var(--white);
    display: flex;
    height: 2.5rem;
    justify-content: center;
    width: 12rem;
  }
`
