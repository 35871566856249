import React, { useCallback, useEffect, useMemo, useState } from 'react'
// import { MediaStatus, MediaType } from '@somostera/tera-models-ts'
import { X } from '@somostera/tera-icons'

import { TableColumn } from 'react-data-table-component'

import { InitializeMediaService, Media } from '@somostera/tera-database'
import { MediaType } from '@somostera/tera-database/dist/Medias/Domain/enum/MediaType'
import { db } from 'core/config/firebase'

import { DataTableBase } from 'core/components/DataTableBase'
import { FilterTable } from 'core/components/DataTableBase/FilterTable'
import { Loading } from 'core/components/Animation/Loading'

import { MediaCell } from 'modules/contents/components/MediaCell'

import { Button } from 'core/components/Button'

import {
  ButtonsContainer,
  Container,
  LoadingContainer,
  SubHeaderContainer,
  TableContainer,
  UploadContainer
} from './styles'

type MediaInfo = {
  url: string
  videoDuration?: number
  fileName?: string
  type?: string
  fileSize?: number
  mimeType?: string
  thumbnail?: string
}

interface AddMediaModalProps {
  isOpen: boolean
  onRequestClose: () => void
  articleId: string
  onAddMedia: (mediaInfo: MediaInfo) => void
}

const mediaService = InitializeMediaService.initialize(db)

export function SelectMediaModal({ isOpen, onRequestClose, onAddMedia }: AddMediaModalProps) {
  const [tabIndex, setTabIndex] = useState(1)

  const [filterText, setFilterText] = useState('')
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false)
  const [medias, setMedias] = useState<Media[]>([] as Media[])
  const [loading, setLoading] = useState(false)

  const [selectedTableRows, setSelectedTableRows] = useState<Media[]>([])

  const columns: TableColumn<Media>[] = [
    {
      name: 'Mídia',
      selector: ({ name }) => JSON.stringify(name),
      sortable: false,
      cell: (row: Media) => <MediaCell media={row} />
    }
  ]

  const resetFormState = useCallback(() => {
    setFilterText('')
    setTabIndex(1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setFilterText('')
    resetFormState()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleCancelAddMedia = useCallback(() => {
    setFilterText('')
    resetFormState()
    onRequestClose()
  }, [onRequestClose, resetFormState])

  const filteredItems = useMemo(() => {
    if (medias) {
      return medias.filter(
        (media) =>
          JSON.stringify({
            name: media.name
          })
            .toLowerCase()
            .indexOf(filterText.toLowerCase()) !== -1
      )
    } else {
      return []
    }
  }, [filterText, medias])

  const SubHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle)
        setFilterText('')
      }
    }

    return (
      <SubHeaderContainer>
        <FilterTable
          placeholder="Busque por título"
          onFilter={(event) => setFilterText(event.target.value)}
          onClear={handleClear}
          filterText={filterText}
        />

        <span>{`(${filteredItems.length} resultados)`}</span>
      </SubHeaderContainer>
    )
  }, [filterText, resetPaginationToggle, filteredItems.length])

  useEffect(() => {
    async function getMedias() {
      setLoading(true)
      const allMedias = await mediaService.findAll()

      const filterMediasType = allMedias.filter((media) => media?.createdAt)

      setMedias(filterMediasType)

      setLoading(false)
    }

    getMedias()
  }, [])

  function handleSelect({ selectedRows }: any) {
    setSelectedTableRows(selectedRows)
  }

  function handleSelectMediaInLib() {
    const { name, url, type, duration, mimetype, thumbnail, size } = selectedTableRows[0]

    switch (type) {
      case MediaType.IMAGE || MediaType.THUMBNAIL_VIDEO:
        onAddMedia({ url, type })
        break
      case MediaType.VIDEO:
        if (!duration) {
          return
        }
        onAddMedia({ url, type, videoDuration: Number(duration), thumbnail })
        break
      default:
        onAddMedia({ url, type, fileName: name, fileSize: Number(size) || 0, mimeType: mimetype || '' })
        break
    }

    resetFormState()
    onRequestClose()
  }

  return (
    <Container
      isOpen={isOpen}
      onRequestClose={handleCancelAddMedia}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
      tabIndex={tabIndex}
    >
      <button type="button" onClick={handleCancelAddMedia} className="react-modal-close">
        <X color="var(--gray-40)" size={24} />
      </button>

      <UploadContainer isVisible={true}>
        <TableContainer>
          <h1>Biblioteca de mídias</h1>
          {loading && (
            <LoadingContainer>
              <Loading />
            </LoadingContainer>
          )}
          <DataTableBase
            columns={columns}
            data={filteredItems}
            noDataComponent={`${loading ? 'Carregando...' : 'Nenhum resultado encontrado'}`}
            noTableHead
            subHeader
            subHeaderComponent={SubHeaderComponent}
            pagination={false}
            selectableRows
            selectableRowsSingle
            onSelectedRowsChange={handleSelect}
          />
        </TableContainer>

        <ButtonsContainer>
          <Button
            buttonStyle={{
              background: 'white',
              border: '2px solid var(--gray-80)',
              height: '3rem',
              marginRight: '1rem',
              width: '11rem'
            }}
            onClick={handleCancelAddMedia}
          >
            Cancelar
          </Button>
          <Button
            disabled={selectedTableRows.length === 0}
            type="button"
            onClick={handleSelectMediaInLib}
            buttonStyle={{
              color: 'var(--white)',
              height: '3rem',
              width: '11rem'
            }}
          >
            Adicionar
          </Button>
        </ButtonsContainer>
      </UploadContainer>
    </Container>
  )
}
