import * as React from 'react'

function Calendar(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.5 0C7.05228 0 7.5 0.447715 7.5 1V2H11.5V1C11.5 0.447715 11.9477 0 12.5 0C13.0523 0 13.5 0.447715 13.5 1V2H16.5C17.6046 2 18.5 2.89543 18.5 4V11.9995C19.1279 12.8353 19.5 13.8742 19.5 15C19.5 17.7614 17.2614 20 14.5 20C13.3742 20 12.3353 19.6279 11.4995 19H2.5C1.39543 19 0.5 18.1046 0.5 17V4C0.5 2.89543 1.39543 2 2.5 2H5.5V1C5.5 0.447715 5.94772 0 6.5 0ZM16.5 9V10.416C15.8875 10.1484 15.2111 10 14.5 10C11.7386 10 9.5 12.2386 9.5 15C9.5 15.7111 9.64845 16.3875 9.91604 17H2.5V9H16.5ZM5.5 4H2.5V7H16.5V4H13.5V5C13.5 5.55228 13.0523 6 12.5 6C11.9477 6 11.5 5.55228 11.5 5V4H7.5V5C7.5 5.55228 7.05228 6 6.5 6C5.94772 6 5.5 5.55228 5.5 5V4ZM15.5 13C15.5 12.4477 15.0523 12 14.5 12C13.9477 12 13.5 12.4477 13.5 13V14H12.5C11.9477 14 11.5 14.4477 11.5 15C11.5 15.5523 11.9477 16 12.5 16H13.5V17C13.5 17.5523 13.9477 18 14.5 18C15.0523 18 15.5 17.5523 15.5 17V16H16.5C17.0523 16 17.5 15.5523 17.5 15C17.5 14.4477 17.0523 14 16.5 14H15.5V13Z"
        fill="black"
      />
    </svg>
  )
}

export default Calendar
