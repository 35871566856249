import React, { createContext, ReactNode, useContext, useState } from 'react'

interface GreenModalContextData {
  isConfirmationGreenWarningModalOpen: boolean
  isGreenModalText: string
  openGreenModal: (text: string) => void
  closeGreenModal: () => void
}

const GreenModalContext = createContext<GreenModalContextData>({} as GreenModalContextData)

function GreenModalProvider({ children }: { children: ReactNode }) {
  const [isConfirmationGreenWarningModalOpen, setIsConfirmationGreenWarningModalOpen] = useState(false)
  const [isGreenModalText, setIsGreenModalText] = useState('')

  function openGreenModal(text: string) {
    setIsConfirmationGreenWarningModalOpen(true)
    setIsGreenModalText(text)
    setTimeout(() => {
      setIsConfirmationGreenWarningModalOpen(false)
    }, 4000)
  }

  function closeGreenModal() {
    setIsConfirmationGreenWarningModalOpen(false)
  }

  return (
    <GreenModalContext.Provider
      value={{ isConfirmationGreenWarningModalOpen, isGreenModalText, openGreenModal, closeGreenModal }}
    >
      {children}
    </GreenModalContext.Provider>
  )
}

function useGreenModal(): GreenModalContextData {
  return useContext(GreenModalContext)
}

export { GreenModalProvider, useGreenModal }
