/* eslint-disable prettier/prettier */
import React from 'react'
import { getFormatedToLocaleDate } from 'core/utils/date'
import { ViewData } from 'modules/contents/components/datasheet/ViewData'
import { Content, Box } from './styles'
interface CourseProps {
  name: string
  description: string
  visibility: string
  status: string
  career: []
  availableForSchool: []
  areas: []
  categories: []
  levels: []
  tools: []
  outcomes: []
  coverImage: string
  courseItems: []
  readingTime: number
  updatedAt: string
}
interface DatasheetProps {
  course: CourseProps
}

function translateAreas(areas: string[]) {
  const areasTranslated = areas.map((area) => {
    switch (area) {
      case 'DESIGN_INNOVATION':
        return 'Design e Inovação'
      case 'DATA_AI':
        return 'Dados e IA'
      case 'LEADERSHIP_BUSINESS':
        return 'Liderança e Negócios'
      case 'MARKETING':
        return 'Marketing'
      case 'PRODUCT_MANAGEMENT':
        return 'Gestão de Produtos'
      case 'PROGRAMMING':
        return 'Programação'
      default:
        return ''
    }
  })
  return areasTranslated
}

function translateSchools(schools: string[]) {
  const schoolsTranslated = schools.map((school) => {
    switch (school) {
      case 'PRODUCT':
        return 'Plataforma'
      case 'SOLAR':
        return 'Solar'
      default:
        return ''
    }
  })
  return schoolsTranslated
}

function translateCategories(categories: string[]) {
  const categoriesTranslated = categories.map((category) => {
    switch (category) {
      case 'BUSINESS':
        return 'Negócios'
      case 'DATA':
        return 'Dados'
      case 'TECHNOLOGY':
        return 'Tecnologia'
      case 'DESIGN':
        return 'Design'
      case 'LEADERSHIP':
        return 'Liderança'
      case 'MARKETING':
        return 'Marketing'
      case 'PRODUCT':
        return 'Produto'
      default:
        return ''
    }
  })
  return categoriesTranslated
}

const levelOptions = [
  { value: 'BEGINNER', label: 'Iniciante' },
  { value: 'INTERMEDIATE', label: 'Intermediário' },
  { value: 'ADVANCED', label: 'Avançado' }
]

function translateLevels(levels: string[]) {
  const levelsTranslated = levels.map((level) => {
    switch (level) {
      case 'BEGINNER':
        return 'Iniciante'
      case 'INTERMEDIATE':
        return 'Intermediário'
      case 'ADVANCED':
        return 'Avançado'
      default:
        return ''
    }
  })
  return levelsTranslated
}

export function DataSheet({ course }: DatasheetProps) {
  return (
    <>
      <Content>
        <Box>
          <h3>Especificações</h3>
          <ViewData title="Título" data={[`${course?.name}`]} />
          <ViewData title="Descrição" data={[`${course?.description}`]} />
          <ViewData title="Visibilidade" data={[`${course?.visibility === 'private' ? 'Privado' : 'Público'}`]} />
          <ViewData
            title="Status"
            data={[
              `${
                course?.status === 'published'
                  ? 'Publicado'
                  : course?.status === 'draft'
                  ? 'Rascunho'
                  : course?.status === 'deleted'
                  ? 'Excluído'
                  : course?.status === 'archived'
                  ? 'Arquivado'
                  : course?.status
              }`
            ]}
          />
          <ViewData
            type="tag"
            title="Carreiras"
            data={!course?.career || course.career.length === 0 ? ['Carreiras não cadastradas'] : course?.career}
          />
          <ViewData
            type="tag"
            title="Escolas"
            data={
            !course?.availableForSchool || course.availableForSchool.length === 0 ?
              ['Escolas não cadastradas'] :
              translateSchools(course?.availableForSchool)
          }
          />
          <ViewData
            type="tag"
            title="Áreas"
            data={course?.areas === undefined ? ['Áreas não cadastradas'] : translateAreas(course?.areas)}
          />
          <ViewData
            type="tag"
            title="Categorias"
            data={
              course?.categories === undefined
                ? ['Categorias não cadastrados']
                : translateCategories(course?.categories)
            }
          />
          <ViewData
            type="tag"
            title="Nível"
            data={
              course?.levels === undefined
                ? ['Níveis não cadastrados']
                : translateLevels(course?.levels)
            }
          />

          <ViewData
            type="tag"
            title="Ferramentas"
            data={!course?.tools?.length ? ['Nenhuma'] : course?.tools}
          />

          <ViewData
            type="tag"
            title="Objetivos de aprendizagem"
            data={!course?.outcomes?.length ? ['Nenhuma'] : course?.outcomes}
          />

          <h3 style={{marginTop: '2rem', marginBottom: 0}}>Imagem de Capa</h3>
          {course?.coverImage && (
            <img src={course.coverImage} alt="Imagem do curso" style={{ width: '100%', height: 'auto' }} />
          )}
        </Box>

        <Box>
          <h3>Dados automáticos</h3>
          <ViewData title="Total de aulas" data={[`${course?.courseItems?.length}`]} />
          <ViewData title="Total de práticas" data={['10']} />
          <ViewData title="Tempo Total" data={[`${course?.readingTime}h`]} />

          <ViewData title="Atualizado em" data={[`${getFormatedToLocaleDate(new Date(course.updatedAt))}`]} />
        </Box>
      </Content>
    </>
  )
}
