import 'core/config/firebase'
import React from 'react'
import ReactDOM from 'react-dom'

import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

import { App } from './App'

Sentry.init({
  dsn: 'https://b9d9381419e944b4b914dc32d1794622@o778022.ingest.sentry.io/5804910',
  integrations: [new Integrations.BrowserTracing()],
  enabled: true,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  release: 'tera-admin-v2'
})

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)
