import React, { FormEvent, useCallback, useEffect, useState } from 'react'
import { Tab, TabList, TabPanel } from 'react-tabs'
import { Media, MediaStatus, MediaType } from '@somostera/tera-models-ts'
import { X } from '@somostera/tera-icons'

import { addMedia } from 'modules/contents/services/medias'

import { FileInput } from 'core/components/FileInput'

import { Container, CustomTabs, Form, UploadContainer, ButtonsContainer, ConfirmButton, CancelButton } from './styles'

export interface MediaFormValues {
  fileUrl: string
}

type MediaInfo = {
  url: string
  file: File
  fileSize?: number
  type?: string
}

interface AddMediaModalProps {
  isOpen: boolean
  onRequestClose: () => void
  onAddMedia: (mediaInfo: MediaInfo) => void
}

export function AddMediaModal({ isOpen, onRequestClose, onAddMedia }: AddMediaModalProps) {
  const [tabIndex, setTabIndex] = useState(0)
  const [fileSize, setFileSize] = useState(0)
  const [fileUrl, setFileUrl] = useState('')
  const [uploadedFile, setUploadedFile] = useState<File>()

  const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (fileUrl !== '' && uploadedFile) {
      const media: Media = {
        name: uploadedFile.name,
        status: MediaStatus.PUBLISHED,
        tags: [],
        source: '',
        description: '',
        transcription: '',
        type: MediaType.DOWNLOAD,
        url: fileUrl
      }

      const id = await addMedia(media)
      if (!id) {
        return
      }

      onAddMedia({ url: fileUrl, fileSize: fileSize || 0, file: uploadedFile, type: uploadedFile.type })

      resetFormState()
      onRequestClose()
    }
  }

  const resetFormState = useCallback(() => {
    setTabIndex(0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    resetFormState()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleCancelAddMedia = useCallback(() => {
    resetFormState()
    onRequestClose()
  }, [onRequestClose, resetFormState])

  return (
    <Container
      isOpen={isOpen}
      onRequestClose={handleCancelAddMedia}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <button type="button" onClick={handleCancelAddMedia} className="react-modal-close">
        <X color="var(--gray-40)" size={24} />
      </button>

      <CustomTabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
        <TabList>
          <Tab onClick={resetFormState}>Upload</Tab>
        </TabList>

        <TabPanel>
          <Form onSubmit={onSubmit}>
            <UploadContainer>
              <FileInput
                name="fileUrl"
                fileType={MediaType.DOWNLOAD}
                acceptedFilesRegex={[
                  'application/vnd.ms-powerpoint',
                  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
                  'application/vnd.google-apps.presentation',
                  'application/vnd.oasis.opendocument.presentation',
                  'application/msword',
                  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                  'application/vnd.google-apps.document',
                  'application/vnd.oasis.opendocument.text',
                  'application/vnd.ms-excel',
                  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                  'application/vnd.google-apps.spreadsheet',
                  'application/vnd.oasis.opendocument.spreadsheet',
                  'application/pdf',
                  'application/json',
                  'text/xml',
                  'text/csv',
                  'text/tab-separated-values',
                  'text/tsv',
                  '.ipynb',
                  'image/jpeg',
                  'image/bmp',
                  'image/x-ms-bmp',
                  'image/png',
                  'text/markdown',
                  '.tsv'
                ].join(',')}
                onFileUrlChange={(fileUrl: string) => setFileUrl(fileUrl)}
                onFileSizeChange={(size: number) => setFileSize(size)}
                onFileChange={(file: File) => setUploadedFile(file)}
              />
            </UploadContainer>

            <ButtonsContainer>
              <CancelButton onClick={handleCancelAddMedia}>Cancelar</CancelButton>
              <ConfirmButton type="submit">Adicionar</ConfirmButton>
            </ButtonsContainer>
          </Form>
        </TabPanel>
      </CustomTabs>
    </Container>
  )
}
