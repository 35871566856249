import * as React from 'react'

function SvgHint(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M15 15.8818H9"
        stroke={props.color || 'var(--blue-primary)'}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.54094 15.5892C6.76494 14.3392 5.69994 12.157 6.07494 9.75473C6.48094 7.15823 8.65394 5.0856 11.2749 4.77984C14.9129 4.35455 17.9999 7.17516 17.9999 10.7129C17.9999 12.7297 16.9939 14.5095 15.4569 15.5912C15.1809 15.7854 14.9999 16.0862 14.9999 16.4228V19.1787C14.9999 20.5541 13.8809 21.6686 12.4999 21.6686H11.4999C10.1189 21.6686 8.99994 20.5541 8.99994 19.1787V16.4268C8.99994 16.0872 8.81794 15.7844 8.54094 15.5892Z"
        stroke={props.color || 'var(--blue-primary)'}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20 5.73306L21.19 4.54785"
        stroke={props.color || 'var(--blue-primary)'}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.00006 15.6924L2.81006 16.8776"
        stroke={props.color || 'var(--blue-primary)'}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.00006 4.73697L3.81006 3.55176"
        stroke={props.color || 'var(--blue-primary)'}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20 15.6924L21.19 16.8776"
        stroke={props.color || 'var(--blue-primary)'}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 10.7129H22.69"
        stroke={props.color || 'var(--blue-primary)'}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.31006 10.7129H3.00006"
        stroke={props.color || 'var(--blue-primary)'}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 18.6807H14.87"
        stroke={props.color || 'var(--blue-primary)'}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SvgHint
