import React, { useEffect, useMemo, useState } from 'react'

import { PlusCircle } from '@somostera/tera-icons'
import { BuildingBlock } from '@somostera/tera-database'
import { TableColumn } from 'react-data-table-component'

import { DataTableBase } from 'core/components/DataTableBase'
import { FilterTable } from 'core/components/DataTableBase/FilterTable'
import { Checkbox } from 'core/components/Checkbox'
import { useToast } from 'core/hooks/useToast'
import { ConfirmationModal as BuildingBlockConfirmationModal } from 'core/components/ConfirmationModal'
import { BlueSelectedModalationModal } from 'core/components/BlueSelectedModalationModal'
import { Loading } from 'core/components/Animation/Loading'
import { useGreenModal } from 'core/hooks/useGreenModal'

import {
  Header,
  SubHeaderContainer,
  Container,
  MoreFiltersContainer,
  AddNewBuildingBlockButton,
  BlueWarningModalContainer,
  LoadingContainer,
  MenuTypeContainer
} from 'modules/buildingblock/views/BuildingBlockPage/styles'
import { useBuildingBlock } from 'modules/buildingblock/hooks/useBuildingBlock'
import { TypeCell } from 'modules/buildingblock/components/TypeCell'
import { DateCell } from 'modules/buildingblock/components/DataCell'
import { deleteBuildingBlock } from 'modules/buildingblock/services/build'
import { MenuTypeBuildingBlockModal } from 'modules/buildingblock/components/MenuType'
import { ContainerCell } from 'modules/buildingblock/components/ContainerCell'

interface FilterOption {
  [key: string]: boolean
}

interface Filters {
  [key: string]: FilterOption
}

interface Dictionary {
  [key: string]: string
}

export function BuildingBlockPage() {
  const [filterText, setFilterText] = useState('')
  const [filtersSelected, setFiltersSelected] = useState<string[]>([])
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] = useState(false)
  const { openGreenModal, isConfirmationGreenWarningModalOpen } = useGreenModal()
  const [isActive, setActive] = useState(false)

  const { buildingblock, getAllBuildingBlock, updateBuildingBlock, selectedRows, isLoading, setLoading } =
    useBuildingBlock()
  const { addToast } = useToast()

  useEffect(() => {
    getAllBuildingBlock()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (!isConfirmationGreenWarningModalOpen) {
      getAllBuildingBlock()
    }
    // eslint-disable-next-line
  }, [isConfirmationGreenWarningModalOpen])

  const typeDictionary: Dictionary = {
    class: 'Aula',
    masterclass: 'Masterclass',
    live: 'Ao vivo',
    'masterclass-case-study': 'Estudo de caso',
    'tera-lecture': 'Palestra',
    'tera-debate': 'Tera debate',
    'workshop-roleplay': 'Workshop',
    'workshop-framework': 'Workshop',
    mentorship: 'Mentoria',
    originals: 'Originals',
    visit: 'Aula',
    article: 'Aula',
    'collective-mentoring': 'Mentoria coletiva'
  }
  const dataDictionary: Dictionary = {
    hoje: 'Hoje',
    'nesta semana': 'Nesta semana',
    'neste mês': 'Neste mês',
    'últimos 3 meses': 'Últimos 3 meses',
    'últimos 6 meses': 'Últimos 6 meses',
    'neste ano': 'Neste ano'
  }

  const columns: TableColumn<BuildingBlock>[] = [
    {
      name: 'Titulo',
      selector: ({ name }) => name ?? '',
      sortable: true,
      cell: (row: BuildingBlock) => (
        <ContainerCell url={`/buildingblock/edit/${typeDictionary[row.type]}/${row.id}/details`}>
          {row.name}
        </ContainerCell>
      ),
      width: '28rem'
    },
    {
      name: 'Tipo',
      selector: ({ type }) => type,
      sortable: true,
      cell: (row: BuildingBlock) => (
        <ContainerCell url={`/buildingblock/edit/${typeDictionary[row.type]}/${row.id}/details`}>
          {' '}
          <TypeCell type={row.type} />{' '}
        </ContainerCell>
      ),
      width: '16rem'
    },
    {
      name: 'Data',
      selector: ({ createdAt }) => JSON.stringify(createdAt),
      sortable: true,
      cell: (row: BuildingBlock) => (
        <ContainerCell url={`/buildingblock/edit/${typeDictionary[row.type]}/${row.id}/details`}>
          <DateCell date={row.createdAt} />{' '}
        </ContainerCell>
      ),
      width: '10rem'
    }
  ]

  const moreFiltersOptionsInitialValue = {
    Data: {
      hoje: false,
      'nesta semana': false,
      'neste mês': false,
      'últimos 3 meses': false,
      'últimos 6 meses': false,
      'neste ano': false
    },
    Tipo: {
      'tera-debate': false,
      'masterclass-case-study': false,
      masterclass: false,
      mentorship: false,
      'workshop-roleplay': false,
      class: false
    }
  }

  const [moreFiltersOptions, setMoreFiltersOptions] = useState<Filters>(moreFiltersOptionsInitialValue)

  const handleCheckFilter = (newValue: boolean, filter: string, itemLabel: string) => {
    const newFilterBlock = moreFiltersOptions[filter]
    newFilterBlock[itemLabel] = newValue

    if (newValue) {
      setFiltersSelected([...filtersSelected, itemLabel.toLowerCase()])
    } else {
      const newFilters = filtersSelected.filter((filterSelected) => filterSelected.toLowerCase() !== itemLabel)
      setFiltersSelected(newFilters)
    }

    setMoreFiltersOptions({
      ...moreFiltersOptions,
      [filter]: newFilterBlock
    })
  }

  const filteredItems =
    buildingblock?.filter((block: BuildingBlock) => {
      // filtrando por datas
      if (
        filtersSelected.some(
          (item) =>
            item === 'hoje' ||
            item === 'nesta semana' ||
            item === 'neste mês' ||
            item === 'últimos 3 meses' ||
            item === 'últimos 6 meses' ||
            item === 'neste ano'
        )
      ) {
        // filtro por data 'hoje', pegando o valor do objeto sem alterar a data.
        if (filtersSelected.some((item) => item === 'hoje')) {
          const hoje = new Date()
          hoje.setHours(0, 0, 0, 0)
          const valor = block.createdAt?.valueOf() as number
          const dataBlock = new Date(valor)
          dataBlock?.setHours(0, 0, 0, 0)
          if (hoje.getTime() !== dataBlock?.getTime()) return false
        }
        // filtro por data 'nesta semana', pegando o valor do objeto sem alterar a data.

        if (filtersSelected.some((item) => item === 'neste semana')) {
          const semana = new Date()
          const firstday = new Date(semana.setDate(semana.getDate() - semana.getDay()))
          const lastday = new Date(semana.setDate(semana.getDate() - semana.getDay() + 6))

          const valor = block.createdAt?.valueOf() as number
          const dataBlock = new Date(valor)
          dataBlock?.setHours(0, 0, 0, 0)

          if (!(dataBlock.getTime() >= firstday.getTime() && dataBlock.getTime() <= lastday.getTime())) return false
        }

        if (filtersSelected.some((item) => item === 'neste mês')) {
          const meses = new Date(new Date().setMonth(new Date().getMonth() - 1))
          meses.setHours(0, 0, 0, 0)
          const valor = block.createdAt?.valueOf() as number
          const dataBlock = new Date(valor)
          dataBlock?.setHours(0, 0, 0, 0)
          if (!(dataBlock.getTime() >= meses.getTime())) return false
        }

        // filtro por data 'últimos 3 meses', pegando o valor do objeto sem alterar a data.

        if (filtersSelected.some((item) => item === 'últimos 3 meses')) {
          const meses = new Date(new Date().setMonth(new Date().getMonth() - 3))
          meses.setHours(0, 0, 0, 0)
          const valor = block.createdAt?.valueOf() as number
          const dataBlock = new Date(valor)
          dataBlock?.setHours(0, 0, 0, 0)
          if (!(dataBlock.getTime() >= meses.getTime())) return false
        }
        // filtro por data 'últimos 6 meses', pegando o valor do objeto sem alterar a data.

        if (filtersSelected.some((item) => item === 'últimos 6 meses')) {
          const meses = new Date(new Date().setMonth(new Date().getMonth() - 6))
          meses.setHours(0, 0, 0, 0)
          const valor = block.createdAt?.valueOf() as number
          const dataBlock = new Date(valor)
          dataBlock?.setHours(0, 0, 0, 0)
          if (!(dataBlock.getTime() >= meses.getTime())) return false
        }

        if (filtersSelected.some((item) => item === 'último ano'))
          if (block.createdAt?.getFullYear() !== new Date().getFullYear()) return false
      }
      // filtrando por tipos
      if (
        filtersSelected.some(
          (item) =>
            item === 'tera-debate' ||
            item === 'masterclass-case-study' ||
            item === 'masterclass' ||
            item === 'mentorship' ||
            item === 'workshop-roleplay' ||
            item === 'class'
        )
      ) {
        if (!filtersSelected.includes(block.type)) return false
      }

      // filtrando por texto
      return block.name?.toLowerCase().includes(filterText.toLowerCase())
    }) || []

  const handleDeleteBuildingBlock = () => {
    setLoading(true)
    selectedRows.forEach(({ id }) => {
      try {
        id && deleteBuildingBlock(id)
      } catch (error) {
        setIsDeleteConfirmationModalOpen(false)
        addToast({
          type: 'error',
          title: 'Building Block(s) Não Excluido(s)'
        })
      }
    })
    setIsDeleteConfirmationModalOpen(false)
    openGreenModal('Building block(s) excluído(s) com sucesso!')
    updateBuildingBlock([])
  }

  const toggleClass = () => {
    setActive(!isActive)
  }

  const SubHeaderComponent = useMemo(() => {
    const handleClear = () => {
      setFilterText('')
      setMoreFiltersOptions(moreFiltersOptionsInitialValue)
      setFiltersSelected([])
    }

    return (
      <>
        <SubHeaderContainer>
          <FilterTable
            placeholder="Busque por nome ou tipo de building block"
            onFilter={(event) => setFilterText(event.target.value)}
            onClear={handleClear}
            filterText={filterText}
            hasMoreFilters
            numberOfSelectedFilters={filtersSelected.length}
            MoreFiltersComponent={
              <MoreFiltersContainer>
                {Object.entries(moreFiltersOptions).map(([title, items]) => (
                  <div key={title}>
                    <h3 key={title}>{title}</h3>
                    {Object.entries(items).map(([itemLabel, checked]) => (
                      <Checkbox
                        key={itemLabel}
                        checked={checked}
                        labelText={title === 'Tipo' ? typeDictionary[itemLabel] : dataDictionary[itemLabel]}
                        onChange={(event) => handleCheckFilter(event.target.checked, title, itemLabel)}
                      />
                    ))}
                  </div>
                ))}
              </MoreFiltersContainer>
            }
          />

          {selectedRows.length > 0 && (
            <BlueWarningModalContainer>
              <BlueSelectedModalationModal
                selectedLines={selectedRows.length}
                onRequestClose={() => setIsDeleteConfirmationModalOpen(true)}
                messageDescription={selectedRows.length === 1 ? 'ITEM SELECIONADO' : 'ITEM SELECIONADOS'}
              />
            </BlueWarningModalContainer>
          )}
        </SubHeaderContainer>
      </>
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterText, moreFiltersOptions, selectedRows])

  const menuOptions = [
    {
      name: 'Tera debate',
      link: `/buildingblock/add/Tera debate/details`
    },
    {
      name: 'Estudo de caso',
      link: `/buildingblock/add/Estudo de caso/details`
    },
    {
      name: 'Masterclass',
      link: `/buildingblock/add/Masterclass/details`
    },
    {
      name: 'Mentoria',
      link: `/buildingblock/add/Mentoria/details`
    },
    {
      name: 'Workshop',
      link: `/buildingblock/add/Workshop/details`
    },
    {
      name: 'Aula',
      link: `/buildingblock/add/Aula/details`
    }
  ]

  return (
    <>
      <Container>
        <BuildingBlockConfirmationModal
          messageTitle="Excluir Building Block?"
          messageDescription="Esta ação tem impacto sobre a operação da Tera e não poderá ser revertida."
          confirmButtonText={'Sim, excluir'}
          cancelButtonText={'Cancelar'}
          messageType="RedWarning"
          confirmButtonColor="var(--red)"
          isOpen={isDeleteConfirmationModalOpen}
          onRequestClose={() => setIsDeleteConfirmationModalOpen(false)}
          onCancel={() => setIsDeleteConfirmationModalOpen(false)}
          onConfirm={handleDeleteBuildingBlock}
        />

        <Header>
          <div>
            <span>Design instrucional</span>
            <h1>building blocks</h1>
          </div>

          <section>
            <AddNewBuildingBlockButton onClick={toggleClass}>
              <PlusCircle color="var(--white)" size={20} />
              <p>Building Block</p>
            </AddNewBuildingBlockButton>
            {isActive && (
              <MenuTypeContainer>
                <MenuTypeBuildingBlockModal title="Tipos" options={menuOptions} />
              </MenuTypeContainer>
            )}
          </section>
        </Header>

        {isLoading && (
          <LoadingContainer>
            <Loading />
          </LoadingContainer>
        )}

        <DataTableBase
          columns={columns}
          data={filteredItems}
          subHeader
          subHeaderComponent={SubHeaderComponent}
          onSelectedRowsChange={({ selectedRows }) => updateBuildingBlock(selectedRows)}
          selectableRows
          pagination
          noDataComponent={`${isLoading ? 'Carregando...' : 'Nenhum dado encontrado'}`}
          selectableRowsNoSelectAll={true}
        />
      </Container>
    </>
  )
}
