import React from 'react'

export default function Cam(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="20" height="14" viewBox="0 0 20 14" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M19.5 1.1C19.2 0.9 18.8 0.9 18.5 1.1L14.1 4.1V2C14.1 0.9 13.2 0 12.1 0H2C0.9 0 0 0.9 0 2V12C0 13.1 0.9 14 2 14H12C13.1 14 14 13.1 14 12V9.9L18.4 12.9C18.6 13 18.8 13.1 19 13.1C19.2 13.1 19.3 13.1 19.5 13C19.8 12.8 20 12.5 20 12.1V2C20 1.6 19.8 1.3 19.5 1.1ZM12 12H2V2H12V6V8V12ZM18 10.1L14 7.4V6.5L18 3.8V10.1Z"
        fill="currentColor"
      />
    </svg>
  )
}
