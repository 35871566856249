import React, { useMemo, useState, useEffect } from 'react'
import { TableColumn } from 'react-data-table-component'
import { PlusCircle } from '@somostera/tera-icons'
import { useNavigate } from 'react-router-dom'

import { NetworkData } from '../../@types/network'
import { useNetwork } from '../../hooks/useNetwork'

import { RolesCell } from 'modules/network/components/RolesCell'
import { NameCell } from 'modules/network/components/NameCell'
import { DataTableBase } from 'core/components/DataTableBase'
import { FilterTable } from 'core/components/DataTableBase/FilterTable'
import { Checkbox } from 'core/components/Checkbox'
import { Loading } from 'core/components/Animation/Loading'

import { ContainerCell } from 'modules/network/components/ContainerCell'

import { Container, AddNewPersonButton, SubheaderContainer, MoreFiltersContainer, LoadingContainer } from './styles'

interface FilterOption {
  [key: string]: boolean
}

interface Filters {
  [key: string]: FilterOption
}

export const Network = () => {
  const navigate = useNavigate()

  const rolesDictionary = {
    expert: 'Expert',
    facilitator: 'Facilitadora',
    manager: 'Gerente',
    mentor: 'Mentora',
    tutor: 'Tutora'
  } as { [key: string]: string }

  const { networkTablePeople, isLoading, findAllPeople } = useNetwork()
  useEffect(() => {
    findAllPeople()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [filterText, setFilterText] = useState('')
  const [filtersSelected, setFiltersSelected] = useState<string[]>([])

  const moreFiltersOptionsInitialValue = {
    Papeis: {
      Expert: false,
      Facilitadora: false,
      Mentora: false,
      Tutora: false
    }
  }

  const [moreFiltersOptions, setMoreFiltersOptions] = useState<Filters>(moreFiltersOptionsInitialValue)

  const handleCheckFilter = (newValue: boolean, filter: string, itemLabel: string) => {
    const newFilterBlock = moreFiltersOptions[filter]
    newFilterBlock[itemLabel] = newValue

    if (newValue) {
      setFiltersSelected([...filtersSelected, itemLabel])
    } else {
      const newFilters = filtersSelected.filter((filterSelected) => filterSelected !== itemLabel)
      setFiltersSelected(newFilters)
    }

    setMoreFiltersOptions({
      ...moreFiltersOptions,
      [filter]: newFilterBlock
    })
  }
  const columns: TableColumn<NetworkData>[] = [
    {
      name: 'Nome',
      selector: ({ name }) => name,
      sortable: true,
      cell: (row: NetworkData) => (
        <ContainerCell row={row}>
          <NameCell row={row} />
        </ContainerCell>
      ),
      width: '20rem'
    },
    {
      name: 'Cargo',
      selector: ({ jobTitle }) => jobTitle,
      cell: (row: NetworkData) => <ContainerCell row={row}>{row.jobTitle}</ContainerCell>,
      sortable: true,
      width: '10rem'
    },
    {
      name: 'Empresa',
      selector: ({ company }) => company,
      cell: (row: NetworkData) => <ContainerCell row={row}>{row.company}</ContainerCell>,
      sortable: true,
      width: '10rem'
    },
    {
      name: 'Papeis',
      selector: ({ roles }) => JSON.stringify(roles),
      cell: (row: NetworkData) => (
        <ContainerCell row={row}>
          <RolesCell roles={row.roles} />
        </ContainerCell>
      ),
      width: '24rem'
    },
    {
      selector: ({ availability }) => availability.join(', '),
      omit: true
    }
  ]

  const filteredItems = networkTablePeople.filter((row) => {
    if (filterText === '') {
      if (filtersSelected.length === 0) {
        return networkTablePeople
      }
      return [...row.availability, ...row.roles.map((role: string) => rolesDictionary[role])].some((value) =>
        filtersSelected.includes(value)
      )
    }

    if (filtersSelected.length === 0) {
      return JSON.stringify([row.name, row.email, row.company]).toLowerCase().includes(filterText.toLowerCase())
    }

    return (
      [...row.availability, ...row.roles.map((role: string) => rolesDictionary[role])].some((value) =>
        filtersSelected.includes(value)
      ) && JSON.stringify([row.name, row.email, row.company]).toLowerCase().includes(filterText.toLowerCase())
    )
  })

  const SubheaderComponent = useMemo(() => {
    const handleClear = () => {
      setFilterText('')
      setMoreFiltersOptions(moreFiltersOptionsInitialValue)
      setFiltersSelected([])
    }

    return (
      <SubheaderContainer>
        <FilterTable
          placeholder="Busque por nome, e-mail ou empresa"
          onFilter={(event) => setFilterText(event.target.value)}
          onClear={handleClear}
          filterText={filterText}
          hasMoreFilters
          numberOfSelectedFilters={filtersSelected.length}
          MoreFiltersComponent={
            <MoreFiltersContainer>
              {Object.entries(moreFiltersOptions).map(([title, items]) => (
                <div key={title}>
                  <h3 key={title}>{title}</h3>
                  {Object.entries(items).map(([itemLabel, checked]) => (
                    <Checkbox
                      key={itemLabel}
                      labelText={itemLabel}
                      checked={checked}
                      onChange={(event) => handleCheckFilter(event.target.checked, title, itemLabel)}
                    />
                  ))}
                </div>
              ))}
            </MoreFiltersContainer>
          }
        />

        <AddNewPersonButton
          onClick={(event) => {
            event.preventDefault()
            navigate('person/add/verification')
            // TODO: Continuar usando useHistory() pra quando adicionar pessoa for uma feature
            // const oldFoundationUrl = process.env.REACT_APP_TERA_FOUNDATION_V1_URL + 'community/person'
            // window.open(oldFoundationUrl)
          }}
        >
          <PlusCircle color="var(--white)" size={20} />
          Nova Pessoa
        </AddNewPersonButton>
      </SubheaderContainer>
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterText, moreFiltersOptions])

  return (
    <>
      <Container>
        <h1>Rede</h1>

        {isLoading && (
          <LoadingContainer>
            <Loading />
          </LoadingContainer>
        )}

        <DataTableBase
          columns={columns}
          data={filteredItems}
          noDataComponent={`${isLoading ? 'Carregando...' : 'Nenhum dado encontrado'}`}
          pagination
          subHeader
          subHeaderComponent={SubheaderComponent}
        />
      </Container>
    </>
  )
}
