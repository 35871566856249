import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Modal from 'react-modal'
import { ArticleDraft } from '@somostera/tera-database'
import { X, PlusCircle, Trash } from '@somostera/tera-icons'

// import { Input } from 'core/components/Input'
// import { TagsInput } from 'core/components/TagsInput'
import { Select } from 'core/components/Select'

import { ViewData } from './ViewData'
import { ContentContainer } from './styles'

import { findJourneyBlockById, updateArticleDraft } from 'modules/contents/services/article'
import { useContent } from 'modules/contents/hooks/useContent'

interface ExpertData {
  id: string
  name: string
}

interface DataSheetProps {
  isOpen: boolean
  onRequestClose: () => void
}

export function DataSheet({ isOpen, onRequestClose }: DataSheetProps) {
  const [isEditing, setIsEditing] = useState(false)
  const [initialExperts, setInitialExperts] = useState<ExpertData[]>([])
  const [currentExperts, setCurrentExperts] = useState<ExpertData[]>([{ id: '1', name: 'Carregando...' }])
  const [currentArticleInfo, setCurrentArticleInfo] = useState<ArticleDraft>({} as ArticleDraft)
  const [isLoading, setIsLoading] = useState(false)

  const { articleId } = useParams()

  const { getExpertsNameByParticipantsIds, allPeopleData, allValidExperts } = useContent()

  useEffect(() => {
    async function getExperts() {
      if (articleId) {
        const articleInfo = await findJourneyBlockById(articleId)
        setCurrentArticleInfo(articleInfo)

        const expertNames = getExpertsNameByParticipantsIds(articleInfo.participantsIds)

        setCurrentExperts(expertNames)
        setInitialExperts(expertNames)
      }
    }

    getExperts()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [articleId, getExpertsNameByParticipantsIds])

  function handleEdit() {
    currentExperts.length === 0 && setCurrentExperts([{ id: '1', name: '' }])

    setIsEditing(true)
  }

  function handleCancelSave() {
    currentExperts[0].id === '1' ? setCurrentExperts([]) : setCurrentExperts(initialExperts)

    setIsEditing(false)
  }

  async function handleSave() {
    setIsLoading(true)

    const validExperts = currentExperts.filter((expert) => expert.id !== '1')

    setCurrentExperts(validExperts)

    const newExperts = validExperts.map((expert) => expert.id)

    const newArticleInfo: ArticleDraft = {
      ...currentArticleInfo,
      participantsIds: newExperts
    }

    try {
      articleId && (await updateArticleDraft(articleId, newArticleInfo))
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }

    setIsEditing(false)
  }

  function addExpert() {
    setCurrentExperts([...currentExperts, { id: '1', name: '' }])
  }

  function removeExpert(index: number) {
    const newCurrentExperts = [...currentExperts]
    newCurrentExperts.splice(index, 1)
    setCurrentExperts(newCurrentExperts)
  }

  function handleExpertsChange(expertId: string, index: number) {
    const expertData = allPeopleData.find((personData) => personData.id === expertId)

    const newCurrentExperts = [...currentExperts]

    newCurrentExperts[index] = {
      id: expertData?.id || '1',
      name: expertData?.name || ''
    }

    setCurrentExperts(newCurrentExperts)
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <ContentContainer>
        <header>
          <h3>Ficha Técnica</h3>
          <button onClick={onRequestClose}>
            <X color="var(--gray-100)" size={24} />
          </button>
        </header>

        {isEditing ? (
          <main>
            {currentExperts.map((expert, index) => (
              <section key={index}>
                <Select
                  name="experts"
                  label={index === 0 ? 'Experts Participantes' : ' '}
                  placeholder="Selecione quais experts estão presentes no conteúdo"
                  items={[
                    { value: '1', name: 'Selecione quais experts estão presentes no conteúdo' },
                    ...allValidExperts
                  ]}
                  selectedValue={expert.id}
                  onChange={(event) => handleExpertsChange(event.target.value, index)}
                />

                {index === 0 ? (
                  <button onClick={addExpert}>
                    <PlusCircle color="var(--gray-100)" size={22} />
                  </button>
                ) : (
                  <button onClick={() => removeExpert(index)}>
                    <Trash color="var(--red)" size={22} />
                  </button>
                )}
              </section>
            ))}

            {/* <TagsInput
              name="skills"
              label="Habilidades de aula"
              placeholder="Escreva as habilidades desenvolvidas nessa aula"
              value={[]}
              onTagsChange={() => {}} //eslint-disable-line
            />

            <TagsInput
              name="goals"
              label="Objetivos de aprendizagem"
              placeholder="Escreva quais são os objetivos de aprendizagem"
              value={[]}
              onTagsChange={() => {}} //eslint-disable-line
            />

            <Select
              name="level"
              label="Nível de proeficiência"
              placeholder="Selecione o nível de proeficiência"
              items={[
                { name: 'Básico', value: 'basic' },
                { name: 'Intermediário', value: 'intermediary' },
                { name: 'Avançado', value: 'advanced' }
              ]}
              selectedValue="intermediary"
            /> */}
          </main>
        ) : (
          <main>
            <ViewData
              title="Experts Participantes"
              data={
                currentExperts.length === 0 || currentExperts[0].id === '1'
                  ? ['Nenhum']
                  : currentExperts.map((currentExpert) => currentExpert.name)
              }
            />

            {/* <ViewData title="Habilidades de aula" type="tag" data={['Jesus', 'Maria', 'José']} />

            <ViewData title="Objetivos de aprendizagem" type="tag" data={['Jesus', 'Maria']} />

            <ViewData title="Tempo total de vídeo" data={['1:13:13']} />

            <ViewData title="Nível de proficiência" data={['Intermediário']} /> */}
          </main>
        )}

        <footer>
          {isEditing ? (
            <button disabled={!isEditing || isLoading} onClick={handleCancelSave}>
              Cancelar
            </button>
          ) : (
            <button disabled={isEditing || isLoading} onClick={handleEdit}>
              Editar
            </button>
          )}
          <button disabled={!isEditing || isLoading} onClick={handleSave}>
            {isLoading ? 'Carregando...' : 'Salvar'}
          </button>
        </footer>
      </ContentContainer>
    </Modal>
  )
}
