import React, { ReactNode } from 'react'

import { Container } from './styles'

interface BlockCellProps {
  url: string
  children: ReactNode
}

export const ContainerCell = ({ url, children }: BlockCellProps) => {
  return <Container to={url}>{children}</Container>
}
