import React from 'react'

import { ExclamationMarkTriangleFilled } from '@somostera/tera-icons'

import { Container } from './styles'

interface NoMaterialsPanelProps {
  text: string
}

export function NoMaterialsPanel({ text }: NoMaterialsPanelProps) {
  return (
    <Container>
      <ExclamationMarkTriangleFilled color="var(--yellow)" size={20} />
      {text}
    </Container>
  )
}
