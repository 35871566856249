import React from 'react'

import { ToggleSwitchWrapper } from './styles'

interface ToggleSwitchProps {
  onClick: () => void
}

const ToggleSwitch = ({ onClick }: ToggleSwitchProps) => {
  return (
    <ToggleSwitchWrapper>
      <input type="checkbox" onClick={onClick} />
      <span />
    </ToggleSwitchWrapper>
  )
}

export default ToggleSwitch
