import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 2rem auto;
  margin-top: 70px;
  width: 90%;

  h1 {
    text-transform: uppercase;
  }

  form {
    display: flex;
    flex-direction: column;

    > section {
      display: flex;
      flex: 1;
      align-items: flex-end;

      > button {
        background: transparent;
        border: 0;
        margin: 0 0 0.6rem 0.6rem;
      }
    }

    > button {
      margin-top: 1rem;
    }
  }
`
