import React from 'react'
import Modal from 'react-modal'
import { ExclamationMarkCircleFilledBlueToPurpleGradient, X } from '@somostera/tera-icons'

import { Button } from '../Button'

import { Container } from './styles'
import ExclamationMarkTriangleFilled from 'core/components/Icon/ExclamationMarkTriangleFilled'
import CheckCircleFilled from 'core/components/Icon/CheckCircleFilled'

interface ConfirmationModalProps {
  isOpen: boolean
  messageType?: string
  messageTitle: string
  messageDescription?: string
  confirmButtonText?: string
  confirmButtonColor?: string
  cancelButtonText?: string
  closeButtonColor?: string
  onRequestClose?: () => void
  onConfirm: () => void
  onCancel?: () => void
}

export function ConfirmationModal({
  isOpen,
  messageTitle,
  messageType,
  messageDescription,
  confirmButtonText,
  confirmButtonColor = 'var(--black)',
  cancelButtonText,
  closeButtonColor = 'var(--gray-80)',
  onRequestClose,
  onConfirm,
  onCancel
}: ConfirmationModalProps) {
  const MessageIcon = () => {
    switch (messageType) {
      case 'Confirmation':
        return <CheckCircleFilled />
      case 'BlueConfirmation':
        return <CheckCircleFilled size={72} color="var(--blue-primary)" />
      case 'Warning':
        return <ExclamationMarkCircleFilledBlueToPurpleGradient size={72} />
      case 'RedWarning':
        return <ExclamationMarkTriangleFilled />
      default:
        return <ExclamationMarkCircleFilledBlueToPurpleGradient size={70} />
    }
  }
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose || onCancel}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <Container>
        <MessageIcon />

        <h2>{messageTitle}</h2>
        {messageDescription && <p>{messageDescription}</p>}

        <div>
          {onCancel && (
            <Button type="button" onClick={onCancel}>
              {cancelButtonText}
            </Button>
          )}
          <Button type="button" onClick={onConfirm}>
            {confirmButtonText}
          </Button>
        </div>
      </Container>
    </Modal>
  )
}
