import React, { useEffect, useState } from 'react'

import {
  BlockType,
  BuildingBlock,
  LibraryMaterials,
  LibraryMaterialsSection,
  MaterialsBeforeClass
} from '@somostera/tera-database'
import { Link, Outlet, useLocation, useParams } from 'react-router-dom'

import { useGreenModal } from 'core/hooks/useGreenModal'
import { useNavigatingAway } from 'core/hooks/useNavigatingAway'
import { DialogLeavingPage } from 'core/components/DialogLeavingPage'
import { Loading } from 'core/components/Animation/Loading'

import { CustomTabs, Input, LoadingContainer, PageHeader, Tab } from './styles'
import { useBuildingBlock } from 'modules/buildingblock/hooks/useBuildingBlock'
import { createBuildingBlock, updateBuildingBlock } from 'modules/buildingblock/services/build'
import { TextBeforeClass } from '@somostera/tera-database/dist/BuildBlock/Domain/types/TextBeforeClass'

interface Dictionary {
  [key: string]: string
}

export const AddBuildingBlock = () => {
  const { pathname } = useLocation()
  const { blocktype, blockId } = useParams()
  const [newBlockId, setNewBlockId] = useState<string | undefined>(undefined)

  const { openGreenModal } = useGreenModal()
  const {
    detailsName,
    detailsTag,
    outcomesTag,
    detailsDescription,
    files,
    filesUpload,
    filesOldContent,
    filesClass,
    filesComplementary,
    contentDescription,
    clearFormInfo,
    canShowDialogLeavingPage,
    setCanShowDialogLeavingPage,
    setHandleSave,
    findBlockById,
    isLoading,
    setLoading,
    contentAfterClass
  } = useBuildingBlock()

  // const buildingblockService = InitializeBuildBlockService.initialize(db)

  const freeRoutesForNavigation = ['buildingblock/add', 'buildingblock/edit']

  const [showDialogLeavingPage, confirmNavigation, cancelNavigation] = useNavigatingAway(
    canShowDialogLeavingPage,
    freeRoutesForNavigation
  )

  const handleSaved = async () => {
    setLoading(true)

    const contentBeforeClass: (MaterialsBeforeClass | TextBeforeClass)[] = [
      {
        image: files[0] || '',
        text: contentDescription,
        materials: filesUpload.map((file) => ({
          name: file.name,
          url: file.url,
          type: file.type,
          sizeInMb: file.sizeInMb
        }))
      },
      ...filesOldContent
    ]

    const materialsClass: LibraryMaterials[] = filesClass.map((file) => ({
      name: file.name,
      url: file.url,
      type: file.type,
      sizeInMb: file.sizeInMb,
      section: LibraryMaterialsSection.CLASS_MATERIALS
    }))
    const deepeningClass: LibraryMaterials[] = filesComplementary.map((file) => ({
      name: file.name,
      url: file.url,
      type: file.type,
      sizeInMb: file.sizeInMb,
      section: LibraryMaterialsSection.DEEPENING_MATERIALS
    }))

    const typeDictionary: Dictionary = {
      Masterclass: BlockType.MASTERCLASS,
      'Tera debate': BlockType.TERA_DEBATE,
      'Estudo de caso': BlockType.MASTERCLASS_CASE_STUDY,
      Mentoria: BlockType.MENTORSHIP,
      Workshop: BlockType.WORKSHOP_ROLEPLAY,
      Artigos: BlockType.ARTICLE,
      Aula: BlockType.CLASS,
      'Ao vivo': BlockType.LIVE
    }

    const submit: BuildingBlock = {
      id: '',
      name: detailsName,
      description: detailsDescription,
      skills: detailsTag,
      outcomes: outcomesTag,
      type: typeDictionary[blocktype || 'Artigos'] as BlockType,
      contentBeforeClass,
      contentAfterClass: contentAfterClass,
      downloads: [...materialsClass, ...deepeningClass]
    }

    if (blockId || newBlockId) {
      submit.id = blockId || newBlockId
      await updateBuildingBlock(submit)
      openGreenModal('Alterações foram salvas com sucesso!')
      setHandleSave(true)
      setCanShowDialogLeavingPage(false)
    } else {
      const saveBlock = await createBuildingBlock(submit)
      setNewBlockId(saveBlock.id)
      openGreenModal('Building Block criado com sucesso!')
      setHandleSave(true)
      setCanShowDialogLeavingPage(false)
    }

    setLoading(false)
  }

  useEffect(() => {
    setCanShowDialogLeavingPage(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    clearFormInfo()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (blockId) {
      findBlockById(blockId)
    }
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blockId])

  return (
    <>
      {isLoading && (
        <LoadingContainer>
          <Loading />
        </LoadingContainer>
      )}
      <DialogLeavingPage
        showDialog={showDialogLeavingPage}
        setShowDialog={setCanShowDialogLeavingPage}
        confirmNavigation={confirmNavigation}
        cancelNavigation={cancelNavigation}
      />
      <CustomTabs>
        <section>
          <PageHeader>
            <div>
              <h4>{blocktype}</h4>
              <Input
                type="text"
                name="description"
                readOnly
                placeholder="SEM TÍTULO"
                minHeight="2rem"
                value={detailsName}
              />
            </div>

            <button type="button" disabled={!detailsDescription || !detailsName} onClick={() => handleSaved()}>
              Salvar
            </button>
          </PageHeader>
          <ul>
            <Tab className={pathname.includes('details') ? 'selected' : ''}>
              <Link to="details">Detalhes</Link>
            </Tab>
            <Tab className={pathname.includes('content') ? 'selected' : ''}>
              <Link to="content">Conteúdo</Link>
            </Tab>
            <Tab className={pathname.includes('settings') ? 'selected' : ''}>
              <Link to="settings">Configurações</Link>
            </Tab>
          </ul>
          <Outlet />
        </section>
      </CustomTabs>
    </>
  )
}
