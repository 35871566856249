import { BlockType } from '@somostera/tera-database'
import React from 'react'

import { Chip, Container } from './styles'

interface BuildingProps {
  type: BlockType
}

export const TypeCell = ({ type }: BuildingProps) => {
  const typeDictionary = {
    class: 'Aula',
    masterclass: 'Masterclass',
    live: 'Ao vivo',
    'masterclass-case-study': 'Estudo de caso',
    'tera-lecture': 'Palestra',
    'tera-debate': 'Tera debate',
    'workshop-roleplay': 'Workshop',
    mentorship: 'Mentoria',
    originals: 'Originals',
    visit: 'Aula',
    article: 'Aula',
    'collective-mentoring': 'Mentoria coletiva',
    'workshop-framework': 'Workshop'
  } as { [key: string]: string }

  return (
    <Container>
      <Chip type={type}>{typeDictionary[type]}</Chip>
    </Container>
  )
}
