import React, { useEffect, useState } from 'react'
import { Tab, TabList } from 'react-tabs'
import { ProfileIcon } from '@somostera/tera-icons'

import { useNetwork } from '../../hooks/useNetwork'
import { usePortfolio } from '../../hooks/usePortfolio'
import { StatusPerson } from 'modules/network/components/StatusPerson'

import { ImageStorage } from 'core/config/firebase-storage'
import { MediaType } from '@somostera/tera-models-ts'

import {
  CustomTabs,
  PageHeader,
  ProfileContainer,
  ProfileData,
  ProfilePictureInput,
  ProfilePicture,
  LoadingContainer,
  Container
} from './styles'
import { Loading } from 'core/components/Animation/Loading'
import { Link, Outlet, useLocation, useParams } from 'react-router-dom'

export const Person = () => {
  const {
    getPersonById,
    personPersonalData,
    personContactInformation,
    isLoading,
    setIsLoading,
    personStatusAndAvailability,
    updatePersonPhoto,
    name,
    email,
    setName,
    setEmail,
    isNewExpert,
    setIsNewExpert,
    clearSectionFilled,
    findAllTiers,
    setCanShowDialogLeavingPage
  } = useNetwork()

  const { portfolioByPeopleId } = usePortfolio()
  const { pathname } = useLocation()
  const { personId } = useParams()

  const [tabIndex, setTabIndex] = useState(0)
  const [personPhotoEdited, setPersonPhotoEdited] = useState('')

  const storage = new ImageStorage()

  const handleChangePersonPhoto = async (files: HTMLInputElement['files']) => {
    const file = files ? files[0] : undefined
    if (!file) return

    const acceptedFiles = file.type.match(/image.*/)

    if (!acceptedFiles) return

    setIsLoading(true)

    const randomHash = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
    const fileName = randomHash + '_' + file.name.normalize('NFC')
    const blob = file.slice(0, file.size, 'image/png')
    const newFile = new File([blob], fileName, { type: file.type })

    const { fileDownloadUrl } = await storage.uploadMedia(file, MediaType.IMAGE, newFile.name)

    await updatePersonPhoto(fileDownloadUrl)

    setPersonPhotoEdited(fileDownloadUrl)

    setIsLoading(false)
  }

  useEffect(() => {
    if (pathname.includes('/portfolio')) {
      setTabIndex(1)
    } else if (pathname.includes('/financial')) {
      setTabIndex(2)
    } else {
      setTabIndex(0)
    }
  }, [pathname])

  useEffect(() => {
    if (personId !== '0' && personId !== undefined) {
      const findPerson = async () => {
        clearSectionFilled()
        await getPersonById(personId || '')
        await portfolioByPeopleId(personId)
        await findAllTiers()
      }
      setName('')
      setEmail('')
      setIsNewExpert(false)
      setCanShowDialogLeavingPage(false)
      findPerson()
    }
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personId])

  const personIsExpert = personStatusAndAvailability?.roles?.includes('expert') || false

  return (
    <Container>
      {isLoading && (
        <LoadingContainer>
          <Loading />
        </LoadingContainer>
      )}
      <CustomTabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
        <PageHeader>
          <ProfileContainer>
            <ProfilePictureInput>
              <label htmlFor="fileInput">
                {personPhotoEdited ||
                (personPersonalData !== undefined && personPersonalData.profilePicture && !isNewExpert) ? (
                  <ProfilePicture
                    src={personPhotoEdited || personPersonalData.profilePicture}
                    alt={personPersonalData?.name}
                  />
                ) : (
                  <ProfileIcon size={80} />
                )}
              </label>

              <input
                hidden
                type="file"
                id="fileInput"
                accept=".jpg,.jpeg,.png"
                onChange={(e) => handleChangePersonPhoto(e?.target?.files)}
              />
            </ProfilePictureInput>
            <ProfileData>
              <h4>Perfil</h4>
              {personStatusAndAvailability !== undefined &&
              personStatusAndAvailability.status !== '' &&
              !isNewExpert ? (
                <StatusPerson status={personStatusAndAvailability.status} />
              ) : (
                <></>
              )}
              <p>{name !== '' ? name : personPersonalData !== undefined && personPersonalData.name}</p>
              <span>
                {email !== '' ? email : personContactInformation !== undefined && personContactInformation.email}
              </span>
            </ProfileData>
          </ProfileContainer>
        </PageHeader>
        <TabList>
          <Tab>
            <Link to="">Perfil</Link>
          </Tab>
          {!isNewExpert && (
            <>
              <Tab>
                <Link to="portfolio">Portfolio</Link>
              </Tab>
              {personIsExpert && (
                <Tab>
                  <Link to="financial">Financeiro</Link>
                </Tab>
              )}
            </>
          )}
        </TabList>
        <Outlet />
      </CustomTabs>
    </Container>
  )
}
