import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { AutosizeInput } from 'modules/contents/components/datasheet/AutosizeInput'
import { Input } from 'modules/contents/components/datasheet/Input'
import { MultiSelect } from 'modules/contents/components/datasheet/MultiSelect'

import { Container, FormContainer, ToggleSwitchContainer, Footer, LoadingContainer } from './styles'

import { CareersType } from '@somostera/tera-database'
import ToggleSwitch from 'modules/contents/components/datasheet/ToggleSwitch'

import { useAuth } from 'core/hooks/useAuth'

import { Loading } from 'core/components/Animation/Loading'
import { FormationsService } from 'modules/formations/services/formations'

export function AddFormation() {
  const navigate = useNavigate()
  const { userFirebase } = useAuth()
  const [title, setTitle] = useState<string | undefined>('')
  const [description, setDescription] = useState('')

  const careerOptions = Object.keys(CareersType).map((key) => ({ value: key, label: CareersType[key] }))
  const [careers, setCareers] = useState<string[] | undefined>([])

  const [isChecked, setIsChecked] = useState(false)
  const [isVisible, setIsVisible] = useState('')
  const [isLoading, setIsLoading] = useState(true)

  const handleCheck = () => {
    setIsChecked(!isChecked)
  }

  useEffect(() => {
    setIsLoading(false)
  }, [])

  useEffect(() => {
    isChecked ? setIsVisible('Público') : setIsVisible('Privado')
  }, [isChecked])

  async function handleSave() {
    setIsLoading(true)

    const data = {
      name: title,
      description,
      career: careers?.map((career) => career.label),
      visibility: isChecked ? 'public' : 'private'
    }

    try {
      const token = await userFirebase.getIdToken()
      await FormationsService.saveFormation(token, data)
      navigate('/formations')
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  async function handleCancel() {
    try {
      navigate(`/formations`)
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  if (isLoading) {
    return (
      <LoadingContainer>
        <Loading />
      </LoadingContainer>
    )
  }

  return (
    <>
      <Container>
        <section>
          <h2>Nova Formação</h2>

          <FormContainer>
            <h3>Especificações</h3>

            <Input
              type="text"
              name="title"
              label="Nome da formação"
              placeholder="Escreva o nome da nova formação"
              value={title}
              onChange={(event) => setTitle(event.target.value)}
              required
            />

            <AutosizeInput
              type="text"
              name="description"
              label="Descrição"
              placeholder="Descrição breve do que é esta formação"
              minHeight={'6.75rem'}
              value={description}
              onChange={(event) => setDescription(event.target.value)}
              required
            />

            <MultiSelect options={careerOptions} onChange={setCareers} value={careers} label="Carreiras" required />

            <ToggleSwitchContainer>
              <h3>Visibilidade</h3>
              <div>
                <ToggleSwitch onClick={handleCheck} />
                <span>{isVisible}</span>
              </div>
            </ToggleSwitchContainer>
          </FormContainer>
        </section>
      </Container>
      <Footer>
        <button onClick={handleCancel}>Cancelar</button>
        <button onClick={handleSave}>Salvar</button>
      </Footer>
    </>
  )
}
