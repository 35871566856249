import styled from 'styled-components'

export const Container = styled.div`
padding-top: 60px;
  background: #f7f7f7;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding-bottom: 87px;
  height: 100vh;

  > section {
    width: 640px;

    h2 {
      margin-top: 40px;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 900;
      font-size: 32px;
      line-height: 100%;

      display: flex;
      align-items: flex-end;
      letter-spacing: -0.02em;
      text-transform: uppercase;

      color: #000000;
    }
  }
`
export const FormContainer = styled.div`
  margin-top: 20px;
  padding: 20px;

  background: #ffffff;
  border: 1px solid #f2f2f2;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.04);

  > h3 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 24px;

    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: #000000;
  }
`

export const Footer = styled.footer`
  background-color: #000;
  width: calc(100% - 240px);
  height: 56px;

  display: flex;
  justify-content: flex-end;

  position: fixed;
  bottom: 0;

  > button {
    height: 100%;
    padding: 12px 64px 12px 64px;
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 700;
    background-color: #000;
    color: #fff;

    &:last-child {
      background-color: #8ffe81;
      color: #000;
    }

    &:disabled {
      cursor: not-allowed;
    }
  }
`
export const ToggleSwitchContainer = styled.div`
  display: flex;
  flex-direction: column;
  > h3 {
    margin-top: 16px;
    font-size: 12px;
    font-weight: 600;
    line-height: 12px;
    letter-spacing: 0.01;
    text-transform: uppercase;
    color: #000;
  }

  > div {
    margin-top: 16px;
    display: flex;
    gap: 8px;
    align-items: center;

    > label {
      margin-top: 0;
    }

    > span {
      font-size: 14px;
      line-height: 16px;
      font-weight: 400;
    }
  }
`

export const Alert = styled.div`
  background: #e8e8e8;
  border-radius: 9999px;
  width: 375px;
  padding: 4px 12px 4px 4px;

  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
  margin-top: 16px;

  h4 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    letter-spacing: -0.01em;

    color: #171717;
  }
`
export const ExpertsContainer = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  align-items: flex-end;
  gap: 20px;

  > button {
    background: transparent;
    border: 0;
    // margin: 0 0 0.6rem 0.6rem;
  }
`
export const InviteButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 20px 8px 8px;
  gap: 8px;
  background: #000000;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 20px;

  letter-spacing: 0.01em;
  text-transform: uppercase;

  color: #ffffff;

  margin-top: 16px;
`

export const HourContainer = styled.div`
  display: flex;
  gap: 20px;

  section {
    width: 50%;
  }
`

export const InputSelectContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  > div {
    display: flex;
    gap: 8px;
    width: 100%;
    flex-direction: column;
  }
`
