import styled from 'styled-components'
import { Button } from 'core/components/Button'

export const SaveConfirmationContainer = styled.div`
  min-height: 10rem;
  padding: 1.5rem 2rem;
  min-width: 30rem;
`

export const TextPanel = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 8.5rem;
  justify-content: center;
  width: 29.5rem;

  h1 {
    font-size: 1.5rem;
    line-height: 1.5rem;
    text-transform: uppercase;
  }

  p {
    font-size: 1rem;
    line-height: 1.5rem;
    margin-top: 1rem;
  }
`

export const ButtonContainer = styled.div`
  align-items: center;
  border-top: 1px solid var(--gray-20);
  display: flex;
  justify-content: space-between;
  margin: 1rem 0;
  padding: 1rem 0;
`

export const BackButton = styled(Button)`
  align-items: center;
  background: transparent;
  border: 2px solid var(--gray-80);
  border-radius: 0.5rem;
  color: var(--gray-80);
  display: flex;
  font-size: 1rem;
  height: 3rem;
  justify-content: center;
  line-height: 1.25rem;
  text-transform: uppercase;
  width: 11.5rem;
`

export const ConfirmButton = styled(Button)`
  align-items: center;
  background: var(--black);
  border: 2px solid var(--black);
  border-radius: 0.5rem;
  color: var(--white);
  display: flex;
  font-size: 1rem;
  height: 3rem;
  justify-content: center;
  line-height: 1.25rem;
  text-transform: uppercase;
  width: 11.5rem;
`
