import React from 'react'

import Smille from 'core/components/Icon/Smille'

import { Container, ListItem, TagContainer, ListItemLink, ListItemList } from './styles'

export type ViewDataType = 'default' | 'tag' | 'link' | 'tagWithIcon' | 'list'

interface ViewDataProps {
  title: string
  data: string[]
  type?: ViewDataType
}

export function ViewData({ title, data, type = 'default' }: ViewDataProps) {
  return (
    <Container>
      <h3>{title}</h3>

      <div>
        {data.map((item, index) => (
          <ListItem key={index} type={type} tagKey={index + 1}>
            {type === 'tagWithIcon' && (
              <TagContainer>
                <Smille /> {item}
              </TagContainer>
            )}

            {type === 'link' && (
              <>
                <ListItemLink href={item} target="_blank" rel="noreferrer">
                  {item}
                </ListItemLink>
              </>
            )}

            {type === 'tag' && (
              <>
                <span>{item}</span>
              </>
            )}

            {type === 'list' && (
              <>
                <span>&#8226;&nbsp;&nbsp;{item}</span>
              </>
            )}

            {type === 'default' && (
              <>
                <span>{item}</span>
              </>
            )}
          </ListItem>
        ))}
      </div>
    </Container>
  )
}
