import styled, { css } from 'styled-components'

export const InputInformationContainer = styled.div`
  align-items: baseline;
  display: flex;
  gap: 0.25rem;
  margin-top: 1rem;

  label {
    font-family: 'Rubik';
    color: '#000';
    display: block;
    font-size: 0.75rem;
    font-weight: bold;
    line-height: 0.75rem;
    text-transform: uppercase;
    margin-bottom: 0.5rem;
    letter-spacing: 0.01;
  }

  span {
    color: #cc3314;
    font-weight: 700;
    font-size: 0.75rem;
    line-height: 0.75rem;
  }

  p {
    color: var(--gray-80);
    font-weight: 300;
    font-size: 1rem;
    line-height: 1.25rem;
  }
`

export const Description = styled.p`
  color: var(--gray-60);
  margin-bottom: 0.3rem;
  font-size: 0.875rem;
`

interface ContainerProps {
  isFocused: boolean
  isInvalid: boolean
}

export const Container = styled.div<ContainerProps>`
  background-color: #fcfcfc;
  border: 1px solid #dbdbdb;
  display: flex;
  min-height: 48px;
  padding: 12px 16px;
  position: relative;
  border-radius: 0.125rem;

  ${(props) =>
    props.isInvalid &&
    css`
      border-color: var(--orange);
    `}

  ${(props) =>
    props.isFocused &&
    css`
      border-color: #3af221;
    `}

  ul {
    display: inline-flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    width: 100%;
    gap: 8px;

    li {
      padding: 4px;
      font-size: 14px;
      align-items: center;
      border-radius: 1.375rem;
      color: var(--black);
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      gap: 5px;

      //margin-bottom: 8px;

      padding: 4px 5px 4px 12px;

      button {
        background: transparent;
        border-radius: 50%;
        color: var(--gray-20);
        cursor: pointer;
        width: 1.125rem;

        display: flex;
        align-items: center;

        svg {
          width: 100%;
        }

        & + button {
          margin-left: 0.5rem;
        }
      }

      &:last-child {
        background: transparent;
        border-radius: 0;
        padding: 0 1rem 0 0;
        flex: 1;
        min-width: 10rem;

        input {
          background: transparent;
          border: none;
          font-size: 0.875rem;
          height: 100%;
          outline: 0;
          width: 100%;

          font-family: 'Inter';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          /* identical to box height, or 150% */

          letter-spacing: -0.01em;

          /* Neutral/neutral11 */

          color: #707070;

          &::-webkit-calendar-picker-indicator {
            display: none !important;
          }
        }
      }
    }
  }

  > button {
    background: transparent;
    border-radius: 50%;
    height: 1.5rem;
    position: absolute;
    right: 0.5rem;
    top: calc(50% - 0.75rem);
    width: 1.5rem;

    &:hover {
      opacity: 0.8;
    }
  }
`

const colors = {
  1: 'var(--green-pastel)',
  2: 'var(--blue-pastel)',
  3: 'var(--yellow-pastel)',
  4: 'var(--purple-pastel)'
} as { [key: string]: string }

interface TagProps {
  tagKey?: number
  type: string
}

export const Tag = styled.li<TagProps>`
  background: ${(props) =>
    props.tagKey ? colors[props.tagKey - 4 * (Math.ceil(props.tagKey / 4) - 1)] : 'var(--gray-20)'};

  ${(props) =>
    props.type === 'tag' &&
    css`
      background: #d0f6cb;
    `}
`

export const Error = styled.p`
  color: var(--orange);
  font-size: 1rem;
  line-height: 1.25rem;
  margin-top: 0.5rem;
`
