import React from 'react'
import Modal from 'react-modal'
import {
  ExclamationMarkCircleFilledBlueToPurpleGradient,
  X,
  CheckCircleFilledBlueToPurpleGradient,
  CheckCircleFilled
} from '@somostera/tera-icons'

import { Container } from './styles'

interface InformationModalProps {
  isOpen: boolean
  messageType: 'Confirmation' | 'Warning' | 'BlueWarning'
  messageTitle: string
  messageDescription?: string
  onRequestClose: () => void
}

export function InformationModal({
  isOpen,
  messageType,
  messageTitle,
  messageDescription,
  onRequestClose
}: InformationModalProps) {
  const MessageIcon = () => {
    switch (messageType) {
      case 'Confirmation':
        return <CheckCircleFilledBlueToPurpleGradient size={72} />
      case 'Warning':
        return <ExclamationMarkCircleFilledBlueToPurpleGradient size={72} />
      case 'BlueWarning':
        return <CheckCircleFilled color={'var(--blue-primary)'} size={72} />
      default:
        return <ExclamationMarkCircleFilledBlueToPurpleGradient size={72} />
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <Container>
        {onRequestClose && (
          <button type="button" onClick={onRequestClose} className="react-modal-close">
            <X color="var(--gray-80)" height={12} width={12} />
          </button>
        )}

        <MessageIcon />

        <h2>{messageTitle}</h2>
        {messageDescription && <p>{messageDescription}</p>}
      </Container>
    </Modal>
  )
}
