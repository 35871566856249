import React, { ReactNode, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Loading, CheckCircleFilled, ExclamationMarkTriangleFilled } from '@somostera/tera-icons'

import { ContactVerificationFormValues, NetworkData } from '../../@types/network'
import { useNetwork } from '../../hooks/useNetwork'
import { useForm } from 'core/hooks/useForm'

import { Input } from 'core/components/Input'
import { RoundCheckbox } from 'core/components/RoundCheckbox'
import { ProfileIcon } from '@somostera/tera-icons'

import {
  Container,
  SearchStatusContainer,
  ProfileContainer,
  ProfileData,
  ProfilePicture,
  ProfilePictureInput
} from './styles'

interface SearchStatus {
  [key: string]: ReactNode
}

export const ContactVerification = () => {
  const searchStatusDictionary: SearchStatus = {
    verifiedContact: (
      <SearchStatusContainer status="verifiedContact">
        <CheckCircleFilled color="var(--green-60)" size={16} />
        <p>Contato verificado!</p>
      </SearchStatusContainer>
    ),
    alreadyRegistered: (
      <SearchStatusContainer status="alreadyRegistered">
        <ExclamationMarkTriangleFilled color="var(--yellow)" size={20} />
        <p>Este e-mail já está cadastrado na rede.</p>
      </SearchStatusContainer>
    ),
    loadingContact: (
      <SearchStatusContainer status="loadingContact">
        <Loading size={24} color="var(--gray-60)" />
        <p>Verificando contato na base de dados.</p>
      </SearchStatusContainer>
    ),
    none: <div />
  }

  const navigate = useNavigate()
  const { getPersonByEmail, networkPersonSelected, clearDataAndSection } = useNetwork()

  const [foundPersonSelected, setFoundPersonSelected] = useState(false)
  const [foundedPerson, setFoundedPerson] = useState<NetworkData>()

  const [searchStatus, setSearchStatus] = useState('none')

  const handleFindPerson = async () => {
    if (
      searchStatus !== 'alreadyRegistered' ||
      (searchStatus === 'alreadyRegistered' && networkPersonSelected.email !== data.email)
    ) {
      setSearchStatus('loadingContact')

      if (!data.email) {
        setSearchStatus('none')
        return
      }

      const foundPerson = await getPersonByEmail(data.email)
      setFoundedPerson(foundPerson)
      setSearchStatus(foundPerson ? 'alreadyRegistered' : 'verifiedContact')
    }
  }

  const { data, errors, handleChange, handleSubmit, setValue } = useForm<ContactVerificationFormValues>({
    initialValues: { name: '', email: '' },
    onSubmit: handleFindPerson,
    validations: {
      name: { required: { value: true, message: 'Preencha o campo obrigatório.' } },
      email: {
        required: { value: true, message: 'Preencha o campo obrigatório.' },
        custom: {
          isValid: (value: string) =>
            /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(
              value
            ),
          message: 'E-mail inválido'
        }
      }
    }
  })

  const handleFormChange = (inputName: any) => (e: any) => {
    setValue(inputName, e.target.value)
    handleChange(inputName)
  }

  const handleNewPerson = () => {
    if (searchStatus === 'alreadyRegistered') {
      navigate(`/network/person/${foundedPerson?.id}`)
    } else {
      clearDataAndSection(data)
      navigate(`/network/person/0`)
    }
  }

  useEffect(() => {
    if (data.email === '' && searchStatus !== 'loadingAddress') {
      setValue('email', '')
      setSearchStatus('none')
    } else if (data.email[0] === '@') {
      setSearchStatus('none')
    }

    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.email])

  return (
    <Container>
      <h1>Verificação de contato</h1>
      <p>Antes de começar, precisamos verificar se a pessoa que você quer cadastrar já está na nossa base de dados.</p>

      <form onSubmit={handleSubmit}>
        <Input
          name="name"
          type="text"
          label="Nome Completo *"
          placeholder="Digite o nome"
          onChange={handleFormChange('name')}
          value={data.name}
          errors={errors.name}
        />

        <Input
          name="email"
          type="email"
          label="E-mail *"
          placeholder="Digite o e-mail"
          onChange={handleFormChange('email')}
          onBlur={handleFindPerson}
          value={data.email}
          errors={errors.email}
        />

        {searchStatusDictionary[searchStatus]}

        {searchStatus === 'alreadyRegistered' && networkPersonSelected && (
          <ProfileContainer selected={foundPersonSelected}>
            <h3>Perfil encontrado:</h3>
            <div>
              <RoundCheckbox
                checked={foundPersonSelected}
                onChange={() => setFoundPersonSelected(!foundPersonSelected)}
              />
              <ProfilePictureInput>
                {networkPersonSelected.profilePicture !== undefined && networkPersonSelected.profilePicture !== '' ? (
                  <ProfilePicture src={networkPersonSelected.profilePicture} alt={networkPersonSelected.name} />
                ) : (
                  <ProfileIcon size={45} />
                )}
              </ProfilePictureInput>

              <ProfileData>
                <p>{networkPersonSelected.name}</p>
                <span>{networkPersonSelected.email}</span>
              </ProfileData>
            </div>
            <p>
              Este e-mail já está vinculado à uma pessoa cadastrada na rede Tera. Para prosseguir, selecione o perfil
              acima e clique em avançar.
            </p>
            <p>
              Não é quem está procurando? Entre em contato com a nossa equipe através{' '}
              <a
                href="https://docs.google.com/forms/d/e/1FAIpQLSfL8zrv8zhiASohQ6iQr1ys5MWmFz_oj7Exa5BgBUF92IYwgA/formrestricted"
                target="blank"
              >
                desse formulário
              </a>
              .
            </p>
          </ProfileContainer>
        )}

        <hr />

        <button type="submit" hidden />

        <button
          type="button"
          disabled={!(searchStatus === 'verifiedContact' || foundPersonSelected) || !data.name || !data.email}
          onClick={() => handleNewPerson()}
        >
          Avançar
        </button>
      </form>
    </Container>
  )
}
