import { Timestamp } from 'firebase/firestore'

export const timeDiffInMinutes = (currentTimestamp: Timestamp, anotherTimestamp: Timestamp): number => {
  const currentDate = currentTimestamp.toDate()
  const anotherDate = anotherTimestamp.toDate()

  const diff: number = currentDate.getTime() - anotherDate.getTime()

  return Math.floor(diff / 1000 / 60)
}

export function parseDate(dateString: string) {
  const date = new Date(dateString)
  date.setUTCHours(date.getUTCHours() - 3) // Subtrair 3 horas
  const day = date.getUTCDate()
  const month = date.getUTCMonth() + 1
  const year = date.getUTCFullYear()
  const hours = ('0' + date.getUTCHours()).slice(-2)
  const minutes = ('0' + date.getUTCMinutes()).slice(-2)

  return {
    day,
    month,
    year,
    hours,
    minutes
  }
}

export function parseEndDate(dateString: string) {
  const date = new Date(dateString)
  const day = date.getUTCDate()
  const month = date.getUTCMonth() + 1
  const year = date.getUTCFullYear()
  const hours = ('0' + `${date.getUTCHours()}`).slice(-2)
  const minutes = ('0' + date.getUTCMinutes()).slice(-2)

  return {
    day,
    month,
    year,
    hours,
    minutes
  }
}

export const getDateInformation = (dateParameter: Date | Timestamp) => {
  const dateNow = new Date()

  let date = dateParameter

  if (dateParameter.seconds && dateParameter.nanoseconds) {
    date = new Date(dateParameter.seconds * 1000 + dateParameter.nanoseconds / 1000000)
  }

  const day = date.getUTCDate()
  const month = date.getUTCMonth()
  const year = date.getUTCFullYear()
  const hours = date.getHours().toString().padStart(2, '0')
  const minutes = date.getMinutes().toString().padStart(2, '0')

  return {
    day,
    month,
    year,
    hours,
    minutes,
    currentDay: day === dateNow.getUTCDate() && month === dateNow.getUTCMonth() && year === dateNow.getFullYear()
  }
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const sortByDate = (dateA: Date, dateB: Date, desc?: boolean): number => (desc ? dateB - dateA : dateA - dateB)

export const monthNames = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro'
]

export const getLocaleDateString = (date?: Date) => {
  if (!date) {
    return ''
  }
  return new Date(date).toLocaleDateString('pt-BR', { timeZone: 'UTC' })
}

export const getLocaleHourString = (date?: Date) => {
  if (!date) {
    return ''
  }
  const [hour, minutes] = new Date(date).toLocaleTimeString('pt-BR', { timeZone: 'UTC' }).split(':')
  return `${hour}h${minutes}`
}

export const getFormatedDate = (date?: Date) => {
  if (!date) {
    return ''
  }

  const day = date.getUTCDate()
  const month = date.getUTCMonth()
  const year = date.getUTCFullYear()
  const hours = date.getHours().toString().padStart(2, '0')
  const minutes = date.getMinutes().toString().padStart(2, '0')

  return `${day}.${month}.${year} - ${hours}h${minutes}`
}

export const getFormatedToLocaleDate = (date?: Date) => {
  if (!date) {
    return ''
  }

  let newDate

  if (date.seconds && date.nanoseconds) {
    newDate = new Date(date.seconds * 1000 + date.nanoseconds / 1000000)
  }else{
    newDate = new Date(date)
  }

  const formatedDate = newDate.toLocaleDateString('pt-BR', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric'
  })

  const hours = newDate.getHours().toString().padStart(2, '0')
  const minutes = newDate.getMinutes().toString().padStart(2, '0')

  return `${formatedDate} às ${hours}:${minutes}`
}

export const getDateInformationText = (date: Date) => {
  const dateNow = new Date()

  const day = date.getUTCDate()
  const month = date.getUTCMonth()
  const year = date.getUTCFullYear()
  const hours = date.getHours().toString().padStart(2, '0')
  const minutes = date.getMinutes().toString().padStart(2, '0')

  return {
    day,
    month,
    year,
    hours,
    minutes,
    currentDay: day === dateNow.getUTCDate() && month === dateNow.getUTCMonth() && year === dateNow.getFullYear()
  }
}
