import styled from 'styled-components'

export const Container = styled.div`
  margin: 2rem auto;
  width: 90%;

  h1 {
    margin-bottom: 1rem;
    text-transform: uppercase;
  }

  a {
    color: var(--gray-80);
  }
`
