import styled from 'styled-components'

export const Label = styled.label`
  font-family: 'Rubik';
  color: '#000';
  display: block;
  font-size: 0.75rem;
  font-weight: bold;
  line-height: 0.75rem;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
  letter-spacing: 0.01;
  margin-top: 16px;
`
