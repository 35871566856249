import { db } from '../../../core/config/firebase'
import { InitializeTierService } from '@somostera/tera-database/dist/Tiers/Infrastructure/Adapters/TierService'

const tierService = InitializeTierService.initialize(db)

export abstract class TierService {
  static async findAll() {
    const tier = await tierService.findAll()
    return tier
  }

  static async findByName(name: string) {
    const tier = await tierService.findByName(name)
    return tier
  }
}
