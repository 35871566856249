import styled from 'styled-components'

export const Container = styled.div`
  .rdt_TableCol {
    > div > div {
      overflow: auto;
      white-space: pre-wrap;
    }
  }

  .rdt_Table {
    background-color: #fcfcfc;

    > div {
      background-color: #fcfcfc;
    }
  }
`
export const LoadingContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 4;
  margin-top: 4rem;
`
