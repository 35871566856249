import { collection, doc, getDocs, orderBy, query, writeBatch, where } from '@firebase/firestore'

import { ClassBlock, GenericConverter, Journey, JourneyBlock } from '@somostera/tera-models-ts'
import { db } from 'core/config/firebase'
import { JourneyCastingData } from '../@types/casting'

const JOURNEYS_COLLECTION_NAME = 'journeys'
const JOURNEYS_BLOCKS_COLLECTION_NAME = 'journey_blocks'

const journeysCollection = collection(db, JOURNEYS_COLLECTION_NAME).withConverter(new GenericConverter<Journey>())

export const getAllJourneys = async (): Promise<Journey[]> => {
  const journeysQuery = query(journeysCollection, where('endsAt', '>=', new Date())).withConverter(
    new GenericConverter<Journey>()
  )

  const journeysQuerySnapshot = await getDocs(journeysQuery)

  return journeysQuerySnapshot.docs.map((doc) => ({
    ...doc.data(),
    id: doc.id
  }))
}

export const getClassJourneyBlocks = async (journeyId: string): Promise<ClassBlock[]> => {
  const journeyBlocksCollection = collection(
    db,
    `${JOURNEYS_COLLECTION_NAME}/${journeyId}/${JOURNEYS_BLOCKS_COLLECTION_NAME}`
  ).withConverter(new GenericConverter<ClassBlock>())

  const journeyBlocksQuery = query(
    journeyBlocksCollection,
    where('type', 'not-in', ['tera-debate', 'mentorship', 'collective-mentoring']),
    orderBy('type')
  ).withConverter(new GenericConverter<ClassBlock>())

  const journeyBlocksQuerySnapshot = await getDocs(journeyBlocksQuery)

  return journeyBlocksQuerySnapshot.docs.map((doc) => ({ ...doc.data(), journeyBlockId: doc.id }))
}

export const updateJourneyCasting = async (
  journeyId: string,
  journeyCastingData: JourneyCastingData[]
): Promise<void> => {
  try {
    const batch = writeBatch(db)

    for (const { journeyBlockId, expertId, expertStatus } of journeyCastingData) {
      const expertRef = doc(db, `people/${expertId}`)
      const journeyBlockRef = doc(
        db,
        `${JOURNEYS_COLLECTION_NAME}/${journeyId}/${JOURNEYS_BLOCKS_COLLECTION_NAME}/${journeyBlockId}`
      ).withConverter(new GenericConverter<JourneyBlock>())
      batch.update(journeyBlockRef, { expertId, expertStatus, expertRef })
    }

    await batch.commit()
  } catch (error) {
    console.error(error)
  }
}
