import React from 'react'

export default function CheckCircleOrange(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.333496 7.00004C0.333496 10.6823 3.31794 13.6667 7.00016 13.6667C10.6824 13.6667 13.6668 10.6823 13.6668 7.00004C13.6668 3.31782 10.6824 0.333374 7.00016 0.333374C3.31794 0.333374 0.333496 3.31782 0.333496 7.00004ZM10.356 5.91138C10.5729 5.69442 10.5729 5.34267 10.356 5.12571C10.139 4.90875 9.78725 4.90875 9.57029 5.12571L6.25943 8.43657L4.43004 6.60719C4.21308 6.39023 3.86133 6.39023 3.64437 6.60719C3.42741 6.82415 3.42741 7.17591 3.64437 7.39286L5.86659 9.61509C5.97078 9.71927 6.11208 9.7778 6.25943 9.7778C6.40677 9.7778 6.54808 9.71927 6.65226 9.61509L10.356 5.91138Z"
        fill="#FF9F1A"
      />
    </svg>
  )
}
