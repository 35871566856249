import axios from 'axios'

interface Formation {
  name?: string
  description: string
  career?: string[]
  visibility: string
}

const getUrl = `${process.env.REACT_APP_TERA_API}/formations`

export abstract class FormationsService {
  static async getAll(token: string) {
    return await axios.get(getUrl, {
      headers: { __session: token, 'Access-Control-Allow-Origin': '*' }
    })
  }

  static async saveFormation(token: string, data: Formation) {
    return await axios.post(getUrl, data, {
      headers: { __session: token, 'Access-Control-Allow-Origin': '*' }
    })
  }
}
