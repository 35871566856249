import styled from 'styled-components'
import { AutosizeInput } from 'core/components/AutosizeInput'

export const PageHeader = styled.header`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: 4rem 0rem 1.5rem;

  > button {
    background: var(--blue-primary);
    border-radius: 0.5rem;
    color: var(--white);
    font-weight: bold;
    font-size: 0.9rem;
    text-transform: uppercase;
    border-radius: 0.5rem;
    padding: 0.5rem 1rem;
    margin-top: 2.1rem;

    &:disabled {
      background: var(--gray-20);
      cursor: not-allowed;
    }
  }
  > div {
    > h4 {
      text-transform: uppercase;
      font-weight: normal;
      color: var(--gray-60);
    }
  }
`

export const Input = styled(AutosizeInput)`
  border: 0;
  font-size: 2rem;
  line-height: 2rem;
  margin-left: -1rem;
  cursor: default;
  font-weight: bold;
  text-transform: uppercase;

  &::placeholder {
    color: var(--gray-20);
  }
`

export const CustomTabs = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  > section {
    width: 35rem;

    > ul {
      display: flex;
      border-bottom: 1px solid var(--gray-20);

      > li a {
        flex: 1;
        text-decoration: none;
        color: var(--gray-60);
      }

      > li {
        background: var(--white);
        border-bottom: 0.25rem solid transparent;
        color: var(--gray-60);

        font-size: 1rem;
        height: 1.75rem;
        min-width: 3rem;
        text-transform: uppercase;
        width: 11rem;
        text-align: center;
        display: flex;
      }
    }
  }
`
export const Tab = styled.li`
  &.selected {
    border-bottom: 0.25rem solid var(--black);
    color: var(--black);
    font-weight: bold;
    padding-bottom: 0.25rem;

    > a {
      color: var(--black);
    }
  }
`
export const GreenWarningModalContainer = styled.div`
  width: calc(100% - 16rem);
  position: fixed;
`

export const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: calc(100% - 17rem);
  position: fixed;
  z-index: 2;
  background: rgba(0, 0, 0, 0.6);
`
