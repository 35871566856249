import React from 'react'

import { Trash } from '@somostera/tera-icons'

import { BlueSelectedModal } from './styles'

interface BlueSelectedModalationModalProps {
  onRequestClose: () => void
  selectedLines?: number
  messageDescription: string
}

export function BlueSelectedModalationModal({
  onRequestClose,
  selectedLines,
  messageDescription
}: BlueSelectedModalationModalProps) {
  return (
    <BlueSelectedModal>
      <div>
        <h4>{selectedLines}</h4>
        <p>{messageDescription}</p>
      </div>

      <button type="button" onClick={onRequestClose}>
        <Trash size={30} color="var(--gray-80)" />
      </button>
    </BlueSelectedModal>
  )
}
