import RemoveCircle from 'core/components/Icon/RemoveCircle'
import React, { createRef, useCallback, useEffect, useState } from 'react'

import { Container, Description, Tag, InputInformationContainer, Error } from './styles'

export type ListInputType = 'default' | 'tag'

interface ListInputProps {
  name: string
  label?: string
  placeholder?: string
  errors?: string
  containerStyle?: object
  value: string[]
  onTagsChange: (tags: string[]) => void
  description?: string
  type?: ListInputType
  required?: boolean
}

export const ListInput = ({
  name,
  label,
  placeholder,
  errors,
  containerStyle,
  value,
  onTagsChange,
  description,
  type = 'default',
  required
}: ListInputProps) => {
  const [newTag, setNewTag] = useState('')
  const [tags, setTags] = useState<string[]>(value)
  const [isFocused, setIsFocused] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [indexToEdit, setIndexToEdit] = useState(0)
  const inputRef = createRef<HTMLInputElement>()

  // updates Tags when value update
  useEffect(() => {
    setTags(value)
  }, [value])

  const editItem = (index: number) => {
    setNewTag(tags[index])
    setIsEditing(true)
    setIndexToEdit(index)
  }

  const removeTag = useCallback(
    (index: number) => {
      const newTags = [...tags]
      newTags.splice(index, 1)
      setTags(newTags)
      onTagsChange(newTags)
    },

    [onTagsChange, tags]
  )

  const handleInputKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLElement>) => {
      switch (event.key) {
        case 'Backspace':
          if (!newTag) {
            removeTag(tags.length - 1)
          }
          break
        case 'Enter':
        case 'Tab':
          event.preventDefault()

          if (isEditing && !newTag) {
            removeTag(indexToEdit)
            setIsEditing(false)
          }

          if (newTag) {
            // value already exists
            if (tags.find((tag) => tag.toLowerCase() === newTag.toLowerCase())) {
              return
            }

            if (isEditing) {
              const newTags = [...tags]
              newTags[indexToEdit] = newTag
              setTags([...newTags])
              setNewTag('')
              onTagsChange([...newTags])
              setIsEditing(false)
            } else {
              setTags([...tags, newTag])
              setNewTag('')

              onTagsChange([...tags, newTag])
            }
          }

          break
        default:
          break
      }
    },
    [newTag, onTagsChange, removeTag, tags]
  )

  const handleClickContainer = useCallback(() => {
    inputRef.current?.focus()
    setIsFocused(true)
  }, [inputRef])

  const handleInputFocus = useCallback(() => {
    setIsFocused(true)
  }, [])

  const handleInputBlur = useCallback(() => {
    setIsFocused(false)
  }, [])

  return (
    <div>
      <InputInformationContainer>
        {required && !!label ? (
          <>
            <label htmlFor={name}>{label}</label>
            <span>*</span>
          </>
        ) : (
          <label htmlFor={name}>{label}</label>
        )}
      </InputInformationContainer>

      {description && <Description>{description}</Description>}

      <Container onClick={handleClickContainer} isFocused={isFocused} isInvalid={!!errors} {...containerStyle}>
        <ul>
          {!!tags &&
            tags.map((tag, index) => (
              <Tag key={tag} tagKey={index + 1} type={type} onClick={() => editItem(index)}>
                <span>&#8226;</span>
                <span>{tag}</span>
              </Tag>
            ))}
          <li>
            <textarea
              name={name}
              placeholder={!!tags && !tags.length ? placeholder : ''}
              onKeyDown={handleInputKeyDown}
              onFocus={handleInputFocus}
              onBlur={handleInputBlur}
              value={newTag}
              onChange={(event) => setNewTag(event.target.value)}
            />
          </li>
        </ul>
      </Container>
      {!!errors && <Error>{errors}</Error>}
    </div>
  )
}
