import React from 'react'

import { useAuth } from '../../hooks/useAuth'

import { GoogleLogo } from '@somostera/tera-icons'
import { Button } from '../../components/Button'

import { Container } from './styles'

export function SignIn() {
  const { googlePopupSignIn } = useAuth()

  return (
    <Container>
      <Button onClick={googlePopupSignIn}>
        <GoogleLogo size="1rem" />
        Entrar com Google
      </Button>
    </Container>
  )
}
