/* eslint-disable prettier/prettier */
import Info from 'core/components/Icon/Info'
import React, { useEffect, useState } from 'react'
import { CardContainer, OptionalTooltip, TypeTooltip } from './styles'

interface CardProps {
  handleShowAside: (article: object) => void
  article: object
  item?: object
}

export function Card({ handleShowAside, article, item }: CardProps) {
  const [isOptional, setIsOptional] = useState(false)

  function getTypeOfContent(name: string) {
    const nameToLowerCase = name?.toLowerCase()

    switch (nameToLowerCase) {
      case 'article':
        return 'Artigo'
      case 'class':
        return 'Aula'
      case 'challenge':
        return 'Desafio'
      case 'replay':
        return 'Replay'
      case 'branded-challenge':
        return 'Desafio de Mercado'
      case 'tutorial':
        return 'Projeto/Tutorial'
      case 'case':
        return 'Estudo de Caso'
      case 'podcast':
        return 'Podcast'
      case 'narrative':
        return 'Narrativa da Jornada'
      case 'questions-answers':
        return 'Q&A'
      case 'internal':
        return 'Interno'
      case 'debate':
        return 'Debate (ou talk)'
      default:
        return 'Sem tipo definido'
    }
  }

  useEffect(() => {
    setIsOptional(item?.isOptional)
  }, [item?.isOptional])

  return (
    <CardContainer>
      <header>
        <Info onClick={() => handleShowAside(article)} />
        <TypeTooltip>{getTypeOfContent(article.type)}</TypeTooltip>
        {isOptional && <OptionalTooltip>Opcional</OptionalTooltip>}
      </header>
      <section>
        <h3>{article.name}</h3>
        <p>{article.description}</p>
      </section>
    </CardContainer>
  )
}
