import styled from 'styled-components'

// TODO: Deletar please
export const Container = styled.a`
  align-items: center;
  display: flex;
  justify-content: center;
  text-decoration: none;
`

export const ProfileData = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-left: 1rem;

  p {
    color: var(--black);
    font-size: 1rem;
    line-height: 1.5rem;
  }

  span {
    color: var(--gray-60);
    font-size: 0.875rem;
    line-height: 120%;
  }
`

export const ContainerProfilePicture = styled.div`
  border-radius: 50%;
  height: 2.75rem;
  width: 2.75rem;
  overflow: hidden;
`

export const ProfilePicture = styled.img`
  width: 100%;
  object-fit: cover;
  height: 100%;
  align-items: center;
`
