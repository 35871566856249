import React from 'react'

export default function CheckCircle(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.00016 0.333313C3.31826 0.333313 0.333496 3.31808 0.333496 6.99998C0.333496 10.6819 3.31826 13.6666 7.00016 13.6666C10.6821 13.6666 13.6668 10.6819 13.6668 6.99998C13.6668 3.31808 10.6821 0.333313 7.00016 0.333313ZM10.1651 5.77623C10.4097 5.50104 10.3849 5.07966 10.1097 4.83505C9.83456 4.59044 9.41318 4.61522 9.16856 4.89041L6.11128 8.32985L4.83178 6.89041C4.58716 6.61522 4.16578 6.59044 3.89059 6.83505C3.61541 7.07966 3.59062 7.50104 3.83523 7.77623L5.61301 9.77623C5.73952 9.91856 5.92086 9.99999 6.11128 9.99999C6.30171 9.99999 6.48304 9.91856 6.60955 9.77623L10.1651 5.77623Z"
        fill="#45A557"
      />
    </svg>
  )
}
