import styled, { css } from 'styled-components'

export const Container = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 6.625rem;

  button {
    align-items: center;
    background: transparent;
    display: flex;
    height: 1.25rem;
    justify-content: center;
    width: 1.25rem;
  }
`

interface InputContainerProps {
  isFocused: boolean
  isInvalid: boolean
}

export const InputContainer = styled.div<InputContainerProps>`
  border: 1.5px solid var(--gray-40);
  border-radius: 0.25rem;
  height: 2.5rem;
  margin: 0 0.75rem;
  position: relative;
  width: 3rem;

  ${(props) =>
    props.isFocused &&
    css`
      border-color: var(--blue-primary);
    `}

  ${(props) =>
    props.isInvalid &&
    css`
      border-color: var(--orange);
    `}

  // remove spin buttons from number input
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }

  input {
    background: transparent;
    border: 0;
    font-size: 1rem;
    line-height: 1.25rem;
    height: 100%;
    outline: 0;
    text-align: center;
    width: 100%;

    &::placeholder {
      color: var(--gray-60);
    }
  }

  svg {
    position: absolute;
    right: 0.625rem;
    top: calc(50% - 12px);

    &:hover {
      filter: brightness(0.8);
    }
  }
`

export const InputInformationContainer = styled.div`
  align-items: baseline;
  display: flex;
  justify-content: space-between;

  label {
    color: var(--gray-60);
    display: block;
    font-size: 0.75rem;
    font-weight: bold;
    line-height: 1.25rem;
    text-transform: uppercase;
    margin: 1.5rem 0 0.25rem;
  }

  p {
    color: var(--gray-80);
    font-weight: 300;
    font-size: 1rem;
    line-height: 1.25rem;
  }
`

export const Error = styled.p`
  color: var(--orange);
  font-size: 1rem;
  line-height: 1.25rem;
  margin-top: 0.5rem;
`
