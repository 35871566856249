import { PortfolioData } from '../@types/portfolio'

export interface State {
  isLoading: boolean
  personPortfolioTableData: PortfolioData[]
  currentPortfolioClass: PortfolioData
  error?: string
}

export type Action =
  | { type: 'ASYNC_CALL' }
  | { type: 'ASYNC_CALL_SUCCEED' }
  | { type: 'ASYNC_CALL_FAILED'; payload: { error: string } }
  | { type: 'SET_PERSON_PORTFOLIO_TABLE_DATA'; payload: { portfolioTableData: PortfolioData[] } }
  | { type: 'SET_CURRENT_PORTFOLIO_DATA'; payload: { portfolioClass?: PortfolioData } }

export const portfolioReducer = (draft: State, action: Action): State => {
  switch (action.type) {
    case 'ASYNC_CALL':
      draft.isLoading = true
      break
    case 'ASYNC_CALL_SUCCEED':
      draft.isLoading = false
      break
    case 'ASYNC_CALL_FAILED':
      draft.isLoading = false
      draft.error = action.payload.error
      break
    case 'SET_PERSON_PORTFOLIO_TABLE_DATA':
      draft.personPortfolioTableData = action.payload.portfolioTableData
      break
    case 'SET_CURRENT_PORTFOLIO_DATA':
      if (action.payload.portfolioClass) {
        draft.currentPortfolioClass = action.payload.portfolioClass
      }
      break
    default:
      break
  }
  return draft
}
