import React, { ChangeEvent, ReactElement, useState, useEffect } from 'react'
import Doc from 'core/components/Icon/Doc'
import { Popover } from 'react-tiny-popover'

import { InputSmall } from 'core/components/InputSmall'

import { Container, MoreFiltersButton, ResetButton } from './styles'
import { Search } from '@somostera/tera-icons'

interface FilterTableProps {
  filterText: string
  onPopoverChange?: boolean
  onFilter: (event: ChangeEvent<HTMLInputElement>) => void
  onClear: () => void
  placeholder?: string
  hasMoreFilters?: boolean
  numberOfSelectedFilters?: number
  MoreFiltersComponent?: ReactElement
  showResetButton?: boolean
}

export const FilterTable = ({
  filterText,
  onFilter,
  onClear,
  placeholder,
  hasMoreFilters = false,
  showResetButton = true,
  onPopoverChange = true,
  numberOfSelectedFilters = 0,
  MoreFiltersComponent = <div>teste</div>
}: FilterTableProps) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)

  function closePopover() {
    setIsPopoverOpen(false)
  }

  useEffect(() => {
    closePopover()
  }, [onPopoverChange])

  return (
    <Container>
      <InputSmall
        name="search"
        list="historico"
        placeholder={placeholder}
        icon={() => <Search />}
        value={filterText}
        onChange={onFilter}
      />
      {hasMoreFilters && (
        <Popover
          isOpen={isPopoverOpen}
          positions={['bottom', 'right']}
          onClickOutside={() => setIsPopoverOpen(false)}
          reposition={false}
          padding={4}
          content={MoreFiltersComponent}
          align="start"
        >
          <MoreFiltersButton
            hasSelectedFilter={numberOfSelectedFilters > 0}
            onClick={() => setIsPopoverOpen(!isPopoverOpen)}
          >
            <Doc color="#000" size={20} />
          </MoreFiltersButton>
        </Popover>
      )}
      {showResetButton && numberOfSelectedFilters !== 0 && <ResetButton onClick={onClear}>Limpar filtros</ResetButton>}
    </Container>
  )
}
