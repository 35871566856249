import styled from 'styled-components'
import { DropzoneRootProps } from 'react-dropzone'
import { ArrowRefresh } from '@somostera/tera-icons'

interface DropzoneRootsCustomProps extends DropzoneRootProps {
  hasFile: boolean
}

export const Container = styled.div<DropzoneRootProps>`
  > p {
    color: var(--gray-40);
    font-weight: normal;
    line-height: 1.5rem;
    margin: 1rem 0;
    text-align: center;
    text-transform: uppercase;
  }

  button {
    background: var(--blue-primary);
    border-radius: 0.5rem;
    box-shadow: 0 6px 12px rgba(51, 51, 51, 0.25);
    color: var(--white);
    font-weight: bold;
    font-size: 1rem;
    height: 3rem;
    line-height: 1.25rem;
    text-transform: uppercase;
    width: 100%;

    &:hover {
      transform: translate(1px, 1px);
    }
  }
`

export const DropzoneContainer = styled.div<DropzoneRootsCustomProps>`
  align-items: center;
  border-radius: 0.25rem;
  border: ${(props) =>
    props.isDragActive ? '1.5px solid var(--blue-primary)' : props.hasFile ? 'none' : '2px dashed var(--gray-40)'};
  color: var(--gray-60);
  cursor: pointer;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  transition: border 0.24s ease-in-out;
  height: 16.25rem;

  p {
    color: ${(props) => (props.isDragActive ? 'var(--blue-primary)' : 'var(--gray-80)')};
    font-size: 1rem;
    font-weight: ${(props) => (props.isDragActive ? 'bold' : 'normal')};
    margin-top: 1rem;
    max-width: 20rem;
    text-transform: uppercase;
    text-align: center;

    > span {
      color: var(--gray-80);
      text-decoration: underline;
      display: inline;
      text-transform: uppercase;
    }

    & + p {
      text-transform: lowercase;
    }
  }

  img,
  video {
    height: auto;
    width: auto;
    max-width: 100%;
    max-height: 100%;
  }
`

export const LoadingIcon = styled(ArrowRefresh)`
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(359deg);
    }
  }
  animation: spin 2s linear infinite;
`
