interface Chapter {
  index: number
  contentType: string
  content?: {
    type?: string
    content?: ChapterArray[]
    text?: string
  }
  title: string
}

interface ChapterContent {
  text?: string
  type?: string
}

interface ChapterArray {
  type: string
  content?: ChapterContent[]
  attrs?:
    | {
        poster: string
        allowfullscreen: boolean
        controls: boolean
        duration: number
        frameborder: number
        src: string
      }
    | undefined
}

export const getNumberOfWords = (chapters: Chapter[] | undefined): number => {
  let words = 0

  if (chapters) {
    chapters.forEach((chapter) => {
      if (chapter.content && chapter.content.content) {
        chapter.content.content.forEach((node: ChapterArray) => {
          if (node && node.content) {
            node.content.forEach((content: ChapterContent) => {
              if (content.text) {
                words += content.text.split(' ').length
              }
            })
          }
        })
      }
    })
  }

  return words
}

export const getReadingTime = (chapters?: Chapter[]) => {
  if (chapters) {
    const AVERAGE_SPEED = 260 // words per minute average
    const numberOfWords = getNumberOfWords(chapters)

    return Math.ceil(numberOfWords / AVERAGE_SPEED) + getDurationOfVideos(chapters)
  }

  return 0
}

export const getNumberOfVideos = (chapters: Chapter[]) => {
  let numberOfVideos = 0
  if (chapters) {
    chapters.forEach((chapter) => {
      if (chapter.content && chapter.content.content) {
        chapter.content.content.forEach((node) => {
          if (node && node.type) {
            if (node.type.toLowerCase().includes('custom-video')) {
              numberOfVideos += 1
            }
          }
        })
      }
    })
  }
  return numberOfVideos
}

export const getDurationOfVideos = (chapters: Chapter[]) => {
  let minutes = 0
  if (chapters) {
    chapters.forEach((chapter) => {
      if (chapter.content && chapter.content.content) {
        chapter.content.content.forEach((node) => {
          if (node && node.type) {
            if (node.type.toLowerCase().includes('custom-video')) {
              minutes += Math.ceil(node.attrs!.duration / 60)
            }
          }
        })
      }
    })
  }
  return minutes
}
