import styled from 'styled-components'

export const Container = styled.div`
  background: #ededed;
  display: flex;
  flex-direction: column;

  > section {
    width: 40rem;

    h2 {
      font-family: 'Inter';
      font-size: 2rem;
      line-height: 2rem;
      letter-spacing: -0.02rem;
      font-weight: 900;
      text-transform: uppercase;
      padding-top: 2.5rem;
    }
  }
`

export const FormContainer = styled.form`
  background: #fff;
  border: 0.0625rem solid #f2f2f2;
  box-shadow: 0 0.25rem 1.5625rem rgba(0, 0, 0, 0.04);
  padding: 1.25rem;
  margin-top: 17px;
  margin-bottom: 125px;
  > h3 {
    text-transform: uppercase;
    font-family: 'Rubik';
    font-size: 1rem;
    line-height: 1.5rem;
    letter-spacing: 0.01rem;
    margin-bottom: 1rem;
  }
`

export const ToggleSwitchContainer = styled.div`
  display: flex;
  flex-direction: column;

  > h3 {
    margin-top: 1rem;
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 0.75rem;
    letter-spacing: 0.01rem;
    text-transform: uppercase;
    color: #000;
  }

  > div {
    margin-top: 1rem;
    display: flex;
    gap: 0.5rem;
    align-items: center;

    > label {
      margin-top: 0;
    }

    > span {
      font-size: 0.875rem;
      line-height: 1rem;
      font-weight: 400;
    }
  }
`

export const Footer = styled.footer`
  background-color: #000;
  width: 100%;
  height: 56px;

  display: flex;
  justify-content: flex-end;

  position: sticky;
  bottom: 0;

  > button {
    height: 100%;
    padding: 12px 64px 12px 64px;
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 700;
    background-color: #000;
    color: #fff;

    &:last-child {
      background-color: #8ffe81;
      color: #000;
    }
  }
`
