import React, { useEffect, useState } from 'react'
import { useNetwork } from 'modules/network/hooks/useNetwork'
import { useForm } from 'core/hooks/useForm'
import { Course, FormatBlock, PeopleRoleTypes } from '@somostera/tera-database'

import { PortfolioClassFormValues } from 'modules/network/@types/portfolio'
import { useNavigate } from 'react-router-dom'
import { InformationModal as SuccessSavedPortfolioClassModal } from 'core/components/InformationModal'

import { Select } from 'core/components/Select'
import { Radio } from 'core/components/Radio'
import { DialogLeavingPage } from 'core/components/DialogLeavingPage'
import { useNavigatingAway } from 'core/hooks/useNavigatingAway'
import { Container, Header, Form, HorizontalPanel, Label } from './styles'
import { usePortfolio } from 'modules/network/hooks/usePortfolio'
import { getAllCourses } from 'modules/network/services/course'
import { useParams } from 'react-router'
import { LoadingContainer } from '../PersonPortfolio/styles'
import { Loading } from 'core/components/Animation/Loading'

export const AddPortfolioClass = () => {
  const [dataCourse, setDataCourse] = useState<Course[]>([])

  const { networkPersonSelected, getPersonById } = useNetwork()
  const {
    addItemToPortfolio,
    isLoading,
    getBuildingBlockByAcronymCourseAndBlockType,
    arrayBuildingBlock,
    portfolioByPeopleId
  } = usePortfolio()
  const [isSuccessSavedPortfolioClassModalOpen, setIsSuccessSavedPortfolioClassModalOpen] = useState(false)
  const { personId } = useParams()
  const navigate = useNavigate()

  const handleSaveForm = async () => {
    if (personId !== undefined) {
      await addItemToPortfolio(data, personId)
    }
    setCanShowDialogLeavingPage(false)
    setIsSuccessSavedPortfolioClassModalOpen(true)
  }

  useEffect(() => {
    const findAllCouses = async () => {
      await getPersonById(personId || '')
      const receiveCourses = await getAllCourses()

      receiveCourses.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
      setDataCourse(receiveCourses)
    }
    findAllCouses()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personId])

  const { data, handleChange, handleSubmit, setValue, setFullData } = useForm<PortfolioClassFormValues>({
    initialValues: {
      blockId: 'default',
      courseAcronym: 'default',
      type: undefined,
      format: undefined
    },
    onSubmit: handleSaveForm,
    validations: {
      blockId: { required: { value: true, message: 'Preencha o campo obrigatório.' } },
      courseAcronym: { required: { value: true, message: 'Preencha o campo obrigatório.' } },
      format: { required: { value: true, message: 'Preencha o campo obrigatório.' } },
      type: { required: { value: true, message: 'Preencha o campo obrigatório.' } }
    }
  })

  const handleCloseSuccessModal = async () => {
    setIsSuccessSavedPortfolioClassModalOpen(false)
    if (personId !== undefined) {
      await portfolioByPeopleId(personId)
      navigate(`/network/person/${personId}/portfolio`)
    }
  }

  useEffect(() => {
    setFullData({ blockId: 'default', courseAcronym: 'default', type: data.type, format: undefined })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.type])

  useEffect(() => {
    setFullData({ blockId: 'default', courseAcronym: data.courseAcronym, type: data.type, format: undefined })
    if (data.courseAcronym !== '' && data.courseAcronym !== 'default') {
      getBuildingBlockByAcronymCourseAndBlockType(data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.courseAcronym])

  useEffect(() => {
    setValue('format', undefined)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.blockId])

  const [canShowDialogLeavingPage, setCanShowDialogLeavingPage] = useState(false)
  const [showDialogLeavingPage, confirmNavigation, cancelNavigation] = useNavigatingAway(canShowDialogLeavingPage)

  const handleInputChange = (inputName: any) => (e: any) => {
    setValue(inputName, e.target.value)

    // value is asynchronic, so it's updated in the next render
    if (e.target.value !== '') setCanShowDialogLeavingPage(true)
    else {
      setCanShowDialogLeavingPage(false)
      handleChange(inputName)
    }
  }

  return (
    <Container>
      {isLoading && (
        <LoadingContainer>
          <Loading />
        </LoadingContainer>
      )}
      <SuccessSavedPortfolioClassModal
        messageType="BlueWarning"
        messageTitle="Tudo Certo!"
        messageDescription="A aula foi adicionada ao portfólio da expert."
        isOpen={isSuccessSavedPortfolioClassModalOpen}
        onRequestClose={() => handleCloseSuccessModal()}
      />

      <Form onSubmit={handleSubmit}>
        <Header>
          <div>
            <span>{networkPersonSelected.name}</span>

            <h1>Adicionar Nova Aula</h1>
          </div>

          <p>Preencha as informações abaixo:</p>
        </Header>

        <Label>A aula será vinculada a qual papel?</Label>

        <HorizontalPanel>
          <Radio
            name="type"
            labelText="Expert"
            value={PeopleRoleTypes.EXPERT}
            checked={data.type === 'expert'}
            onChange={handleInputChange('type')}
          />

          <Radio
            name="type"
            labelText="Mentora"
            value={PeopleRoleTypes.MENTOR}
            checked={data.type === 'mentor'}
            onChange={handleInputChange('type')}
          />
        </HorizontalPanel>

        <Select
          name="courseAcronym"
          label="Curso *"
          placeholder="Escolha uma das opções"
          items={dataCourse.map((index) => {
            return { value: `${index.acronym}`, name: `${index.name}` }
          })}
          selectedValue={data.courseAcronym}
          onChange={handleInputChange('courseAcronym')}
          selectStyle={{ height: '3rem', width: '35rem' }}
          disabled={data.type === undefined}
        />

        <Select
          name="name"
          label="Building Block *"
          placeholder="Escolha uma das opções"
          items={arrayBuildingBlock.map((index: any) => {
            return { value: `${index.blockId}`, name: `${index.name}` }
          })}
          selectedValue={data.blockId}
          onChange={handleInputChange('blockId')}
          selectStyle={{ height: '3rem', width: '35rem' }}
          disabled={data.courseAcronym === 'default'}
        />

        <Select
          name="format"
          label="Formato *"
          placeholder="Escolha uma das opções"
          items={Object.entries(FormatBlock).map(([value, name]) => ({ value, name }))}
          selectedValue={data.format === undefined ? 'default' : data.format}
          onChange={handleInputChange('format')}
          selectStyle={{ height: '3rem', width: '35rem' }}
          disabled={data.blockId === 'default' || data.blockId === ''}
        />

        <hr />

        <button
          type="submit"
          disabled={
            data.type === undefined ||
            data.courseAcronym === 'default' ||
            data.blockId === 'default' ||
            data.format === undefined
          }
          onSubmit={() => handleSaveForm()}
        >
          Adicionar Aula
        </button>
      </Form>

      <DialogLeavingPage
        showDialog={showDialogLeavingPage}
        setShowDialog={setCanShowDialogLeavingPage}
        confirmNavigation={confirmNavigation}
        cancelNavigation={cancelNavigation}
      />
    </Container>
  )
}
