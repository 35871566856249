import React, { ChangeEvent } from 'react'

import { JourneyCastingData } from '../../@types/casting'

import { Select, SelectItem } from 'core/components/Select'

import { Container } from './styles'

interface ExpertCellProps {
  row: JourneyCastingData
  onUpdateCell: (event: ChangeEvent<HTMLSelectElement>) => void
}

export const ExpertCell = ({ row, onUpdateCell }: ExpertCellProps) => {
  const items = row.validExpertsForClass.map(
    ({ expertName, expertId }) =>
      ({
        value: expertId,
        name: expertName
      } as SelectItem)
  )

  return (
    <Container>
      <Select
        name="expert"
        selectStyle={{
          background: 'transparent',
          border: 0,
          fontSize: '0.875rem',
          height: '1rem',
          minHeight: '1rem',
          paddingLeft: 0,
          width: '100%'
        }}
        items={items}
        onChange={onUpdateCell}
        selectedValue={row.expertId || 'default'}
      />
    </Container>
  )
}
