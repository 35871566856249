import React from 'react'

import { NodeViewProps } from '@tiptap/react'
import PromptBox from '../../PromptBox'
import { NodeContainer } from './styles'

export const PromptBoxComponent = ({ node, updateAttributes }: NodeViewProps) => {
  return (
    <NodeContainer>
      <PromptBox prompt={node.attrs.prompt} updateAttributes={updateAttributes} sendToAiAssistant={() => {console.log('send')}} />
    </NodeContainer>
  )
}
