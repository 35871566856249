import React from 'react'

import { ToggleSwitchWrapper } from './styles'

interface ToggleSwitchProps {
  onClick: () => void
  isChecked: boolean
}

const ToggleSwitch = ({ onClick, isChecked }: ToggleSwitchProps) => {
  return (
    <ToggleSwitchWrapper>
      <input type="checkbox" onChange={onClick} checked={isChecked} />
      <span />
    </ToggleSwitchWrapper>
  )
}

export default ToggleSwitch
