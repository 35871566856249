import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem 0;
`

export const MediaContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 9rem;
  width: 14rem;
  position: relative;

  > div {
    width: 100%;
    height: 100%;
    background: var(--gray-20);
  }

  > img,
  video {
    max-height: 9rem;
    max-width: 14rem;
  }

  > span {
    position: absolute;
    margin-top: 1.5rem;

    > img {
      height: 4.2rem;
    }
  }
`

export const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 1rem;
  width: 18rem;

  > div {
    display: flex;
    flex-direction: column;
  }

  > section {
    margin-top: 1rem;
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
  }
`

export const InfoContainer = styled.div`
  display: flex;
  width: 100%;

  & + div {
    margin-top: 0.5rem;
  }

  > p {
    color: var(--black);
    min-width: 7rem;
  }

  > span {
    color: var(--gray-80);
  }
`
const colors = {
  1: 'var(--blue-pastel)',
  2: 'var(--purple-pastel)',
  3: 'var(--green-pastel)',
  4: 'var(--yellow-pastel)'
} as { [key: string]: string }

interface TagProps {
  tagKey: number
}

export const Tag = styled.div<TagProps>`
  background: ${(props) =>
    props.tagKey && props.tagKey >= 1 && props.tagKey <= 4 ? colors[props.tagKey] : 'var(--gray-20)'};
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--black);
  padding: 0.2rem 1rem;
  border-radius: 1rem;
  white-space: nowrap;
  text-transform: capitalize;
`
