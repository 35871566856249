import React, { FormEvent, useEffect, useState } from 'react'

import { Tab, TabList, TabPanel } from 'react-tabs'
import { Media, MediaStatus, MediaType } from '@somostera/tera-models-ts'
import { X } from '@somostera/tera-icons'

import { addMedia } from 'modules/contents/services/medias'
import { FileInput } from 'core/components/FileInput'
import {
  Container,
  CustomTabs,
  Form,
  UploadContainer,
  ButtonsContainer,
  ConfirmButton,
  CancelButton,
  LinkContainer
} from './styles'
import { Input } from 'core/components/Input'
import { useForm } from 'core/hooks/useForm'

import { BuildingBlockFormValues } from 'modules/buildingblock/@types/buildings'
import { LibraryMaterialsWithId } from 'modules/buildingblock/hooks/useBuildingBlock'

export interface MediaFormValues {
  fileUrl: string
}

interface AddMediaModalProps {
  isOpen: boolean
  onRequestClose: () => void
  onAddMedia: (libraryMaterials: LibraryMaterialsWithId) => void
  hasLink?: boolean
  libraryMaterials?: LibraryMaterialsWithId
}

export function AddMediaModalBuildingBlock({
  isOpen,
  onRequestClose,
  onAddMedia,
  hasLink = false,
  libraryMaterials
}: AddMediaModalProps) {
  const [tabIndex, setTabIndex] = useState(0)
  const [fileSize, setFileSize] = useState(0)
  const [fileUrl, setFileUrl] = useState('')
  const [uploadedFile, setUploadedFile] = useState<File>()
  const [linkAddress, setLinkAddress] = useState('')
  const [nameLink, setNameLink] = useState('')

  const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (fileUrl !== '' && uploadedFile) {
      const media: Media = {
        name: uploadedFile.name,
        status: MediaStatus.PUBLISHED,
        tags: [],
        source: '',
        description: '',
        transcription: '',
        type: MediaType.DOWNLOAD,
        url: fileUrl
      }

      const id = await addMedia(media)
      if (!id) {
        return
      }

      onAddMedia({
        id,
        url: fileUrl,
        sizeInMb: String(fileSize) || '0',
        name: uploadedFile.name,
        type: uploadedFile.type
      })

      resetFormState()
      onRequestClose()
    }
  }
  const onSubmitLink = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (nameLink !== '' && linkAddress) {
      const media: Media = {
        name: nameLink,
        status: MediaStatus.PUBLISHED,
        tags: [],
        source: '',
        description: '',
        transcription: '',
        type: MediaType.EXTERNAL_LINK,
        url: linkAddress
      }

      const id = libraryMaterials?.id ? libraryMaterials.id : await addMedia(media)
      if (!id) {
        return
      }

      onAddMedia({
        id,
        url: linkAddress,
        sizeInMb: String(fileSize) || '0',
        name: nameLink,
        type: MediaType.EXTERNAL_LINK
      })

      resetFormState()
      onRequestClose()
    }
  }

  const resetFormState = () => {
    setTabIndex(0)
    setLinkAddress('')
    setNameLink('')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }

  useEffect(() => {
    resetFormState()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    resetFormState()
    if (libraryMaterials?.name) {
      setTabIndex(0)
      setLinkAddress(libraryMaterials?.url || '')
      setNameLink(libraryMaterials?.name || '')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [libraryMaterials, isOpen])

  const handleCancelAddMedia = () => {
    resetFormState()
    onRequestClose()
  }

  const { handleChange, errors, setValue } = useForm<BuildingBlockFormValues>({
    initialValues: {
      nameLink: '',
      linkAdress: ''
    },

    validations: {
      description: { required: { value: true, message: 'Preencha o campo obrigatório.' } }
    }
  })
  const handleFormChange = (inputLink: any) => (e: any) => {
    setValue(inputLink, e.target.value)
    handleChange(inputLink)
    if (inputLink === 'linkAdress') {
      setLinkAddress(e.target.value)
    }
    if (inputLink === 'nameLink') {
      setNameLink(e.target.value)
    }
  }

  return (
    <Container
      isOpen={isOpen}
      onRequestClose={handleCancelAddMedia}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <button type="button" onClick={handleCancelAddMedia} className="react-modal-close">
        <X color="var(--gray-40)" size={24} />
      </button>

      <CustomTabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
        <TabList>
          {!libraryMaterials?.name && <Tab onClick={resetFormState}>Upload</Tab>}
          {hasLink && <Tab onClick={resetFormState}>Link</Tab>}
        </TabList>

        {!libraryMaterials?.name && (
          <TabPanel>
            <Form onSubmit={onSubmit}>
              <UploadContainer>
                <FileInput
                  name="fileUrl"
                  fileType={MediaType.DOWNLOAD}
                  acceptedFilesRegex={[
                    'application/vnd.ms-powerpoint',
                    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
                    'application/vnd.google-apps.presentation',
                    'application/vnd.oasis.opendocument.presentation',
                    'application/msword',
                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                    'application/vnd.google-apps.document',
                    'application/vnd.oasis.opendocument.text',
                    'application/vnd.ms-excel',
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    'application/vnd.google-apps.spreadsheet',
                    'application/vnd.oasis.opendocument.spreadsheet',
                    'application/pdf',
                    'application/json',
                    'text/xml',

                    'text/csv',
                    'text/tab-separated-values',
                    'text/tsv',
                    '.ipynb',
                    'image/jpeg',
                    'image/bmp',
                    'image/x-ms-bmp',
                    'image/png',
                    'text/markdown',
                    '.tsv'
                  ].join(',')}
                  onFileUrlChange={(fileUrl: string) => setFileUrl(fileUrl)}
                  onFileSizeChange={(size: number) => setFileSize(size)}
                  onFileChange={(file: File) => setUploadedFile(file)}
                />
              </UploadContainer>

              <ButtonsContainer>
                <CancelButton onClick={handleCancelAddMedia}>Cancelar</CancelButton>
                <ConfirmButton type="submit">Adicionar</ConfirmButton>
              </ButtonsContainer>
            </Form>
          </TabPanel>
        )}

        <TabPanel>
          <Form onSubmit={onSubmitLink}>
            <LinkContainer>
              <Input
                type="url"
                name="linkAddress"
                label="Endereço do link"
                value={linkAddress}
                onChange={handleFormChange('linkAdress')}
                errors={errors.linkAdress}
              />
              <Input
                type="name"
                name="nameLink"
                label="Nome do link"
                placeholder="Escreva como o link vai aparecer. Ex: Framework de UX"
                value={nameLink}
                onChange={handleFormChange('nameLink')}
                errors={errors.nameLink}
              />
              <ButtonsContainer>
                <ConfirmButton type="submit">{libraryMaterials?.name ? 'Editar Link' : 'Adicionar link'}</ConfirmButton>
              </ButtonsContainer>
            </LinkContainer>
          </Form>
        </TabPanel>
      </CustomTabs>
    </Container>
  )
}
