import { ArticleDraft, InitializeArticleService, Article } from '@somostera/tera-database'
import { db } from 'core/config/firebase'

const articleDraftService = InitializeArticleService.initialize(db)

export const deleteArticleDraftService = async (articleToBeRemoved: string) => {
  await articleDraftService.delete(articleToBeRemoved)
}

export const findJourneyBlockById = async (blockId: string) => {
  return await articleDraftService.findById(blockId)
}

export const updateArticleDraft = async (articleId: string, article: ArticleDraft) => {
  return await articleDraftService.update(articleId, article)
}

export const updateArticle = async (articleId: string, article: Article) => {
  return await articleDraftService.updateArticle(articleId, article)
}

export const findArticleById = async (articleId: string, ignoreDraft: boolean) => {
  return await articleDraftService.findById(articleId, ignoreDraft)
}
