import styled from 'styled-components'

export const PrivateRouteContainer = styled.div`
  display: flex;

  > div {
    margin-left: 15rem;
    min-width: 50rem;
    /* 100% - menu width */
    width: calc(100% - 15rem);
    height: 100vh;
    background-color: #fcfcfc;
  }
`
