import React from 'react'

export default function OptionsVertical(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="4" height="14" viewBox="0 0 4 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M0.33317 7.00016C0.33317 7.92064 1.07936 8.66683 1.99984 8.66683C2.92031 8.66683 3.6665 7.92064 3.6665 7.00016C3.6665 6.07969 2.92031 5.3335 1.99984 5.3335C1.07936 5.3335 0.33317 6.07969 0.33317 7.00016Z"
        fill="#EDEDED"
      />
      <path
        d="M0.33317 2.00016C0.33317 2.92064 1.07936 3.66683 1.99984 3.66683C2.92031 3.66683 3.6665 2.92064 3.6665 2.00016C3.6665 1.07969 2.92031 0.333496 1.99984 0.333496C1.07936 0.333496 0.33317 1.07969 0.33317 2.00016Z"
        fill="#EDEDED"
      />
      <path
        d="M0.33317 12.0002C0.33317 12.9206 1.07936 13.6668 1.99984 13.6668C2.92031 13.6668 3.6665 12.9206 3.6665 12.0002C3.6665 11.0797 2.92031 10.3335 1.99984 10.3335C1.07936 10.3335 0.33317 11.0797 0.33317 12.0002Z"
        fill="#EDEDED"
      />
    </svg>
  )
}
