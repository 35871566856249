import React from 'react'

export default function Copy(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M6.43684 21.9999H14.2542C15.2312 21.9999 16.0241 21.1967 16.0241 20.2071V10.723C16.0241 9.73336 15.2312 8.93018 14.2542 8.93018H6.43684C5.45988 8.93018 4.66699 9.73336 4.66699 10.723V20.2089C4.66699 21.1985 5.45811 21.9999 6.43684 21.9999Z"
        stroke={props.color || 'var(--blue-primary)'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.53418 8.93028V5.79283C9.53418 4.80319 10.3271 4 11.304 4H19.1798C20.1568 4 20.9497 4.80319 20.9497 5.79283V15.2787C20.9497 16.2683 20.1568 17.0715 19.1798 17.0715H16.0224"
        stroke={props.color || 'var(--blue-primary)'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
