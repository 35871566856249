// noinspection JSUnusedGlobalSymbols

import { Node, mergeAttributes } from '@tiptap/core'
import { ReactNodeViewRenderer } from '@tiptap/react'
import { QuoteComponent } from './QuoteComponent'

export interface QuoteOptions {
  quote: string
  author: string
  isPreview: boolean
  HTMLAttributes: Record<string, any>
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    quote: {
      /** Add a quote block component */
      setQuote: (options: QuoteOptions) => ReturnType
    }
  }
}

export const Quote = Node.create<QuoteOptions>({
  name: 'quote',
  group: 'block',
  atom: true,
  draggable: true,
  defaultOptions: {
    quote: '',
    author: '',
    isPreview: false,
    HTMLAttributes: {}
  },
  addAttributes() {
    return {
      quote: {
        default: ''
      },
      author: {
        default: ''
      },
      isPreview: {
        default: false
      }
    }
  },
  parseHTML() {
    return [
      {
        tag: 'quote'
      }
    ]
  },
  renderHTML({ HTMLAttributes }) {
    return ['quote', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)]
  },
  addCommands() {
    return {
      setQuote:
        (options) =>
        ({ commands }) =>
          commands.insertContent({
            type: this.name,
            attrs: options
          })
    }
  },
  addNodeView() {
    return ReactNodeViewRenderer(QuoteComponent)
  }
})
