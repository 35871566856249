import React from 'react'

export default function UndoCircle(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.00016 0.333374C3.31826 0.333374 0.333496 3.31814 0.333496 7.00004C0.333496 10.6819 3.31826 13.6667 7.00016 13.6667C10.6821 13.6667 13.6668 10.6819 13.6668 7.00004C13.6668 3.31814 10.6821 0.333374 7.00016 0.333374ZM4.3335 6.33337C3.96531 6.33337 3.66683 6.63185 3.66683 7.00004C3.66683 7.36823 3.96531 7.66671 4.3335 7.66671H9.66683C10.035 7.66671 10.3335 7.36823 10.3335 7.00004C10.3335 6.63185 10.035 6.33337 9.66683 6.33337H4.3335Z"
        fill="#8F8F8F"
      />
    </svg>
  )
}
