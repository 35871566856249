import styled, { css } from 'styled-components'
import { ViewDataType } from '.'

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 30rem;
  gap: 2rem;
  padding: 1.5rem 0;
  border-bottom: 1px solid var(--gray-20);

  > h3 {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 0.875rem;
    color: var(--gray-50);
  }

  > div {
    max-width: 60%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0.4rem;
  }
`

interface ListItemProps {
  type: ViewDataType
  tagKey?: number
}

const colors = {
  1: 'var(--green-pastel)',
  2: 'var(--blue-pastel)',
  3: 'var(--yellow-pastel)',
  4: 'var(--purple-pastel)'
} as { [key: string]: string }

export const ListItem = styled.span<ListItemProps>`
  font-size: 0.875rem;

  ${(props) =>
    props.type === 'default'
      ? css`
          & + span::before {
            content: '| ';
          }
        `
      : css`
          background: ${props.tagKey ? colors[props.tagKey - 4 * (Math.ceil(props.tagKey / 4) - 1)] : 'var(--gray-20)'};
          padding: 0.25rem 1rem;
          border-radius: 9999px;
        `}
`
