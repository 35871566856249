import React from 'react'

import { getDateInformation } from 'core/utils/date'

import { Container } from './styles'

interface DateProps {
  date: Date | any
}

export const DateCell = ({ date }: DateProps) => {
  try {
    const { day, month, year, hours, minutes } = getDateInformation(date)

    return <Container>{`${day}/${month}/${year} - ${hours}h${minutes}`}</Container>
  } catch (e) {
    return <Container>{null}</Container>
  }
}
