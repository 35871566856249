import React, { useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { TableColumn } from 'react-data-table-component'
import { PlusCircle } from '@somostera/tera-icons'
import { Loading } from 'core/components/Animation/Loading'

import { usePortfolio } from '../../hooks/usePortfolio'
import { PortfolioData } from 'modules/network/@types/portfolio'

import { StatusCell } from 'modules/network/components/StatusCell'
import { DataTableBase } from 'core/components/DataTableBase'
import { FilterTable } from 'core/components/DataTableBase/FilterTable'
import { Checkbox } from 'core/components/Checkbox'

import { AddNewClassButton, Container, LoadingContainer, MoreFiltersContainer, SubheaderContainer } from './styles'
import { PortfolioCell } from 'modules/network/components/PortfolioCell'
import { FormatBlock } from '@somostera/tera-database/dist/BuildBlock'

interface FilterOption {
  [key: string]: boolean
}

interface Filters {
  [key: string]: FilterOption
}

interface Dictionary {
  [key: string]: string
}

export const PersonPortfolio = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { personId } = useParams()

  const { isLoading, personPortfolioTableData } = usePortfolio()

  const [filterText, setFilterText] = useState('')
  const [filtersSelected, setFiltersSelected] = useState<string[]>([])

  const statusDictionary: Dictionary = {
    'em-validacao': 'Em Validação',
    indisponivel: 'Indisponível',
    invalidada: 'Invalidada',
    validada: 'Validada'
  }

  const formatDictionary: Dictionary = {
    autodirigido: 'Autodirigido',
    bootcamp: 'Bootcamp',
    evento: 'Evento',
    experiencia: 'Experiência',
    gratuito: 'Gratuito',
    intensivo: 'Intensivo',
    'para-empresas': 'Para empresas',
    'semi-intensivo': 'Semi-intensivo',
    trial: 'Trial',
    'todos-os-formatos': 'Todos os Formatos'
  }

  useEffect(() => {
    if (personPortfolioTableData !== undefined) {
      if (personPortfolioTableData.length === 0) {
        navigate(`/network/person/${personId}/portfolio/add`)
      }
    }
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personPortfolioTableData])

  const columns: TableColumn<PortfolioData>[] = [
    {
      name: 'Aula',
      selector: ({ name }) => name,
      cell: (row: PortfolioData) => <PortfolioCell row={row}>{row.name}</PortfolioCell>,
      sortable: true,
      minWidth: '20rem'
    },
    {
      name: 'Curso',
      selector: ({ courseAcronym }) => courseAcronym?.toUpperCase(),
      cell: (row: PortfolioData) => <PortfolioCell row={row}>{row.courseAcronym?.toUpperCase()}</PortfolioCell>,
      sortable: true,
      width: '7rem'
    },
    {
      name: 'Formato',
      selector: ({ format }) => format,
      cell: (row: PortfolioData) => (
        <PortfolioCell row={row}>
          {Object.values(FormatBlock)[Object.keys(FormatBlock).indexOf(row.format)]}
        </PortfolioCell>
      ),
      sortable: true,
      width: '15rem'
    },
    {
      name: 'Status',
      selector: () => '',
      cell: (row: PortfolioData) => <StatusCell row={row} />,
      sortable: true,
      width: '12rem'
    }
  ]

  const moreFiltersOptionsInitialValue = {
    Formato: {
      autodirigido: false,
      bootcamp: false,
      evento: false,
      experiencia: false,
      gratuito: false,
      intensivo: false,
      'para-empresas': false,
      'semi-intensivo': false,
      trial: false,
      'todos-os-formatos': false
    },
    Status: {
      'em-validacao': false,
      indisponivel: false,
      invalidada: false,
      validada: false
    }
  }

  const [moreFiltersOptions, setMoreFiltersOptions] = useState<Filters>(moreFiltersOptionsInitialValue)

  const handleCheckFilter = (newValue: boolean, filter: string, itemLabel: string) => {
    const newFilterBlock = moreFiltersOptions[filter]
    newFilterBlock[itemLabel] = newValue

    if (newValue) {
      setFiltersSelected([...filtersSelected, itemLabel])
    } else {
      const newFilters = filtersSelected.filter((filterSelected) => filterSelected !== itemLabel)
      setFiltersSelected(newFilters)
    }

    setMoreFiltersOptions({
      ...moreFiltersOptions,
      [filter]: newFilterBlock
    })
  }

  const filteredItems =
    personPortfolioTableData?.filter((row) => {
      if (
        filtersSelected.some(
          (value) =>
            value === 'autodirigido' ||
            value === 'bootcamp' ||
            value === 'evento' ||
            value === 'experiencia' ||
            value === 'gratuito' ||
            value === 'intensivo' ||
            value === 'para-empresas' ||
            value === 'semi-intensivo' ||
            value === 'trial' ||
            value === 'todos-os-formatos'
        )
      ) {
        if (!filtersSelected.includes(row.format)) return false
      }

      if (
        filtersSelected.some(
          (value) =>
            value === 'em-validacao' || value === 'indisponivel' || value === 'invalidada' || value === 'validada'
        )
      ) {
        if (row.status !== undefined && !filtersSelected.includes(row.status)) return false
      }
      return row.name?.toLowerCase().includes(filterText.toLowerCase())
    }) || []

  const SubheaderComponent = useMemo(() => {
    const handleClear = () => {
      setFilterText('')
      setMoreFiltersOptions(moreFiltersOptionsInitialValue)
      setFiltersSelected([])
    }

    return (
      <SubheaderContainer>
        <FilterTable
          placeholder="Busque pela aula"
          onFilter={(event) => setFilterText(event.target.value)}
          onClear={handleClear}
          filterText={filterText}
          hasMoreFilters
          numberOfSelectedFilters={filtersSelected.length}
          MoreFiltersComponent={
            <MoreFiltersContainer>
              {Object.entries(moreFiltersOptions).map(([title, items]) => (
                <div key={title}>
                  <h3 key={title}>{title}</h3>
                  {Object.entries(items).map(([itemLabel, checked]) => (
                    <Checkbox
                      key={itemLabel}
                      checked={checked}
                      labelText={title === 'Status' ? statusDictionary[itemLabel] : formatDictionary[itemLabel]}
                      onChange={(event) => handleCheckFilter(event.target.checked, title, itemLabel)}
                    />
                  ))}
                </div>
              ))}
            </MoreFiltersContainer>
          }
        />

        <AddNewClassButton
          onClick={(event) => {
            event.preventDefault()
            navigate(`${pathname}/add`)
          }}
        >
          <PlusCircle color="var(--white)" size={20} />
          Nova Aula
        </AddNewClassButton>
      </SubheaderContainer>
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterText, moreFiltersOptions])

  return (
    <>
      {isLoading && (
        <LoadingContainer>
          <Loading />
        </LoadingContainer>
      )}
      {personPortfolioTableData !== undefined && personPortfolioTableData.length === 0 ? (
        <LoadingContainer>
          <Loading />
        </LoadingContainer>
      ) : (
        <Container>
          <DataTableBase
            columns={columns}
            data={filteredItems}
            noDataComponent={`${isLoading ? 'Carregando...' : 'Nenhum dado encontrado'}`}
            pagination
            subHeader
            subHeaderComponent={SubheaderComponent}
          />
        </Container>
      )}
    </>
  )
}
