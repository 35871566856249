import React from 'react'
import Select, { StylesConfig } from 'react-select'

import { LabelContainer } from './styles'

interface Option {
  value: string
  label: string
}

interface MultiSelectProps {
  options: Option[]
  onChange: (event: any) => void
  value: Option[]
  required?: boolean
  label?: string
  name: string
}

export function MultiSelect({ options, onChange, value, label, required }: MultiSelectProps) {
  const styles: StylesConfig<Option, true> = {
    control: (base, state) => ({
      ...base,
      minHeight: 48,
      fontSize: 16,
      borderRadius: '2px',
      fontFamily: 'Rubik',
      backgroundColor: '#FCFCFC',
      borderColor: state.isFocused ? ' #3AF221' : '#DBDBDB',
      boxShadow: 'none',
      borderWidth: '1.5px',
      padding: '12px 16px',
      '&:hover': {
        borderColor: 'none',
        boxShadow: 'none'
      }
    }),
    valueContainer: (styles) => ({
      ...styles,
      padding: '0'
    }),

    multiValueRemove: (styles) => ({
      ...styles,
      '&:hover': {
        backgroundColor: 'transparent'
      }
    }),

    menu: (provided) => ({
      ...provided,
      border: `solid 1px#E0E0E0`,
      borderRadius: '0 0 4px 4px',
      borderTop: 'none',
      marginTop: '1px',
      textAlign: 'left'
    }),
    option: (provided, state) => ({
      ...provided,
      height: '48px',
      fontFamily: 'Rubik',
      cursor: 'pointer',
      fontSize: 16,
      overflow: 'hidden',
      color: state.isSelected ? 'black' : '#333333',
      fontWeight: state.isSelected ? '700' : 'normal',
      backgroundColor: state.isSelected ? '#fff' : '#FFFFFF',
      display: 'flex',
      alignItems: 'center',
      textAlign: 'left',
      '&:hover': {
        backgroundColor: '#DCF8D8',
        color: `#000`
      },
      '&:last-child': {
        borderBottom: 'none'
      }
    }),

    indicatorsContainer: (styles) => ({ ...styles, display: 'none' }),
    multiValue: (base, state) => {
      return {
        ...base,
        backgroundColor: '#d0f6cb',
        borderRadius: '1.375rem',
        marginRight: '8px',

        svg: {
          backgroundColor: '#0D4D05',
          color: '#DCF8D8',
          width: '13px',
          height: '13px',
          borderRadius: '50%',

          '&:hover': {
            backgroundColor: 'none',
            borderColor: 'none',
            boxShadow: 'none'
          }
        }
      }
    }
  }

  return (
    <>
      {required ? (
        <LabelContainer>
          {' '}
          <label id="aria-label" htmlFor="aria-example-input">
            {label}
          </label>
          <span>*</span>
        </LabelContainer>
      ) : (
        <LabelContainer>
          {' '}
          <label id="aria-label" htmlFor="aria-example-input">
            {label}
          </label>
        </LabelContainer>
      )}

      <Select
        styles={styles}
        placeholder="Tipos de live"
        isMulti
        options={options}
        classNamePrefix="select"
        onChange={(event) => onChange(event)}
        value={value}
      />
    </>
  )
}
