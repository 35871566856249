import {
  BuildingBlock,
  InitializeBuildBlockService,
  InitializePortfolioService,
  Portfolio
} from '@somostera/tera-database'
import { db } from 'core/config/firebase'
import { PortfolioData } from '../@types/portfolio'

const portfolioService = InitializePortfolioService.initialize(db)

const buildingBlockService = InitializeBuildBlockService.initialize(db)

export const getBlockInformation = async (blockId: string): Promise<BuildingBlock> => {
  return await buildingBlockService.findById(blockId)
}

export const addItemPortfolio = async (itemToPortfolio: Portfolio): Promise<void> => {
  return await portfolioService.addItemToPortfolio(itemToPortfolio)
}

export const updateItemPortfolio = async (itemToPortfolio: Portfolio): Promise<void> => {
  return await portfolioService.updateItemToPortfolio(itemToPortfolio)
}

export const deleteItemPortfolio = async (personId: string, itemId: string): Promise<void> => {
  return await portfolioService.deleteItemToPortfolio(personId, itemId)
}

export const getPortfolioByIdAndPeopleId = async (peopleId: string, portfolioId: string): Promise<PortfolioData> => {
  const portfolio = await portfolioService.findById(peopleId, portfolioId)
  const block = await getBlockInformation(portfolio.blockId)

  return {
    ...portfolio,
    name: block.name
  } as PortfolioData
}

export const getPortfolioByPeopleId = async (peopleId: string): Promise<PortfolioData[]> => {
  const portfolio = await portfolioService.findByPeopleId(peopleId)

  // portfolioService.addItemToPortfolio()

  return await Promise.all(
    portfolio.map(async (item: Portfolio) => {
      const block = await getBlockInformation(item.blockId)

      return {
        ...item,
        name: block.name
      } as PortfolioData
    })
  )
}
