import React from 'react'

import Select, { StylesConfig } from 'react-select'

import { Label } from './styles'

interface SelectItem {
  value: string
  label: string
}

interface InputSelectProps {
  options: SelectItem[]
  type?: string
  index?: number
  label?: string
  placeholder?: string
  value: SelectItem
  onChange: (event: any) => void
}

export function InputSelect({ type = '', index, label, placeholder, value, options, onChange }: InputSelectProps) {
  const styles: StylesConfig<InputSelectProps, true> = {
    control: (base, state) => ({
      ...base,
      height: 48,
      fontSize: 16,
      borderRadius: 2,
      fontFamily: 'Rubik',
      backgroundColor: '#FFFFFF',
      borderColor: state.isFocused ? '#3AF221' : '#DBDBDB',
      boxShadow: 'none',
      borderWidth: '1px',
      '&:hover': {
        borderColor: 'none',
        boxShadow: 'none'
      }
    }),
    valueContainer: (base, state) => ({
      ...base,
      paddingLeft: '16px'
    }),
    singleValue: (styles) => ({
      ...styles,
      margin: 0,
      fontFamily: 'Rubik'
    }),

    multiValueRemove: (styles) => ({
      ...styles,
      '&:hover': {
        backgroundColor: 'transparent'
      }
    }),

    menu: (provided) => ({
      ...provided,
      border: `solid 1px#E0E0E0`,
      borderRadius: '0 0 4px 4px',
      borderTop: 'none',
      marginTop: '1px',
      textAlign: 'left'
    }),
    option: (provided, state) => ({
      ...provided,
      height: '48px',
      fontFamily: 'Rubik',
      cursor: 'pointer',
      fontSize: '16px',
      overflow: 'hidden',
      color: state.isSelected ? 'black' : '#333333',
      fontWeight: state.isSelected ? '700' : 'normal',
      backgroundColor: state.isSelected ? '#fff' : '#FFFFFF',
      display: 'flex',
      alignItems: 'center',
      textAlign: 'left',
      '&:hover': {
        backgroundColor: '#DCF8D8',
        color: `#000`
      },
      '&:last-child': {
        borderBottom: 'none'
      }
    }),

    indicatorsContainer: (styles) => ({ ...styles }),

    indicatorSeparator: (styles) => ({ ...styles, display: 'none' })
  }

  function sortingAlphaticalOrder(options: SelectItem[]) {
    const orderedOptions = options.sort((a, b) => {
      if (a.label > b.label) {
        return 1
      }
      if (a.label < b.label) {
        return -1
      }
      return 0
    })

    if (type === 'expert') {
      orderedOptions.unshift({ value: '1', label: 'Selecione o Expert' })
    } else if (type === 'default') {
      return orderedOptions
    } else {
      orderedOptions.unshift({ value: '1', label: 'Selecione o Facilitador' })
    }

    return orderedOptions
  }

  return (
    <>
      <Label id="aria-label" htmlFor="aria-example-input">
        {label}
      </Label>
      <Select
        isSearchable={true}
        styles={styles}
        options={sortingAlphaticalOrder(options)}
        placeholder={placeholder}
        value={value}
        onChange={type === 'expert' ? (event) => onChange(event, index) : (event) => onChange(event)}
      />
    </>
  )
}
