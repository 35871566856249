import React, { useState } from 'react'
import Tippy, { TippyProps } from '@tippyjs/react/headless'
import 'tippy.js/dist/svg-arrow.css'

import { TooltipContainer, Arrow, Container } from './styles'

interface TooltipProps extends TippyProps {
  title: string
  description?: string
}

export function Tooltip({ title, description, children }: TooltipProps) {
  const [arrow, setArrow] = useState(null)

  if (!children) {
    return <div />
  }

  return (
    <Tippy
      placement="right"
      delay={500}
      arrow
      popperOptions={{
        modifiers: [
          {
            name: 'arrow',
            options: {
              element: arrow
            }
          }
        ]
      }}
      render={(attrs) => (
        <Container>
          <Arrow />
          <TooltipContainer {...attrs} data-popper-arrow="">
            <p>{title}</p>
            {description && <span>{description}</span>}
          </TooltipContainer>
        </Container>
      )}
    >
      {children}
    </Tippy>
  )
}
