import React, { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react'
import { useImmerReducer } from 'use-immer'

import { portfolioReducer, State } from '../reducers/portfolio'
import { BlockType, Course, Portfolio } from '@somostera/tera-database'
import { getBlockStructureByAcronymCourseAndBlockType } from '../services/course'
import { PortfolioClassFormValues, PortfolioData } from '../@types/portfolio'

import {
  addItemPortfolio,
  deleteItemPortfolio,
  getPortfolioByIdAndPeopleId,
  getPortfolioByPeopleId,
  updateItemPortfolio
} from '../services/portfolio'

interface PortfolioContextData {
  isLoading: boolean
  currentPortfolioClass: PortfolioData
  personPortfolioTableData: PortfolioData[]
  updateItemToPortfolio: (item: PortfolioData) => void
  addItemToPortfolio: (itemToPortfolio: Portfolio, personId: string) => Promise<void>
  deleteItemToPortfolio: (personId: string, itemId: string) => Promise<void>
  portfolioByPeopleId: (peopleId: string) => Promise<void>
  setCurrentPortfolioClass: (personId: string, classId: string) => void
  getBuildingBlockByAcronymCourseAndBlockType: (item: PortfolioClassFormValues) => Promise<void>
  arrayBuildingBlock: Course[]
}

interface PortfolioProviderProps {
  children: ReactNode
}

const PortfolioContext = createContext<PortfolioContextData>({} as PortfolioContextData)

export const PortfolioProvider = ({ children }: PortfolioProviderProps) => {
  const [{ isLoading, personPortfolioTableData, currentPortfolioClass }, setState] = useImmerReducer(portfolioReducer, {
    isLoading: true,
    currentPortfolioClass: {} as PortfolioData
  } as State)
  const [addCourseData, setAddCourseData] = useState<Portfolio>()
  const [arrayBuildingBlock, setArrayBuildingBlock] = useState<Course[]>([])

  useEffect(() => {
    setState({ type: 'ASYNC_CALL' })
    // TODO: chamada da api -> recebe as aulas de portfolio do back e passa no payload no lugar de mockedPortfolioData

    setState({ type: 'ASYNC_CALL_SUCCEED' })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const portfolioByPeopleId = useCallback(
    async (personId: string) => {
      setState({ type: 'ASYNC_CALL' })

      const portfolioData = await getPortfolioByPeopleId(personId)
      setState({ type: 'SET_PERSON_PORTFOLIO_TABLE_DATA', payload: { portfolioTableData: portfolioData } })
      setState({ type: 'ASYNC_CALL_SUCCEED' })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [personPortfolioTableData]
  )

  const setCurrentPortfolioClass = async (personId: string, classId: string) => {
    if (personId && classId) {
      const foundPortfolioClass = await getPortfolioByIdAndPeopleId(personId, classId)
      setState({ type: 'SET_CURRENT_PORTFOLIO_DATA', payload: { portfolioClass: foundPortfolioClass } })
    }
  }

  const updateItemToPortfolio = async (data: PortfolioData) => {
    await updateItemPortfolio(data as Portfolio)
  }

  const deleteItemToPortfolio = async (personId: string, itemId: string) => {
    await deleteItemPortfolio(personId, itemId)
  }

  const addItemToPortfolio = async (data: Portfolio, personId: string) => {
    setState({ type: 'ASYNC_CALL' })
    const portfolioCourseAttributes = { ...addCourseData } as Portfolio

    portfolioCourseAttributes.peopleId = personId
    portfolioCourseAttributes.blockId = data.blockId
    portfolioCourseAttributes.courseAcronym = data.courseAcronym
    portfolioCourseAttributes.format = data.format
    portfolioCourseAttributes.type = data.type

    await addItemPortfolio(portfolioCourseAttributes)
    setAddCourseData(portfolioCourseAttributes)
    setState({ type: 'ASYNC_CALL_SUCCEED' })
  }

  const getBuildingBlockByAcronymCourseAndBlockType = async (data: PortfolioClassFormValues) => {
    const arrayBlockTypes = [] as any[]

    if (data.type === 'mentor') {
      arrayBlockTypes.push(BlockType.MENTORSHIP, BlockType.COLLECTIVE_MENTORING)
    } else {
      arrayBlockTypes.push(
        BlockType.CLASS,
        BlockType.LIVE,
        BlockType.MASTERCLASS,
        BlockType.MASTERCLASS_CASE_STUDY,
        BlockType.TERA_DEBATE,
        BlockType.WORKSHOP_FRAMEWORK,
        BlockType.WORKSHOP_ROLEPLAY
      )
    }
    let getBuildindBlock = await getBlockStructureByAcronymCourseAndBlockType(data.courseAcronym, arrayBlockTypes)

    getBuildindBlock.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))

    getBuildindBlock = getBuildindBlock.filter(
      (value, index, self) => index === self.findIndex((t) => t.blockId === value.blockId)
    )

    setArrayBuildingBlock(getBuildindBlock)
  }

  return (
    <PortfolioContext.Provider
      value={{
        isLoading,
        currentPortfolioClass,
        setCurrentPortfolioClass,
        personPortfolioTableData,
        portfolioByPeopleId,
        updateItemToPortfolio,
        deleteItemToPortfolio,
        addItemToPortfolio,
        getBuildingBlockByAcronymCourseAndBlockType,
        arrayBuildingBlock
      }}
    >
      {children}
    </PortfolioContext.Provider>
  )
}

export const usePortfolio = (): PortfolioContextData => {
  return useContext(PortfolioContext)
}
