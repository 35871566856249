import React, { useEffect, useMemo, useState } from 'react'
import { TableColumn } from 'react-data-table-component'
import { Media } from '@somostera/tera-database'

import { DataTableBase } from 'core/components/DataTableBase'
import { FilterTable } from 'core/components/DataTableBase/FilterTable'
import { Loading } from 'core/components/Animation/Loading'
import { getFormatedDate } from 'core/utils/date'

import { useMedia } from 'modules/media/hooks/useMedia'

import { MediaCell } from '../../components/MediaCell'

import { Container, Header, SubHeaderContainer, LoadingContainer } from './styles'

export function MediaPage() {
  const [filterText, setFilterText] = useState('')
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false)

  const { medias, isLoading, getAllMedias } = useMedia()

  useEffect(() => {
    getAllMedias()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const columns: TableColumn<Media>[] = [
    {
      name: 'Mídia',
      selector: ({ name }) => JSON.stringify(name),
      sortable: true,
      cell: (row: Media) => <MediaCell media={row} />,
      width: '28rem'
    },
    {
      name: 'Tipo',
      selector: ({ type }) => JSON.stringify(type),
      sortable: true,
      cell: (row: Media) => <span style={{ cursor: 'default' }}>{row.type}</span>,
      width: '8rem'
    },
    {
      name: 'Tamanho',
      selector: ({ size }) => JSON.stringify(size),
      sortable: true,
      cell: (row: Media) => <span style={{ cursor: 'default' }}>{`${row.size}kb` || '-'}</span>,
      width: '10rem'
    },
    {
      name: 'Data do Upload',
      selector: ({ createdAt }) => JSON.stringify(createdAt),
      sortable: true,
      cell: (row: Media) => <span style={{ cursor: 'default' }}>{getFormatedDate(row.createdAt) || '-'}</span>,
      width: '12rem'
    }
  ]

  const filteredItems = useMemo(() => {
    if (medias) {
      const newMedias = medias.filter((media) => media.createdAt)

      return newMedias.filter(
        (media) =>
          JSON.stringify({
            name: media.name
          })
            .toLowerCase()
            .indexOf(filterText.toLowerCase()) !== -1
      )
    } else {
      return []
    }
  }, [filterText, medias])

  const SubHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle)
        setFilterText('')
      }
    }

    return (
      <SubHeaderContainer>
        <FilterTable
          placeholder="Busque por nome"
          onFilter={(event) => setFilterText(event.target.value)}
          onClear={handleClear}
          filterText={filterText}
        />
      </SubHeaderContainer>
    )
  }, [filterText, resetPaginationToggle])

  return (
    <Container>
      <Header>
        <div>
          <span>Editor</span>
          <h1>Mídias</h1>
        </div>
      </Header>

      {isLoading && (
        <LoadingContainer>
          <Loading />
        </LoadingContainer>
      )}

      <DataTableBase
        columns={columns}
        data={filteredItems}
        noDataComponent={`${isLoading ? 'Carregando...' : 'Nenhum dado encontrado'}`}
        subHeader
        subHeaderComponent={SubHeaderComponent}
      />
    </Container>
  )
}
