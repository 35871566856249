export const enumKeys = <O extends object, K extends keyof O = keyof O>(obj: O): K[] =>
  Object.keys(obj).filter((k) => Number.isNaN(+k)) as K[]

export const enumEntries = <O extends object>(obj: O): { value: string; name: string }[] =>
  Object.entries(obj).map((entry) => ({
    value: entry[0],
    name: entry[1]
  }))

export const OrderByName = (Obj: Array<any>) => {
  Obj.sort((a: any, b: any) => {
    if (a.name === '') {
      return 1
    }
    if (b.name === '') {
      return -1
    }
    if (a.name > b.name) {
      return 1
    }
    if (a.name < b.name) {
      return -1
    }
    return 1
  })
  return Obj
}
