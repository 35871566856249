import React, { ReactNode } from 'react'
import { ArrowRefreshCircleFilled, CheckCircleFilled, MinusCircleFilled, XCircleFilled } from '@somostera/tera-icons'

import { StatusContainer } from './styles'
import { PeopleRoleStatus } from '@somostera/tera-database'

interface StatusPersonProps {
  status: string
}

type StatusComponents = {
  [key in string]: ReactNode
}

export const StatusPerson = ({ status }: StatusPersonProps) => {
  const statusComponents: StatusComponents = {
    [PeopleRoleStatus.AVAILABLE]: (
      <StatusContainer color="var(--green-80)">
        <CheckCircleFilled size={15} color="#ffffff " />
        <p>Disponível</p>
      </StatusContainer>
    ),
    [PeopleRoleStatus.UNAVAILABLE]: (
      <StatusContainer color="var(--gray-60)">
        <MinusCircleFilled size={15} color="#ffffff" />
        <p>Indisponível</p>
      </StatusContainer>
    ),
    [PeopleRoleStatus.IN_VALIDATION]: (
      <StatusContainer color="var(--yellow)">
        <ArrowRefreshCircleFilled size={15} color="#ffffff" />
        <p>Em validação</p>
      </StatusContainer>
    ),
    [PeopleRoleStatus.INVALIDATED]: (
      <StatusContainer color="var(--orange)">
        <XCircleFilled size={15} color="#ffffff " />
        <p>Invalidada</p>
      </StatusContainer>
    ),
    Lead: undefined
  }

  return <>{statusComponents[status]}</>
}
