import React, { Dispatch, SetStateAction } from 'react'

import { Container, TemplateContainer, Template } from './styles'
import { ContentType } from '@somostera/tera-database'
import { ArticleType } from '@somostera/tera-database/dist/Articles/Domain/enum/ArticleType'
interface TemplateProps {
  id: number
  title: string
  description: string
  template: ContentType
  restrictByArticleType?: ArticleType
}

interface AddChapterSubMenuProps {
  onAddChapter: (templateName: ContentType, chapterName?: string) => void
  setShowAddChapterSubMenu: Dispatch<SetStateAction<boolean>>
  articleType: ArticleType
}

export function AddChapterSubMenu({
  onAddChapter,
  setShowAddChapterSubMenu,
  articleType = ArticleType.CLASS
}: AddChapterSubMenuProps) {
  const templates: TemplateProps[] = [
    {
      id: 1,
      title: 'Em branco',
      description: 'Escreva conteúdos',
      template: ContentType.CHAPTER
    },
    {
      id: 2,
      title: 'Quiz',
      description: 'Crie um quiz múltipla escolha',
      template: ContentType.QUIZ
      // restrictByArticleType: ArticleType.CLASS
    }
  ]

  const handleAddChapter = (templateName: ContentType) => {
    onAddChapter(templateName)
    setShowAddChapterSubMenu(false)
  }

  const canAddChapterTemplateForThisArticle = (template: TemplateProps) => {
    if (!template.restrictByArticleType) return true

    return template.restrictByArticleType && template.restrictByArticleType === articleType
  }

  return (
    <Container>
      <h2>Adicionar Capítulo</h2>

      <TemplateContainer>
        {templates.map(
          (template) =>
            canAddChapterTemplateForThisArticle(template) && (
              <Template onClick={() => handleAddChapter(template.template)} key={template.id}>
                <h3>{template.title}</h3>
                <p>{template.description}</p>
              </Template>
            )
        )}
      </TemplateContainer>
    </Container>
  )
}
