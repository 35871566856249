import React from 'react'

import { Chip, Container } from './styles'

interface TypeProps {
  types: Type[]
  currentType: string
}

interface Type {
  type: string
  name: string
  color: string
}

export const TypeCell = ({ types, currentType }: TypeProps) => {
  const getCurrentType = types.find((t) => t.type === currentType)

  return (
    <Container>
      <Chip color={getCurrentType?.color || ''}>{getCurrentType?.name}</Chip>
    </Container>
  )
}
