import React, { useEffect, useState } from 'react'
import { Media } from '@somostera/tera-database'

import { Container, MediaContainer, DataContainer, InfoContainer, Tag } from './styles'

import { getDateInformation } from 'core/utils/date'

import playIcon from 'modules/media/components/modal/icons/playIcon.svg'

interface MediaCellProps {
  media: Media
}

export function MediaCell({ media }: MediaCellProps) {
  const [mediaDate, setMediaDate] = useState('')
  const [imageHasError, setImageHasError] = useState(false)

  useEffect(() => {
    if (media?.createdAt) {
      const { day, month, year, hours, minutes } = getDateInformation(media.createdAt)
      setMediaDate(`${day}.${month}.${year} - ${hours}h${minutes}`)
    }
  }, [media?.createdAt])

  return (
    <Container>
      <MediaContainer>
        {imageHasError ? (
          <div />
        ) : media.type === 'video' ? (
          <>
            {media?.thumbnail ? (
              <img src={media.thumbnail} alt={media.name} onError={() => setImageHasError(true)} />
            ) : null}
            <span>
              <img src={playIcon} alt="play" />
            </span>
          </>
        ) : (
          <img src={media.url} alt={media.name} onError={() => setImageHasError(true)} />
        )}
      </MediaContainer>

      <DataContainer>
        <div>
          <InfoContainer>
            <p>Título:</p>
            <span>{media.name || '-'}</span>
          </InfoContainer>
          <InfoContainer>
            <p>Arquivo:</p>
            <span>{media.fileName || '-'}</span>
          </InfoContainer>
          <InfoContainer>
            <p>Adicionado em:</p>
            <span>{mediaDate || '-'}</span>
          </InfoContainer>
          <InfoContainer>
            <p>Descrição:</p>
            <span>{media.description || '-'}</span>
          </InfoContainer>
        </div>

        <section>
          {media.tags &&
            media.tags.map((tag: any, index: number) => (
              <Tag key={tag} tagKey={index + 1}>
                {tag}
              </Tag>
            ))}
        </section>
      </DataContainer>
    </Container>
  )
}
