/* eslint-disable prettier/prettier */
import React, { ReactNode } from 'react'
import { Navigate } from 'react-router-dom'

import { useAuth } from '../hooks/useAuth'

import { PrivateRouteComponent } from 'core/routes/PrivateRouteComponent'

interface RouteProps {
  isPrivate?: boolean
  children: ReactNode
}

export function RouteElement({ isPrivate = false, children }: RouteProps) {
  const { userFirebase } = useAuth()
  const isSigned = !!userFirebase.uid

  return isSigned && isPrivate ? (
    <PrivateRouteComponent>{children}</PrivateRouteComponent>
  ) : !isSigned && !isPrivate ? (
    <>{children}</>
  ) : (
    <Navigate to={isSigned ? '/home' : '/'} />
  )
}
