import React, { useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'

import { MediaType } from '@somostera/tera-models-ts'
import { CloudUpload } from '@somostera/tera-icons'

import { useToast } from 'core/hooks/useToast'
import { getVideoDuration } from 'core/utils/file'

import CloudUploadBluePurple from 'core/components/Icon/CloudUploadBluePurple'

import { Container, DropzoneContainer, LoadingIcon } from './styles'
import { v4 } from 'uuid'

import * as Sentry from '@sentry/browser'
import {
  FirebaseStorage,
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
  UploadMetadata
} from 'firebase/storage'

import { firebaseApp } from '../../config/firebase'

export interface UploadResult {
  fileDownloadUrl: string
  pathToFile: string
}

enum BucketFolders {
  medias = 'medias',
  publicMedias = 'public/media',
  publicVideo = 'public/videos',
  publicThumbnailVideo = 'public/thumbVideos'
}

const videosStorage = getStorage(firebaseApp, process.env.REACT_APP_FIREBASE_VIDEO_STORAGE)
const imagesStorage = getStorage(firebaseApp, process.env.REACT_APP_FIREBASE_IMAGE_STORAGE)
const downloadsStorage = getStorage(firebaseApp, process.env.REACT_APP_FIREBASE_DOWNLOAD_STORAGE)

interface FileInputProps {
  name: string
  fileType: MediaType
  acceptedFilesRegex: string
  onFileUrlChange: (fileUrl: string) => void
  onFileSizeChange: (fileSize: number) => void
  onMimeTypeChange?: (mimeType: string) => void
  onVideoDurationChange?: (duration: number) => void
  onFileChange?: (file: File) => void
  onFileNameChange?: (fileName: string) => void
}

export const FileInput = React.forwardRef<HTMLInputElement, FileInputProps>(
  (
    {
      fileType,
      name,
      acceptedFilesRegex,
      onFileUrlChange,
      onFileSizeChange,
      onMimeTypeChange,
      onVideoDurationChange,
      onFileChange,
      onFileNameChange
    },
    reference
  ) => {
    const { addToast } = useToast()

    const [storage, setStorage] = useState<FirebaseStorage>(() => {
      switch (fileType) {
        case MediaType.IMAGE || MediaType.THUMBNAIL_VIDEO:
          return imagesStorage
        case MediaType.VIDEO:
          return videosStorage
        default:
          return downloadsStorage
      }
    })

    const [preview, setPreview] = useState('')
    const [file, setFile] = useState<File>()
    const [loadingFile, setLoadingFile] = useState(false)
    const [progress, setProgress] = useState(0)
    const [progressMsg, setProgressMsg] = useState('carregando arquivo...')

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      accept: acceptedFilesRegex,
      onDrop: async (acceptedFiles) => {
        setLoadingFile(true)

        try {
          const extension = acceptedFiles[0].name.substring(acceptedFiles[0].name.lastIndexOf('.') + 1)

          const fileName = v4() + '.' + extension

          await uploadMedia(acceptedFiles[0], fileType, fileName)
        } catch (error) {
          console.error(error)
          Sentry.captureException(error)
          alert('Algo deu errado ao subir a mídia. Por favor, tente novamente.')
          addToast({
            type: 'error',
            title: 'Erro no upload',
            description: 'Algo deu errado ao subir a mídia. Por favor, tente novamente.'
          })
        }
      }
    })

    async function finishUpload(fileDownloadUrl: string, fileName: string, acceptedFiles: File) {
      try {
        // remove token from the url
        onFileUrlChange(fileDownloadUrl.split('&')[0])
        // byte to megabyte
        const fileSize = acceptedFiles.size / 1000000
        onFileSizeChange(Number(fileSize.toFixed(1)))

        if (onFileNameChange) {
          onFileNameChange(fileName)
        }

        if (onMimeTypeChange) {
          onMimeTypeChange(acceptedFiles.type)
        }

        setFile(acceptedFiles)
        if (onFileChange) {
          onFileChange(acceptedFiles)
        }
        setPreview(URL.createObjectURL(acceptedFiles))

        if (fileType === MediaType.VIDEO && onVideoDurationChange) {
          const duration = await getVideoDuration(acceptedFiles)
          onVideoDurationChange(duration)
        }
        setProgressMsg('carregando arquivo...')
      } catch (error) {
        console.error(error)
        Sentry.captureException(error)
        alert('Algo deu errado ao subir a mídia. Por favor, tente novamente.')
        addToast({
          type: 'error',
          title: 'Erro no upload',
          description: 'Algo deu errado ao subir a mídia. Por favor, tente novamente.'
        })
      }

      setLoadingFile(false)
    }

    useEffect(() => {
      switch (fileType) {
        case MediaType.IMAGE:
          setStorage(imagesStorage)
          break
        case MediaType.VIDEO:
          setStorage(videosStorage)
          break
        case MediaType.THUMBNAIL_VIDEO:
          setStorage(imagesStorage)
          break
        default:
          setStorage(downloadsStorage)
      }
    }, [fileType])

    async function getFileDownloadUrl(path: string): Promise<string | undefined> {
      const fileRef = ref(storage, path)

      try {
        return await getDownloadURL(fileRef)

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        switch (error.code) {
          case 'storage/object-not-found': {
            return undefined
          }
          default: {
            throw error
          }
        }
      }
    }

    async function uploadFile(path: string, file: File, fileName: string): Promise<void> {
      const contentType = file.type

      const pathToFile = path + '/' + fileName

      const fileDownloadUrl = await getFileDownloadUrl(pathToFile)

      const fileRef = ref(storage, pathToFile)

      if (fileDownloadUrl) {
        // eslint-disable-next-line no-throw-literal
        throw {
          code: '@somostera/firebase-storage/file-already-exists',
          message: `O arquivo ${pathToFile} já existe`,
          customData: { fileDownloadUrl, pathToFile }
        }
      }

      const metadata: UploadMetadata = {
        contentType
      }

      const uploadTask = uploadBytesResumable(fileRef, file, metadata)

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          setProgress(progress)
        },
        (error) => {
          setProgress(0)
          alert(error.message)
          throw error
          Sentry.captureException(error)
        },
        () => {
          setProgress(0)
          setProgressMsg('preparando arquivo...')
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            finishUpload(downloadURL, fileName, file)
          })
        }
      )
    }

    async function uploadMedia(file: File, fileType: MediaType, fileName: string): Promise<void> {
      return uploadFile(await getBucketFolder(fileType), file, fileName)
    }

    async function getBucketFolder(fileType: MediaType): Promise<string> {
      switch (fileType) {
        case MediaType.VIDEO:
          return BucketFolders.publicVideo
        case MediaType.THUMBNAIL_VIDEO:
          return BucketFolders.publicThumbnailVideo
        default:
          return BucketFolders.publicMedias
      }
    }

    return (
      <Container>
        <DropzoneContainer hasFile={!!file?.type} {...getRootProps({isDragActive})}>
          <input ref={reference} name={name} multiple={false} {...getInputProps()} />

          {loadingFile ? (
            <>
              <LoadingIcon size={72} color="var(--gray-60)"/>
              <p>
                {progressMsg} {progress !== 0 ? progress.toFixed(2) + '%' : ''}
              </p>
            </>
          ) : file && (fileType === MediaType.IMAGE || fileType === MediaType.THUMBNAIL_VIDEO) && preview ? (
            <img src={preview} alt={file.name}/>
          ) : file && fileType === MediaType.VIDEO && preview ? (
            <video src={preview} width="100%" height="100%" controls/>
          ) : file && fileType === MediaType.DOWNLOAD ? (
            <p>{file.name}</p>
          ) : (
            <>
              {isDragActive ? (
                <CloudUpload size={72} color={'var(--blue-primary)'}/>
              ) : (
                <CloudUploadBluePurple height={46}/>
              )}
              {isDragActive ? (
                <p>soltar arquivo</p>
              ) : (
                <>
                  <p>
                    arraste o arquivo aqui para upload ou <span>Escolha um arquivo</span>
                  </p>
                  <p>( limite de 1 arquivo por vez )*</p>
                </>
              )}
            </>
          )}
        </DropzoneContainer>
      </Container>
    )
  }
)
