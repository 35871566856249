/* eslint-disable prettier/prettier */
import styled from 'styled-components'

export const TypeTooltip = styled.span`
  background: #8ffe81;
  padding: 4px 16px;
  border-radius: 16px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;

  color: #000000;
`

export const OptionalTooltip = styled.span`
  background: #FFB224;
  padding: 4px 16px;
  border-radius: 16px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;

  color: #000000;
`

export const CardContainer = styled.div`
  background: var(--neutral1);
  width: 640px;
  padding: 12px 20px 12px 12px;
  margin-top: 24px;

  &:first-of-type {
    margin-top: 17px;
  }

  &:last-of-type {
    margin-bottom: 103px;
  }

  header {
    display: flex;
    align-items: center;
    gap: 14px;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  section {
    width: 572px;
    margin-left: 36px;
    margin-top: 12px;
    h3 {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 900;
      font-size: 20px;
      line-height: 100%;

      letter-spacing: -0.01em;
      text-transform: uppercase;

      color: #171717;
    }
    p {
      margin-top: 8px;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;

      color: #171717;
    }
  }
`
