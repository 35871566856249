import styled from 'styled-components'

export const Container = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`

const colors = {
  'tera-debate': 'var(--green-pastel)',
  'masterclass-case-study': 'var(--blue-pastel)',
  masterclass: 'var(--yellow-pastel)',
  mentorship: 'var(--purple-pastel)',
  'workshop-roleplay': 'var(--blue-pastel)',
  article: 'var(--green-pastel)',
  'workshop-framework': 'var(--gray-20)',
  class: 'var(--yellow-pastel)',
  live: 'var(--blue-pastel)',
  'tera-lecture': 'var(--gray-20)',
  originals: 'var(--gray-20)',
  visit: 'var(--gray-20)',
  'collective-mentoring': 'var(--gray-20)'
} as { [key: string]: string }

interface ChipProps {
  type: string
}
export const Chip = styled.span<ChipProps>`
  background: ${({ type }) => colors[type]};
  border-radius: 3rem;
  border: 2px solid white;
  font-size: 0.875rem;
  line-height: 120%;
  padding: 0.25rem 1rem;

  & + span {
    margin-left: 0.25rem;
  }
`
