import styled from 'styled-components'

import { Link } from 'react-router-dom'

export const EditContainer = styled.div`
  background: #fcfcfc;
  padding-top: 24px;

  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  margin-top: 4.375rem;

  > main {
    background: #fff;
    max-width: 640px;
    width: 100%;
    padding: 20px;
    border: 1px solid #f2f2f2;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.04);

    > datalist {
      background: red;

      option {
        background-color: red !important;
      }
    }

    > h3 {
      color: #000;
      font-size: 1rem;
      line-height: 24px;
      font-weight: 600;
      letter-spacing: 0.01;
      text-transform: uppercase;
    }

    > section {
      display: flex;
      align-items: flex-end;

      > div {
        width: 100%;
      }

      > button {
        background: transparent;
        border: 0;
        margin: 0 0 0.6rem 0.6rem;
      }
    }
  }

  > section {
    background: #fff;
    max-width: 640px;
    width: 100%;
    padding: 20px;
    border: 1px solid #f2f2f2;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.04);

    > h3 {
      font-size: 16px;
      line-height: 24px;
      font-weight: 600;
      letter-spacing: 0.01;
      text-transform: uppercase;
    }
  }
`

export const HeaderContainer = styled.header`
  padding: 0 2.5rem;
  padding-top: 2.625rem;
  padding-bottom: 4.438rem;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;

  background: var(--white);
  position: sticky;
  top: 3.625rem;
  z-index: 9;

  border-bottom: 1px solid #e3e3e3;

  > div {
    > h2 {
      font-size: 2rem;
      font-weight: 800;
      line-height: 2rem;
      font-family: 'Rubik';
      letter-spacing: 0.02;
      text-transform: uppercase;
      margin-bottom: 0.5rem;
    }

    > h3 {
      font-size: 0.75rem;
      font-weight: 400;
      line-height: 1.125rem;
      font-family: 'Rubik';
      text-transform: uppercase;
      color: #707070;
    }
  }
`

export const Button = styled.button`
  text-decoration: none;
  text-transform: uppercase;
  font-size: 0.875rem;
  line-height: 1.5rem;
  font-weight: 700;
  color: white;
  background: #000;
  padding: 0.5rem 1.5rem 0.5rem 1.75rem;

  display: flex;
  align-items: center;
  gap: 12px;

  &:disabled {
    background-color: gray;
    cursor: not-allowed;
  }
`

export const Content = styled.div`
  background-color: #fcfcfc;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 1.5rem;
  padding-bottom: 3.375rem;
  gap: 16px;
`

export const Footer = styled.div`
  position: sticky;
  bottom: 0;
  z-index: 9;
  background-color: #000;
  width: 100%;
  height: 56px;

  display: flex;
  justify-content: flex-end;

  > button {
    height: 100%;
    padding: 12px 64px 12px 64px;
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 700;
    background-color: #000;
    color: #fff;

    &:last-child {
      background-color: #8ffe81;
      color: #000;
    }
  }
`
export const ToggleSwitchContainer = styled.div`
  display: flex;
  flex-direction: column;
  > h3 {
    margin-top: 16px;
    font-size: 12px;
    font-weight: 600;
    line-height: 12px;
    letter-spacing: 0.01;
    text-transform: uppercase;
    color: #000;
  }

  > div {
    margin-top: 16px;
    display: flex;
    gap: 8px;
    align-items: center;

    > label {
      margin-top: 0;
    }

    > span {
      font-size: 14px;
      line-height: 16px;
      font-weight: 400;
    }
  }
`

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
`

export const Datalist = styled.datalist`
  position: absolute;
  background-color: white;
  border: 1px solid blue;
  border-radius: 0 0 5px 5px;
  border-top: none;
  font-family: sans-serif;
  width: 350px;
  padding: 5px;
  max-height: 10rem;
  overflow-y: auto;
`

export const ExpertsContainer = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  align-items: flex-end;

  > div {
    width: 100%;
  }

  > button {
    background: transparent;
    border: 0;
    margin: 0 0 0.6rem 0.6rem;
  }
`
