import styled from 'styled-components'

export const Container = styled.div`
  .quote-component {
    blockquote {
      font-family: Georgia, serif;
      font-style: italic;
      font-weight: normal;
      font-size: 2rem;
      line-height: 2.5rem;
      margin: 0 0 1rem;
    }

    span {
      font-weight: bold;
      line-height: 1.5rem;
      text-transform: uppercase;
    }
  }
`
