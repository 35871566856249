import React from 'react'
import { NodeViewContent, NodeViewProps } from '@tiptap/react'

import { Container } from './styles'

export const CodeBlockSyntaxHighlightComponent = ({
  node: {
    attrs: { language: defaultLanguage }
  },
  updateAttributes
}: NodeViewProps) => {
  const availableLanguages = [
    { value: 'python', label: 'Python' },
    { value: 'javascript', label: 'JavaScript' },
    { value: 'typescript', label: 'Typescript' },
    { value: 'c', label: 'C' },
    { value: 'r', label: 'R' },
    { value: 'sql', label: 'SQL' }
  ].sort((a, b) => {
    if (a.value > b.value) {
      return 1
    }
    if (a.value < b.value) {
      return -1
    }
    return 0
  })

  return (
    <Container className="code-block-component">
      <select
        contentEditable={false}
        defaultValue={defaultLanguage}
        onChange={(event) => {
          updateAttributes({ language: event.target.value })
        }}
      >
        <option value="null">auto</option>
        <option disabled>—</option>
        {availableLanguages.map((lang: { value: string; label: string }, index: number) => (
          <option key={index} value={lang.value}>
            {lang.label}
          </option>
        ))}
      </select>
      <pre>
        <NodeViewContent as="code" />
      </pre>
    </Container>
  )
}
