import TextareaAutosize from 'react-textarea-autosize'
import styled from 'styled-components'

export const Container = styled.div``

export const HeaderAndMenuBarContainer = styled.header`
  background: var(--white);
  position: sticky;
  top: 0;
  z-index: 9;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
`

export const Header = styled.div`
  margin: 0.5rem 0;
  width: 50%;

  p {
    color: var(--gray-80);
    font-size: 0.75rem;
    line-height: 1.25rem;
    margin-bottom: 0.25rem;

    &:last-child {
      text-transform: uppercase;
    }

    strong {
      text-transform: uppercase;
    }
  }
`

export const Title = styled(TextareaAutosize)`
  border: 0;
  font-size: 3rem;
  font-weight: 700;
  height: 3.8rem;
  letter-spacing: -0.2rem;
  line-height: 3.8rem;
  margin: 0;
  outline: 0;
  padding-left: 0;
  resize: none;
  text-transform: uppercase;
  width: 100%;

  &::placeholder {
    color: var(--gray-40);
  }
`

export const EditorContainer = styled.main`
  min-height: 50rem;
  margin: 1rem 0;
  width: 50%;
  max-width: 560px;
  background: var(--white);

  > div {
    height: 100%;
  }

  .ProseMirror {
    padding: 2rem 3rem 0;
    min-height: 50rem;
    color: var(--black);

    > * {
      padding: 0;
      margin: 0;
    }

    iframe {
      max-width: 100%;
      max-height: 15rem;
    }

    h1 {
      border-left: 0.5rem solid var(--green-primary);
      font-size: 3rem;
      font-weight: bold;
      letter-spacing: -2%;
      line-height: 3rem;
      margin-left: -1.5rem;
      padding-left: 1rem;
      text-transform: uppercase;
      margin-bottom: 2.5rem;
    }

    h2 {
      font-size: 1.5rem;
      font-weight: bold;
      line-height: 1.5rem;
      margin-bottom: 1rem;
    }

    h3 {
      font-size: 1rem;
      font-weight: bold;
      line-height: 1rem;
      text-transform: uppercase;
      margin-bottom: 1rem;
    }

    > p {
      font-size: 1rem;
      font-weight: normal;
      line-height: 1.5rem;
      margin-bottom: 1.5rem;
    }

    a {
      color: var(--blue-primary);
      font-weight: bold;
      text-decoration: underline;
    }

    img,
    video {
      width: 100%;
      margin-bottom: 1.5rem;
    }

    ul {
      margin-bottom: 1.5rem;

      p {
        width: 100%;
        font-size: 1rem;
        line-height: 1.5rem;
      }

      li + li {
        margin-top: 1rem;
      }
    }

    .has-focus {
      border: 1px dashed var(--blue-primary);
    }

    .code-block-component {
      margin-bottom: 1.5rem;
    }

    .coloured-card-list-component {
      margin-bottom: 2.5rem;
    }

    .file-download-component {
      margin-bottom: 1.5rem;
    }

    .page-break-component {
      margin-bottom: 1.5rem;
    }

    .quote-component {
      margin-bottom: 2.5rem;

      blockquote {
        font-family: Georgia, serif;
        font-style: italic;
        font-weight: normal;
        font-size: 2rem;
        line-height: 2.5rem;
        margin: 0 0 1rem;
      }

      span {
        font-weight: bold;
        line-height: 1.5rem;
        text-transform: uppercase;
      }
    }
  }

  .ProseMirror-focused {
    outline: none;
  }
`

export const SavingStatusText = styled.div`
  align-items: center;
  display: flex;
  height: 1.25rem;
  padding: 0.25rem 0;

  span {
    color: var(--gray-60);
    font-size: 0.75rem;
    margin-left: 0.25rem;
    text-transform: uppercase;
  }
`

export const MenuBarContainer = styled.nav`
  border: 1px solid #e0e0e0;
  border-radius: 0;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.16);
  display: flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  width: 100%;
  z-index: 10;
  align-items: center;
  justify-content: center;

  > div {
    align-items: center;
    display: flex;
    height: 2rem;
    margin: 0;
    width: 50%;
  }
`
export const HeaderButtonsContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin: 1rem 0;

  button,
  a {
    border-radius: 0.25rem;
    font-size: 1rem;
    font-weight: normal;
    height: 1.75rem;
    line-height: 1.25rem;
    padding: 0.25rem 1rem;
    text-align: center;
    text-decoration: none;
    text-transform: none;

    &:hover {
      transform: translate(1px, 1px);
    }

    &:first-child {
      background: var(--white);
      border: 1.5px solid var(--gray-80);
      color: var(--gray-80);
      margin-right: 1rem;
      text-transform: uppercase;
      font-size: 0.875rem;
      font-weight: 700;
    }

    &:last-child {
      background: var(--blue-primary);
      color: var(--white);
    }
  }
`
export const ChaptersMenuAndEditorContainer = styled.div`
  display: flex;
  background: var(--gray-20);
`
export const ChaptersMenuContainer = styled.div`
  display: flex;
  width: 25%;
  height: 100%;
`

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
`
