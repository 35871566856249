import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const HeaderContainer = styled.header`
  padding: 0 2.5rem;
  padding-top: 2.625rem;
  padding-bottom: 4.438rem;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;

  background: var(--white);
  position: sticky;
  top: 3.625rem;
  z-index: 9;

  border-bottom: 1px solid #e3e3e3;

  > div {
    > h2 {
      font-size: 2rem;
      font-weight: 800;
      line-height: 2rem;
      font-family: 'Rubik';
      letter-spacing: 0.02;
      text-transform: uppercase;
      margin-bottom: 0.5rem;
    }

    > h3 {
      font-size: 0.75rem;
      font-weight: 400;
      line-height: 1.125rem;
      font-family: 'Rubik';
      text-transform: uppercase;
      color: #707070;
    }
  }
`

export const Button = styled(Link)`
  text-decoration: none;
  text-transform: uppercase;
  font-size: 0.875rem;
  line-height: 1.5rem;
  font-weight: 700;
  color: white;
  background: #000;
  padding: 0.5rem 1.5rem 0.5rem 1.75rem;

  display: flex;
  align-items: center;
  gap: 12px;
`

export const Content = styled.div`
  background-color: #fcfcfc;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 1.5rem;
  padding-bottom: 3.375rem;
  gap: 16px;
  margin-top: 4.375rem;
`

export const Box = styled.div`
  padding: 1.25rem;
  background: #fcfcfc;
  border: 1px solid #f2f2f2;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.04);
  width: 640px;

  > h3 {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.01;
    color: #000;
    text-transform: uppercase;
  }
`
export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
`
