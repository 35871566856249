import styled from 'styled-components'

export const Container = styled.div`
  background: var(--gray-20);
  height: 100vh;
  margin: 0 auto;
  overflow-x: auto;

  > div {
    margin: 5rem auto;
    width: 41rem;

    > button {
      background: none;
      color: var(--gray-100);
      display: block;
      font-size: 1rem;
      font-weight: bold;
      margin-bottom: 0.5rem;
      text-decoration: underline;

      svg {
        margin-right: 0.25rem;
      }

      &:hover {
        opacity: 0.7;
      }
    }

    .preview-container {
      background: var(--white);
      padding: 3rem;

      .ProseMirror {
        video {
          width: 100%;
          height: 100%;
        }

        a {
          color: var(--blue-primary);
          font-weight: bold;
          text-decoration: underline;
        }

        > img {
          height: auto;
          width: 100%;
        }

        ul {
          margin-left: 1.5rem;
        }

        .quote-component {
          blockquote {
            font-family: Georgia, serif;
            font-style: italic;
            font-weight: normal;
            font-size: 2rem;
            line-height: 2.5rem;
            margin: 0 0 1rem;
          }

          span {
            font-weight: bold;
            line-height: 1.5rem;
            text-transform: uppercase;
          }
        }
      }
    }
  }
`
