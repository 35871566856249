import React, { useEffect } from 'react'

import { useNetwork } from '../../../hooks/useNetwork'
import { useForm } from 'core/hooks/useForm'

import { AutosizeInput } from 'core/components/AutosizeInput'
import { InputNumber } from 'core/components/InputNumber'
import { RadioButton } from 'core/components/RadioButton'
import { Checkbox } from 'core/components/Checkbox'
import { AvailabilityPeriod, AvailableDaysOfWeek, PeopleRoleStatus } from '@somostera/tera-database'
import { CheckboxContainer, Form, HorizontalPanel, Label, SaveButton, Error } from './styles'
import { StatusAndAvailabilityFormValues } from 'modules/network/@types/network'

interface Dictionary {
  [key: string]: string
}

export const StatusAndAvailabilityComponent = () => {
  const {
    personStatusAndAvailability,
    updatePersonStatusAndAvailability,
    isNewExpert,
    setCanShowDialogLeavingPage,
    isFinancialUser
  } = useNetwork()

  const rolesDictionary: Dictionary = {
    expert: 'Expert',
    facilitator: 'Facilitadora',
    mentor: 'Mentora',
    manager: 'Gerente',
    speaker: 'Palestrante',
    student: 'Estudante',
    tutor: 'Tutora'
  }

  const handleSaveForm = () => {
    updatePersonStatusAndAvailability(data)
    setCanShowDialogLeavingPage(false)
  }

  const { data, errors, handleSubmit, setValue, setFullData } = useForm<StatusAndAvailabilityFormValues>({
    initialValues: {
      status: personStatusAndAvailability?.status || '',
      roles: personStatusAndAvailability?.roles || [],
      daysAvailable: personStatusAndAvailability?.daysAvailable || [],
      periodsAvailable: personStatusAndAvailability?.periodsAvailable || [],
      numberOfClasses: personStatusAndAvailability?.numberOfClasses || 0,
      comments: personStatusAndAvailability?.comments || ''
    },
    onSubmit: handleSaveForm,
    validations: {
      status: {
        custom: {
          isValid: (value: string) => value !== '',
          message: 'Preencha o campo obrigatório.'
        }
      },
      roles: {
        custom: {
          isValid: (value: string) => value.length !== 0,
          message: 'Preencha o campo obrigatório.'
        }
      }
    }
  })

  useEffect(() => {
    if (personStatusAndAvailability && data.status !== personStatusAndAvailability.status) {
      setFullData({
        status: personStatusAndAvailability.status,
        roles: personStatusAndAvailability.roles,
        daysAvailable: personStatusAndAvailability.daysAvailable,
        periodsAvailable: personStatusAndAvailability.periodsAvailable,
        numberOfClasses: personStatusAndAvailability.numberOfClasses,
        comments: personStatusAndAvailability.comments
      })
    }
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personStatusAndAvailability])

  useEffect(() => {
    if (isNewExpert && personStatusAndAvailability.status === undefined) {
      setFullData({
        status: '',
        roles: [],
        daysAvailable: [],
        periodsAvailable: [],
        numberOfClasses: 0,
        comments: ''
      })
    }
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNewExpert])

  const handleSelectStatus = (status: string) => {
    setCanShowDialogLeavingPage(true)
    setValue('status', status)
  }

  const handleSelectRole = (role: string) => {
    const foundRoleIndex = data.roles.findIndex((item) => item === role)
    if (foundRoleIndex < 0) {
      setValue('roles', [...data.roles, role])
      setCanShowDialogLeavingPage(false)
    } else {
      const newRoles = data.roles.filter((item) => item !== role)
      setValue('roles', newRoles)
      setCanShowDialogLeavingPage(true)
    }
  }

  const handleSelectDayAvailable = (day: string) => {
    const foundDayAvailableIndex = data.daysAvailable.findIndex((item) => item === day)
    if (foundDayAvailableIndex < 0) {
      setValue('daysAvailable', [...data.daysAvailable, day])
      setCanShowDialogLeavingPage(true)
    } else {
      const newDaysAvailable = data.daysAvailable.filter((dayAvailable) => dayAvailable !== day)
      setValue('daysAvailable', newDaysAvailable)
      setCanShowDialogLeavingPage(false)
    }
  }

  const handleSelectPeriodAvailable = (period: string) => {
    const foundPeriodAvailableIndex = data.periodsAvailable.findIndex((item) => item === period)
    if (foundPeriodAvailableIndex < 0) {
      setValue('periodsAvailable', [...data.periodsAvailable, period])
      setCanShowDialogLeavingPage(true)
    } else {
      const newPeriodsAvailable = data.periodsAvailable.filter((periodAvailable) => periodAvailable !== period)
      setValue('periodsAvailable', newPeriodsAvailable)
      setCanShowDialogLeavingPage(false)
    }
  }

  const handleNumberOfClasses = (numberOfClass: number) => {
    setCanShowDialogLeavingPage(true)
    setValue('numberOfClasses', numberOfClass)
  }

  const handleComments = (comments: string) => {
    setCanShowDialogLeavingPage(true)
    setValue('comments', comments)
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Label errors={errors.status}>Status na rede *</Label>
      <HorizontalPanel>
        {Object.entries(PeopleRoleStatus).map(([key, value]) => (
          <CheckboxContainer key={key} checked={data.status.includes(value)} disabled={!isFinancialUser} width="8.5rem">
            <RadioButton
              name={key}
              key={key}
              id={key}
              labelText={value}
              checked={data.status.includes(value)}
              onChange={() => handleSelectStatus(value)}
              disabled={!isFinancialUser}
            />
          </CheckboxContainer>
        ))}
      </HorizontalPanel>
      {!!errors.status && <Error>{errors.status}</Error>}

      <hr />

      <Label errors={errors.roles}>Papeis *</Label>
      <HorizontalPanel>
        {Object.entries(rolesDictionary).map(([key, value]) => (
          <CheckboxContainer key={key} checked={data.roles.includes(key)} width="6">
            <Checkbox
              name={key}
              labelText={value}
              checked={data.roles.includes(key)}
              onChange={() => handleSelectRole(key)}
              disabled={(key === 'expert' || key === 'mentor') && !isFinancialUser}
            />
          </CheckboxContainer>
        ))}
      </HorizontalPanel>
      {!!errors.roles && <Error>{errors.roles}</Error>}

      <hr />

      <Label>Expert disponível em quais dias da semana?</Label>
      <HorizontalPanel style={{ width: '30rem' }}>
        {Object.entries(AvailableDaysOfWeek).map(([key, value]) => (
          <CheckboxContainer key={key} checked={data.daysAvailable.includes(key)} width="9rem">
            <Checkbox
              name={key}
              labelText={value[0].toUpperCase() + value.substring(1)}
              checked={data.daysAvailable.includes(key)}
              onChange={() => handleSelectDayAvailable(key)}
            />
          </CheckboxContainer>
        ))}
      </HorizontalPanel>

      <hr />

      <Label>Em que período?</Label>
      <HorizontalPanel>
        {Object.entries(AvailabilityPeriod).map(([key, value]) => (
          <CheckboxContainer key={key} checked={data.periodsAvailable.includes(value)} width="10rem">
            <Checkbox
              name={key}
              labelText={value}
              checked={data.periodsAvailable.includes(value)}
              onChange={() => handleSelectPeriodAvailable(value)}
            />
          </CheckboxContainer>
        ))}
      </HorizontalPanel>

      <hr />

      <InputNumber
        name="numberOfClasses"
        label="Qual o número máximo de aulas no mês?"
        min={0}
        value={data.numberOfClasses}
        onChange={(event) => handleNumberOfClasses(Number(event.target.value))}
        increaseValue={() => handleNumberOfClasses(data.numberOfClasses + 1)}
        decreaseValue={() => handleNumberOfClasses(data.numberOfClasses - 1)}
      />

      <hr />

      <AutosizeInput
        name="comments"
        label="Observações"
        placeholder="Ex: ”Expert está em licença maternidade. Volta em Julho 2024”"
        value={data.comments}
        onChange={(event) => handleComments(event.target.value)}
      />

      <SaveButton width={isNewExpert ? '12rem' : '5.5rem'} type="submit">
        {isNewExpert ? 'Criar Perfil' : 'Salvar'}
      </SaveButton>
    </Form>
  )
}
