import styled, { css } from 'styled-components'

export const InputInformationContainer = styled.div`
  align-items: baseline;
  display: flex;
  justify-content: space-between;

  label {
    color: var(--gray-60);
    display: block;
    font-size: 0.75rem;
    font-weight: bold;
    line-height: 1.25rem;
    text-transform: uppercase;
    margin: 1.5rem 0 0.25rem;
  }

  p {
    color: var(--gray-80);
    font-weight: 300;
    font-size: 1rem;
    line-height: 1.25rem;
  }
`

export const Description = styled.p`
  color: var(--gray-60);
  margin-bottom: 0.3rem;
  font-size: 0.875rem;
`

interface ContainerProps {
  isFocused: boolean
  isInvalid: boolean
}

export const Container = styled.div<ContainerProps>`
  border: 1.5px solid var(--gray-40);
  display: flex;
  min-height: 3rem;
  padding: 0 1.75rem 0.5rem 0.5rem;
  position: relative;
  border-radius: 0.25rem;

  ${(props) =>
    props.isInvalid &&
    css`
      border-color: var(--orange);
    `}

  ${(props) =>
    props.isFocused &&
    css`
      border-color: var(--blue-primary);
    `}

  ul {
    display: inline-flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    width: 100%;

    li {
      align-items: center;
      border-radius: 1.375rem;
      color: var(--black);
      display: flex;
      justify-content: flex-start;
      margin: 0.5rem 0.5rem 0 0;
      padding: 0.3rem 0.5rem 0.3rem 0.75rem;

      button {
        background: transparent;
        border-radius: 50%;
        color: var(--gray-20);
        cursor: pointer;
        height: 1.125rem;
        margin-left: 1rem;
        width: 1.125rem;

        & + button {
          margin-left: 0.5rem;
        }

        &:hover {
          color: var(--gray-20);
          opacity: 0.8;
        }
      }

      &:last-child {
        background: transparent;
        border-radius: 0;
        padding: 0 1rem 0 0;
        flex: 1;
        min-width: 10rem;

        input {
          background: transparent;
          border: none;
          font-size: 1rem;
          height: 100%;
          outline: 0;
          width: 100%;
        }
      }
    }
  }

  > button {
    background: transparent;
    border-radius: 50%;
    height: 1.5rem;
    position: absolute;
    right: 0.5rem;
    top: calc(50% - 0.75rem);
    width: 1.5rem;

    &:hover {
      opacity: 0.8;
    }
  }
`

const colors = {
  1: 'var(--green-pastel)',
  2: 'var(--blue-pastel)',
  3: 'var(--yellow-pastel)',
  4: 'var(--purple-pastel)'
} as { [key: string]: string }

interface TagProps {
  tagKey?: number
  type: string
}

export const Tag = styled.li<TagProps>`
  background: ${(props) =>
    props.tagKey ? colors[props.tagKey - 4 * (Math.ceil(props.tagKey / 4) - 1)] : 'var(--gray-20)'};

  ${(props) =>
    props.type === 'tag' &&
    css`
      background: #d0f6cb;
    `}
`

export const Error = styled.p`
  color: var(--orange);
  font-size: 1rem;
  line-height: 1.25rem;
  margin-top: 0.5rem;
`
