import { Media } from '@somostera/tera-database'

export interface State {
  isLoading: boolean
  medias: Media[]
  error?: unknown
}

export type Action =
  | { type: 'ASYNC_CALL' }
  | { type: 'ASYNC_CALL_SUCCEED' }
  | { type: 'ASYNC_CALL_FAILED'; payload: { error: unknown } }
  | { type: 'SET_MEDIAS'; payload: { medias: Media[] } }

export const mediaReducer = (draft: State, action: Action): State => {
  switch (action.type) {
    case 'ASYNC_CALL':
      draft.isLoading = true
      break
    case 'ASYNC_CALL_SUCCEED':
      draft.isLoading = false
      break
    case 'ASYNC_CALL_FAILED':
      draft.isLoading = false
      draft.error = action.payload.error
      break
    case 'SET_MEDIAS':
      draft.medias = action.payload.medias
      break
    default:
      break
  }
  return draft
}
