import React, { useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { generateJSON } from '@tiptap/core'
import StarterKit from '@tiptap/starter-kit'
import { useForm } from 'core/hooks/useForm'
import { AddArticleFormValues } from 'modules/contents/@types/contents'
import { Button } from 'core/components/Button'
import { Input } from 'core/components/Input'
import { db } from 'core/config/firebase'

import { Container } from './styles'
import { ArticleDraft, InitializeArticleService, ArticleStatus, Chapter, ContentType } from '@somostera/tera-database'
import { ArticleType } from '@somostera/tera-database/dist/Articles/Domain/enum/ArticleType'
import { PlusCircle, Trash } from '@somostera/tera-icons'
import { Select } from 'core/components/Select'
import { useContent } from 'modules/contents/hooks/useContent'

const articleDraftService = InitializeArticleService.initialize(db)

interface Dictionary {
  [key: string]: string
}

interface ExpertData {
  id: string
  name: string
}

export function AddContent() {
  const { type } = useParams()
  const navigate = useNavigate()

  const { allPeopleData, allValidExperts } = useContent()

  const [currentExperts, setCurrentExperts] = useState<ExpertData[]>([{ id: '1', name: '' }])

  const typeDictionary: Dictionary = {
    class: ArticleType.CLASS,
    challenge: ArticleType.CHALLENGE,
    replay: ArticleType.REPLAY,
    branded_challenge: ArticleType.BRANDED_CHALLENGE,
    tutorial: ArticleType.TUTORIAL,
    case: ArticleType.CASE,
    article: ArticleType.ARTICLE,
    podcast: ArticleType.PODCAST,
    narrative: ArticleType.NARRATIVE,
    questions_answers: ArticleType.QUESTIONS_ANSWERS,
    internal: ArticleType.INTERNAL,
    debate: ArticleType.DEBATE
  }

  const handleAddArticle = async () => {
    try {
      if (data.articleTitle !== '') {
        const validExperts = currentExperts.filter((expert) => expert.id !== '1')

        setCurrentExperts(validExperts)

        const newExperts = validExperts.map((expert) => expert.id)

        const newArticle: ArticleDraft = {
          name: data.articleTitle,
          type: typeDictionary[type || 'class'].toUpperCase().replace('-', '_') as ArticleType,
          chapters: [
            {
              index: 0,
              content: generateJSON('<p></p>', [StarterKit]),
              title: '[NOVO] Capítulo',
              contentType: ContentType.CHAPTER
            } as Chapter
          ],
          participantsIds: newExperts,
          status: ArticleStatus.DRAFT,
          createdAt: new Date(),
          updatedAt: new Date()
        }

        console.log(newArticle)

        const createdArticleId = await articleDraftService.create(newArticle)

        reset()
        navigate(`/contents/${createdArticleId}`)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const { data, errors, handleChange, handleSubmit, setValue, reset } = useForm<AddArticleFormValues>({
    initialValues: {
      articleTitle: ''
    },
    onSubmit: handleAddArticle,
    validations: {
      articleTitle: {
        required: { value: true, message: 'Preencha o campo obrigatório.' }
      },
      collaborators: {
        custom: {
          isValid: (values) => values?.length > 0,
          message: 'Preencha o campo obrigatório.'
        }
      }
    }
  })

  function addExpert() {
    setCurrentExperts([...currentExperts, { id: '1', name: '' }])
  }

  function removeExpert(index: number) {
    const newCurrentExperts = [...currentExperts]
    newCurrentExperts.splice(index, 1)
    setCurrentExperts(newCurrentExperts)
  }

  function handleExpertsChange(expertId: string, index: number) {
    const expertData = allPeopleData.find((personData) => personData.id === expertId)

    const newCurrentExperts = [...currentExperts]

    newCurrentExperts[index] = {
      id: expertData?.id || '1',
      name: expertData?.name || ''
    }

    setCurrentExperts(newCurrentExperts)
  }

  return (
    <Container>
      <h1>Novo Artigo</h1>

      <form onSubmit={handleSubmit}>
        <Input
          name="articleTitle"
          type="text"
          label="Título do artigo"
          placeholder="Ex: 'Onboarding', 'Boas vindas'..."
          onChange={handleChange('articleTitle')}
          value={data.articleTitle}
          errors={errors.articleTitle}
        />

        {currentExperts.map((expert, index) => (
          <section key={index}>
            <Select
              name="experts"
              label={index === 0 ? 'Experts Participantes' : ' '}
              placeholder="Selecione quais experts estão presentes no conteúdo"
              items={[{ value: '1', name: 'Selecione quais experts estão presentes no conteúdo' }, ...allValidExperts]}
              selectedValue={expert.id}
              onChange={(event) => handleExpertsChange(event.target.value, index)}
            />

            {index === 0 ? (
              <button type="button" onClick={addExpert}>
                <PlusCircle color="var(--gray-100)" size={22} />
              </button>
            ) : (
              <button type="button" onClick={() => removeExpert(index)}>
                <Trash color="var(--red)" size={22} />
              </button>
            )}
          </section>
        ))}

        <Button
          type="submit"
          buttonStyle={{
            alignSelf: 'flex-end',
            color: 'var(--white)',
            height: '3rem',
            width: '12rem'
          }}
        >
          Criar Artigo
        </Button>
      </form>
    </Container>
  )
}
