import { lowlight } from 'lowlight'
import { ReactNodeViewRenderer } from '@tiptap/react'
import { CodeBlockLowlight } from '@tiptap/extension-code-block-lowlight'

import { CodeBlockSyntaxHighlightComponent } from './CodeBlockSyntaxHighlightComponent'

export const CodeBlockSyntaxHighlight = CodeBlockLowlight.extend({
  addNodeView() {
    return ReactNodeViewRenderer(CodeBlockSyntaxHighlightComponent)
  }
}).configure({ lowlight })
