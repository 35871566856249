import React, { useEffect, useState } from 'react'
import { LivesService } from 'modules/lives/services/lives'
import { useAuth } from 'core/hooks/useAuth'
import { DataTableBase } from 'modules/courses/components/DataTableBase'
import { ContainerCell } from 'modules/buildingblock/components/ContainerCell'
import { TableColumn } from 'react-data-table-component'
import { Input } from 'modules/lives/components/Input'
import { InputSelect } from 'modules/lives/components/Select'
import ToggleSwitch from 'modules/lives/components/ToggleSwitch'
import { MultiSelect } from 'modules/lives/components/MultiSelect'
import { TagsInput } from 'modules/lives/components/TagsInput'
import Trash from 'core/components/Icon/Trash'
import RightArrow from 'core/components/Icon/RightArrow'
import Calendar from 'core/components/Icon/Calendar'
import { InputDate } from 'modules/lives/components/InputDate'
import { PlusCircle } from '@somostera/tera-icons'
import {
  AsideContainer,
  Button,
  Content,
  Container,
  Header,
  TableCpntainer,
  AsideContent,
  EditorialContainer,
  OutcomesContainer,
  EditoriasContainer,
  TopicsContainer,
  VisibilityContainer,
  FooterAside,
  ButtonContainer,
  Navbar,
  ListItem,
  TextContainer,
  AsideEdit,
  ToggleSwitchContainer,
  SelectsContainer,
  SelectContainer,
  MultiSelectContainer,
  TypeContainer,
  Tag,
  ExpertsContainer,
  Label,
  ZoomContainer,
  HoursContainer,
  AutosizeContainer,
  ZoomInputContainer,
  DeleteContainer
} from './styles'
import { PeopleService } from 'modules/lives/services/people'
import { parseDate } from 'core/utils/date'
import { AutosizeInput } from 'modules/lives/components/AutosizeInput'

export interface Live {
  id: string
  name: string
  description: string
  career: string[]
  status: string
  visibility: string
  updatedAt: string
  readingTime: number
  type: string
}

export function Lives() {
  const { userFirebase } = useAuth()
  const [lives, setLives] = useState<Live[]>([])
  const [schedules, setSchedules] = useState<Live[]>([])
  const [currentLiveIndex, setCurrentLiveIndex] = useState(0)
  const [currentScheduleIndex, setCurrentScheduleIndex] = useState(0)
  const [selectedLive, setSelectedLive] = useState(null)
  const [selectedSchelude, setSelectedSchelude] = useState(null)
  const [showAside, setShowAside] = useState(false)
  const [showSchedule, setShowSchedule] = useState(false)
  const [edit, setEdit] = useState(false)
  const [editSchedule, setEditSchedule] = useState(false)
  const [showEdit, setShowEdit] = useState<null | object>(null)
  const [listItemActive, setListItemActive] = useState('content')
  const [dateScheduleEdit, setDateScheduleEdit] = useState('')
  const [experts, setExperts] = useState([])
  const [facilitator, setFacilitator] = useState([])
  const [newLinkText, setNewLinkText] = useState('Novo Link')

  const LiveType = {
    'welcome-session': 'Onboarding',
    tutorial: 'Tutoriais',
    trend: 'Trend',
    masterclass: 'Masterclass'
  }

  const ExpertStatusType = {
    aceito: 'Convite aceito',
    recusado: 'Convite recusado',
    'enviar-convite': 'Enviar convite',
    selecionado: 'Expert selecionado'
  }

  const StatusType = {
    draft: 'Rascunho',
    published: 'Publicado',
    archived: 'Arquivado',
    deleted: 'Excluído'
  }

  const EditoriaType = {
    'product-management': 'Gestão de Produtos',
    'leadership-business': 'Liderança & Negócios',
    'data-ai': 'Dados & IA',
    'design-innovation': 'Design & Inovação',
    programming: 'Desenvolvimento de Software',
    marketing: 'Marketing Digital'
  }

  const statusOptions = [
    { value: 'aceito', label: 'Convite aceito' },
    { value: 'recusado', label: 'Convite recusado' },
    { value: 'enviar-convite', label: 'Enviar convite' },
    { value: 'selecionado', label: 'Expert selecionado' }
  ]

  const liveOptions = Object.keys(LiveType).map((key) => ({
    value: key,
    label: LiveType[key]
  }))

  const editoriaOptions = Object.keys(EditoriaType).map((key) => ({
    value: key,
    label: EditoriaType[key]
  }))

  const [isChecked, setIsChecked] = useState(false)
  const isVisible = isChecked ? 'Público' : 'Privado'
  const handleCheck = () => setIsChecked(!isChecked)

  const [isDraft, setIsDraft] = useState(true)
  const draftLabel = isDraft ? 'Rascunho' : 'Publicado'
  const handleCheckDraft = () => setIsDraft(!isDraft)

  const [isDraftSchedule, setIsDraftSchedule] = useState(true)
  const draftLabelSchedule = isDraftSchedule ? 'Rascunho' : 'Publicado'
  const handleCheckDraftSchedule = () => setIsDraftSchedule(!isDraftSchedule)

  function formatSelectedLive(currentLive: object) {
    const editoriasFormated = currentLive.areas.map((currentEditoria) => {
      if (typeof currentEditoria !== 'object') {
        return currentEditoria
      } else {
        return currentEditoria.value
      }
    })

    const typeFormated =
      typeof currentLive?.type !== 'string'
        ? currentLive?.type
        : { value: currentLive?.type, label: LiveType[currentLive?.type] }
    currentLive.areas = editoriasFormated
    currentLive.type = typeFormated
    return currentLive
  }

  function formatedSelectedSchedule(currentSchedule: object) {
    const expertsFormated =
      currentSchedule?.experts?.length > 0
        ? currentSchedule?.experts
            .map((expert) => ({
              value: expert.peopleId || expert.value,
              label: expert.name || expert.label,
              status: expert.status
            }))
            .filter((exp) => exp.label?.length > 0)
        : []

    const facilitatorsFormated = currentSchedule?.facilitator
      ? {
          value: currentSchedule?.facilitator.peopleId || currentSchedule?.facilitator.value,
          label: currentSchedule?.facilitator.name || currentSchedule?.facilitator.label,
          status: currentSchedule?.facilitator.status
        }
      : undefined

    currentSchedule.experts = expertsFormated
    currentSchedule.facilitator = !facilitatorsFormated?.value ? undefined : facilitatorsFormated

    return currentSchedule
  }

  async function getAllExperts() {
    const token = await userFirebase.getIdToken()
    const response = await PeopleService.getAll(token, 'expert')
    setExperts(response.data)
  }

  async function getAllFacilitator() {
    const token = await userFirebase.getIdToken()
    const response = await PeopleService.getAll(token, 'facilitator')
    setFacilitator(response.data)
  }

  function addExpert() {
    const newExperts = [...selectedSchelude?.experts, { value: '1', label: 'Selecione o expert', status: undefined }]
    const newSelectedSchedule = { ...selectedSchelude }
    newSelectedSchedule.experts = newExperts

    setSelectedSchelude(newSelectedSchedule)
  }

  function removeExpert(index: number) {
    const newSelectedsExperts = [...selectedSchelude?.experts]
    newSelectedsExperts.splice(index, 1)
    const newSelectedSchedule = { ...selectedSchelude }
    newSelectedSchedule.experts = newSelectedsExperts
    setSelectedSchelude(newSelectedSchedule)
  }

  const expertsOptions = experts
    .map((expert) => ({
      value: expert.id,
      label: expert.name
    }))
    .filter((exp) => exp.label?.length > 0)

  const facilitatorOptions = facilitator
    .map((facilitator) => ({
      value: facilitator.id,
      label: facilitator.name
    }))
    .filter((fac) => fac.label?.length > 0)

  function handleAddExpert(expert, index: number) {
    const newSelectedsExperts = [...selectedSchelude.experts]

    if (!newSelectedsExperts.some((expertSelected) => expertSelected.id === expert.id)) {
      newSelectedsExperts.push(expert)
      const newSelectedSchedule = { ...selectedSchelude }
      newSelectedSchedule.experts = newSelectedsExperts

      setSelectedSchelude(newSelectedSchedule)
      return
    } else {
      newSelectedsExperts[index] = {
        value: expert.value,
        label: expert.label
      }
    }

    const newSelectedSchedule = { ...selectedSchelude }
    newSelectedSchedule.experts = newSelectedsExperts

    setSelectedSchelude(newSelectedSchedule)
  }

  function editSelectedLive(value: string, key: string) {
    const newSelectedLive = { ...selectedLive }

    newSelectedLive[key] = value

    setSelectedLive(newSelectedLive)
  }

  function editSelectedScheduleLive(value: string, key: string) {
    const newSelectedScheduleLive = { ...selectedSchelude }

    newSelectedScheduleLive[key] = value

    setSelectedSchelude(newSelectedScheduleLive)
  }

  function editSelectedScheduleStatus(value, type, index = 0) {
    const newSelectedScheduleLive = { ...selectedSchelude }
    if (type === 'facilitator') {
      newSelectedScheduleLive.facilitator.status = value.value
    } else {
      newSelectedScheduleLive.experts[index].status = value.value
    }
    setSelectedSchelude(newSelectedScheduleLive)
  }

  async function getAllLives() {
    const token = await userFirebase.getIdToken()
    const response = await LivesService.getAll(token)
    setLives(response.data.filter((live) => live.status !== 'deleted'))
  }

  async function getAllLivesSchedules() {
    const token = await userFirebase.getIdToken()
    const { data: experts } = await PeopleService.getAll(token, 'expert')
    const { data: facilitators } = await PeopleService.getAll(token, 'facilitator')
    const response = await LivesService.getAllSchedules(token)
    const schedules = response.data.filter((schedule) => schedule.status !== 'deleted')

    const schedulesFormated = schedules
      .map((schedule) => {
        return schedule.schedules
          ? schedule.schedules.map((s) => {
              const expertsIds = s.participants.filter((expert) => expert.role === 'expert').map((e) => e.peopleId)
              const facilitatorIds = s.participants
                .filter((facilitator) => facilitator.role === 'facilitator')
                .map((f) => f.peopleId)
              const currentExperts = experts.filter((ex) => expertsIds.includes(ex.id))
              const currentFacilitators = facilitators.filter((fa) => facilitatorIds.includes(fa.id))
              const { day, month, year, hours, minutes } = parseDate(s.startDate)
              const { hours: endDateHours, minutes: endDateMinutes } = parseDate(s.endDate)

              return {
                id: schedule.id,
                scheduleId: s.id,
                dateCalendar: `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`,
                date: `${day}/${String(month).padStart(2, '0')}/${year}`,
                startDate: parseDate(s.startDate),
                endDate: parseDate(s.endDate),
                startTime: { value: `${hours}:${minutes}`, label: `${hours}:${minutes}` },
                endTime: { value: `${endDateHours}:${endDateMinutes}`, label: `${endDateHours}:${endDateMinutes}` },
                experts: currentExperts,
                liveName: s.liveName,
                status: s.status,
                liveDescription: s.liveDescription,
                recordingUrl: s.recordingUrl,
                outcomes: s.outcomes,
                facilitator: currentFacilitators.shift(),
                type: schedule.type,
                visibility: schedule.visibility,
                createdAt: schedule.createdAt,
                updatedAt: schedule.updatedAt,
                url: s.url
              }
            })
          : null
      })
      .filter((s) => s !== null)
      .flat()

    const orderedSchedules = schedulesFormated.sort((a, b) => {
      if (a.dateCalendar > b.dateCalendar) {
        return 1
      }
      if (a.dateCalendar < b.dateCalendar) {
        return -1
      }
      return 0
    })

    orderedSchedules.forEach(async (schedule) => {
      const { data: hostInvites } = await LivesService.getHostInvites(token, schedule.id, schedule.scheduleId)

      const expertsHost = hostInvites.filter((host) => host.role === 'expert')
      const facilitatorHost = hostInvites.filter((host) => host.role === 'facilitator')

      const expertsWithName = await Promise.all(
        expertsHost.map(async (expert) => {
          const fullExpert = experts.find((ex) => ex.id === expert.peopleId)
          return {
            ...expert,
            name: fullExpert.name
          }
        })
      )

      const facilitatorWithName = await Promise.all(
        facilitatorHost.map(async (facilitator) => {
          const fullFacilitator = facilitators.find((fa) => fa.id === facilitator.peopleId)
          return {
            ...facilitator,
            name: fullFacilitator.name
          }
        })
      )

      const currentFacilitator = facilitatorWithName.shift()
      schedule.experts = expertsWithName
      schedule.facilitator = currentFacilitator
    })

    setSchedules(orderedSchedules)
  }

  async function deleteLive(liveId: string) {
    const token = await userFirebase.getIdToken()
    const response = await LivesService.deleteLive(token, liveId)

    const newLives = [...lives]
    newLives.splice(currentLiveIndex, 1)
    setLives(newLives)
    setShowAside(false)
  }

  async function editLive() {
    const token = await userFirebase.getIdToken()
    const data = {
      id: selectedLive.id,
      name: selectedLive.name,
      type: selectedLive.type.value || selectedLive.type,
      areas: selectedLive.areas,
      description: selectedLive.description,
      keywords: [...selectedLive.keywords],
      outcomes: [...selectedLive.outcomes],
      visibility: isChecked ? 'public' : 'private',
      status: isDraft ? 'draft' : 'published'
    }

    await LivesService.editLive(token, selectedLive.id, data)
    setEdit(false)
    setShowAside(true)
  }

  useEffect(() => {
    getAllLives()
    getAllLivesSchedules()
    getAllExperts()
    setNewLinkText('Novo Link')
  }, [edit, editSchedule])

  useEffect(() => {
    setEditSchedule(false)
    setShowSchedule(false)
    setEdit(false)
    setShowAside(false)
    setNewLinkText('Novo Link')
  }, [listItemActive])

  useEffect(() => {
    editSelectedScheduleLive(dateScheduleEdit, 'dateCalendar')
  }, [dateScheduleEdit])

  useEffect(() => {
    getAllExperts()
    getAllFacilitator()
  }, [])

  useEffect(() => {
    setIsDraftSchedule(selectedSchelude?.status === 'draft')
  }, [selectedSchelude])

  function typeOption(option: string) {
    switch (option) {
      case 'welcome-session':
        return 'Onboarding'
      case 'tutorial':
        return 'Tutoriais'
      case 'trend':
        return 'Trend'
      case 'masterclass':
        return 'Masterclass'
      default:
        return 'Opção inválida'
    }
  }

  const columns: TableColumn<Live>[] = [
    {
      name: 'Nome da Live',
      selector: ({ name }) => name,
      sortable: true,
      cell: (row: Live, index) => (
        <ContainerCell
          onClick={() => {
            setCurrentLiveIndex(index)
            setSelectedLive(formatSelectedLive(lives[index]))
            setShowAside(true)
          }}
        >
          {row.name}
        </ContainerCell>
      ),
      width: '330px'
    },
    {
      name: 'Tipo',
      selector: ({ type }) => type,
      sortable: true,
      cell: (row: Live, index) => (
        <ContainerCell
          onClick={() => {
            setCurrentLiveIndex(index)
            setSelectedLive(formatSelectedLive(lives[index]))
            setShowAside(true)
          }}
        >
          <Tag>
            <p>{typeOption(typeof row.type === 'string' ? row.type : row.type?.value)}</p>
          </Tag>
        </ContainerCell>
      ),
      width: '150px'
    },
    {
      name: 'Editorias',
      selector: ({ areas }) => areas,
      sortable: true,
      cell: (row: Live, index) => (
        <div
          onClick={() => {
            setCurrentLiveIndex(index)
            setSelectedLive(formatSelectedLive(lives[index]))
            setShowAside(true)
          }}
        >
          {row.areas?.map((area, index: number) => {
            return (
              <Tag key={index}>
                <p>{EditoriaType[area] || area.label}</p>
              </Tag>
            )
          })}
        </div>
      ),
      width: '220px'
    }
  ]

  const schedulesColumns: TableColumn<Live>[] = [
    {
      name: 'Data da Live',
      selector: ({ date }) => date ?? [],
      sortable: true,
      cell: (row: Live, index: number) => (
        <ContainerCell
          onClick={() => {
            setCurrentScheduleIndex(index)
            setSelectedSchelude(formatedSelectedSchedule(schedules[index]))
            setShowSchedule(true)
          }}
        >
          {`${row.date}`}
        </ContainerCell>
      ),
      width: '150px'
    },
    {
      name: 'Nome da Live',
      selector: ({ liveName }) => liveName ?? '',
      sortable: true,
      cell: (row: Live, index: number) => (
        <ContainerCell
          onClick={() => {
            setCurrentScheduleIndex(index)
            setSelectedSchelude(formatedSelectedSchedule(schedules[index]))
            setShowSchedule(true)
          }}
        >
          <p>{row.liveName}</p>
        </ContainerCell>
      ),
      width: '200px'
    },
    {
      name: 'Expert',
      selector: ({ experts }) => experts ?? [],
      sortable: true,
      cell: (row: Live, index: number) => (
        <div
          onClick={() => {
            setCurrentScheduleIndex(index)
            setSelectedSchelude(formatedSelectedSchedule(schedules[index]))
            setShowSchedule(true)
          }}
        >
          {row.experts?.length > 0
            ? row.experts?.map((expert, index: number) => (
                <div style={{ width: '100%' }} key={index}>
                  {expert.name || expert.label}
                </div>
              ))
            : 'Nenhum'}
        </div>
      ),
      width: '180px'
    },
    {
      name: 'Status',
      selector: ({ areas }) => areas ?? '',
      sortable: true,
      cell: (row: Live, index) => (
        <ContainerCell
          onClick={() => {
            setCurrentScheduleIndex(index)
            setSelectedSchelude(formatedSelectedSchedule(schedules[index]))
            setShowSchedule(true)
          }}
        >
          {StatusType[row.status] || 'Rascunho'}
        </ContainerCell>
      ),
      width: '150px'
    }
  ]

  function handleChangeListItem(type: string) {
    setListItemActive(type)
    setShowEdit(null)
    setShowAside(false)
    setEdit(false)
  }

  function formattedDate(data) {
    const parts = data.split('T')[0].split('-')

    const year = parts[0]
    const month = parts[1]
    const day = parts[2]

    return day + '/' + month + '/' + year
  }

  function getHoursArray() {
    const hoursArray = []

    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 15) {
        const formattedHour = hour.toString().padStart(2, '0')
        const formattedMinute = minute.toString().padStart(2, '0')
        const timeSlot = `${formattedHour}:${formattedMinute}`
        hoursArray.push(timeSlot)
      }
    }

    return hoursArray
  }

  const hours = getHoursArray()

  const hoursOptions = hours.map((key) => ({
    value: key,
    label: key
  }))

  function joinDate(date, time) {
    const partialDate = date.split('-')
    const partialTime = time.split(':')

    const year = partialDate[0]
    const month = partialDate[1] - 1
    const day = partialDate[2]

    const hour = partialTime[0]
    const minutes = partialTime[1]

    const dateTimeUTC = new Date(year, month, day, hour, minutes, 0, 0)

    dateTimeUTC.setMilliseconds(0)

    dateTimeUTC.setUTCHours(dateTimeUTC.getUTCHours() - 3)

    const dateTimeUTCFormated = dateTimeUTC.toISOString()

    return dateTimeUTCFormated
  }

  async function handleSchedule() {
    const token = await userFirebase.getIdToken()

    const initialFormatedDate = joinDate(selectedSchelude?.dateCalendar, selectedSchelude?.startTime.value)
    const endFormatedDate = joinDate(selectedSchelude?.dateCalendar, selectedSchelude?.endTime.value)

    const data = {
      liveId: selectedSchelude?.id,
      liveScheduleId: selectedSchelude?.scheduleId,
      startDate: initialFormatedDate,
      endDate: endFormatedDate,
      liveName: selectedSchelude?.liveName,
      outcomes: [...selectedSchelude.outcomes],
      liveDescription: selectedSchelude?.liveDescription,
      recordingUrl: selectedSchelude?.recordingUrl,
      status: isDraftSchedule ? 'draft' : 'published',
      url: selectedSchelude?.url || ''
    }

    console.log('data to save', data)

    const response = await LivesService.schedulesLiveEdit(
      token,
      selectedSchelude?.id,
      selectedSchelude?.scheduleId,
      data
    )

    return response.data
  }

  async function handleSendInvite() {
    const token = await userFirebase.getIdToken()
    const expertsData =
      selectedSchelude?.experts?.length > 0 ? selectedSchelude?.experts?.map((expert) => expert.value) : []

    const expertsLiveData = expertsData.map((expert: string, index: number) => ({
      liveId: selectedSchelude?.id,
      liveScheduleId: selectedSchelude?.scheduleId,
      peopleId: expert,
      status: selectedSchelude?.experts[index]?.status,
      role: 'expert'
    }))

    const data = [
      ...expertsLiveData,
      {
        liveId: selectedSchelude?.id,
        liveScheduleId: selectedSchelude?.scheduleId,
        peopleId: selectedSchelude?.facilitator?.value,
        status: selectedSchelude?.facilitator?.status,
        role: 'facilitator'
      }
    ].filter((d) => d.peopleId !== undefined && d.peopleId !== null && d.peopleId !== '1')

    if (data.length > 0)
      await LivesService.sendInviteLive(token, selectedSchelude?.id, selectedSchelude?.scheduleId, data)

    setEditSchedule(false)
    setShowSchedule(false)
    setEdit(false)
    setShowAside(false)
    setNewLinkText('Novo Link')
  }

  async function publishLive() {
    const token = await userFirebase.getIdToken()

    const data = {
      liveId: selectedSchelude?.id,
      liveScheduleId: selectedSchelude?.scheduleId,
      liveName: selectedSchelude?.liveName,
      liveDescription: selectedSchelude?.liveDescription,
      status: 'published'
    }

    await LivesService.schedulesLiveEdit(token, selectedSchelude?.id, selectedSchelude?.scheduleId, data)
    setEditSchedule(false)
    setShowSchedule(true)
  }

  async function newLinkZoom() {
    if (!selectedSchelude?.facilitator || !selectedSchelude?.facilitator?.status) {
      alert('Você precisa informar o facilitador')
      return
    }
    const token = await userFirebase.getIdToken()
    const data = {
      facilitatorId: selectedSchelude?.facilitator?.value
    }

    data.facilitatorId
      ? await LivesService.newLinkZoom(token, selectedSchelude?.id, selectedSchelude?.scheduleId, data)
      : null

    setNewLinkText('Link Gerado')
  }

  return (
    <Container>
      <Content>
        <Header>
          <h2>Lives</h2>
        </Header>
        <Navbar showEdit={!!showEdit}>
          <ul>
            <section>
              <ListItem
                scheduleActive={listItemActive === 'schedules' && !!showEdit}
                active={listItemActive === 'content'}
                onClick={() => handleChangeListItem('content')}
              >
                Conteúdo
              </ListItem>
              <ListItem
                active={listItemActive === 'schedules'}
                onClick={() => handleChangeListItem('schedules')}
                contentActive={listItemActive === 'content' && !!showEdit}
              >
                Agendamentos
              </ListItem>
            </section>
            {listItemActive === 'content' && (
              <section>
                <Button to={`/lives/new`}>nova live</Button>
              </section>
            )}
          </ul>
        </Navbar>
        {listItemActive === 'content' && (
          <TableCpntainer>
            <DataTableBase
              columns={columns}
              data={lives}
              pagination
              noDataComponent={'Nenhum dado encontrado'}
              selectableRowsNoSelectAll={true}
            />
          </TableCpntainer>
        )}
        {listItemActive === 'schedules' && (
          <TableCpntainer>
            <DataTableBase
              columns={schedulesColumns}
              data={schedules}
              pagination
              noDataComponent={'Nenhum dado encontrado'}
              selectableRowsNoSelectAll={true}
            />
          </TableCpntainer>
        )}
      </Content>

      {listItemActive === 'content' && (
        <AsideContainer>
          {showAside ? (
            <AsideContent>
              <div onClick={() => setShowAside(!showAside)}>
                <RightArrow />
              </div>
              {edit === false && (
                <>
                  <span>
                    {typeof lives[currentLiveIndex].type === 'object'
                      ? lives[currentLiveIndex].type?.label
                      : LiveType[lives[currentLiveIndex].type]}
                  </span>
                  <h4>{lives[currentLiveIndex].name}</h4>
                  <p>{lives[currentLiveIndex].description}</p>

                  <EditorialContainer>
                    <OutcomesContainer>
                      <h4>Objetivos de aprendizagem</h4>
                      <ul>
                        {lives[currentLiveIndex]?.outcomes
                          ? lives[currentLiveIndex]?.outcomes.map((outcome, index) => <li key={index}>{outcome}</li>)
                          : 'Nenhum'}
                      </ul>
                    </OutcomesContainer>
                    <EditoriasContainer>
                      <h4>Editorias</h4>
                      <ul>
                        {lives[currentLiveIndex]?.areas
                          ? lives[currentLiveIndex]?.areas.map((area, index) => (
                              <li key={index}>{EditoriaType[area] || area.label}</li>
                            ))
                          : 'Nenhum'}
                      </ul>
                    </EditoriasContainer>
                    <TopicsContainer>
                      <h4>Tópicos</h4>
                      <ul>
                        {lives[currentLiveIndex]?.keywords
                          ? lives[currentLiveIndex]?.keywords.map((keyword, index) => <li key={index}>{keyword}</li>)
                          : 'Nenhum'}
                      </ul>
                    </TopicsContainer>
                    <VisibilityContainer>
                      <h4>Visibilidade</h4>
                      <ul>
                        <li>{lives[currentLiveIndex]?.visibility === 'public' ? 'Público ' : 'Privado'}</li>
                      </ul>
                    </VisibilityContainer>
                    {/* <VisibilityContainer>
                      <h4>Status</h4>
                      <ul>
                        <li>{StatusType[lives[currentLiveIndex]?.status]}</li>
                      </ul>
                    </VisibilityContainer> */}
                  </EditorialContainer>
                </>
              )}

              {edit === true && (
                <AsideEdit>
                  <AutosizeContainer>
                    <AutosizeInput
                      type="text"
                      name="title"
                      placeholder="Escreva o título da live"
                      value={selectedLive.name}
                      defaultValue={selectedLive.name}
                      onChange={(event) => editSelectedLive(event.target.value, 'name')}
                      required
                    />
                  </AutosizeContainer>
                  <AutosizeContainer>
                    <AutosizeInput
                      type="text"
                      name="title"
                      placeholder="Escreva o título da live"
                      value={selectedLive.description}
                      defaultValue={selectedLive.description}
                      onChange={(event) => editSelectedLive(event.target.value, 'description')}
                      required
                    />
                  </AutosizeContainer>
                  <SelectsContainer>
                    <TypeContainer>
                      <InputSelect
                        label="Tipo de Live"
                        placeholder="Selecione qual o tipo de live"
                        options={liveOptions}
                        onChange={(event) => {
                          editSelectedLive(event, 'type')
                        }}
                        value={{
                          value: selectedLive?.type?.value || null,
                          label: selectedLive?.type?.label || 'Selecione qual o tipo de live'
                        }}
                      />
                    </TypeContainer>
                    <SelectContainer>
                      <TagsInput
                        name="outcomes"
                        label="Objetivos de aprendizagem"
                        placeholder="Descrição breve do que é este conteúdo"
                        value={selectedLive.outcomes}
                        onTagsChange={(tags: string[]) => editSelectedLive([...tags], 'outcomes')}
                        type="tag"
                      />
                    </SelectContainer>
                    <MultiSelectContainer>
                      <MultiSelect
                        name="areas"
                        options={editoriaOptions}
                        onChange={(event) => editSelectedLive(event, 'areas')}
                        value={
                          typeof selectedLive?.areas[0] !== 'object'
                            ? selectedLive?.areas.map((area) => ({
                                value: area,
                                label: EditoriaType[area]
                              }))
                            : selectedLive?.areas
                        }
                        label="Editorias"
                      />
                    </MultiSelectContainer>
                    <SelectContainer>
                      <TagsInput
                        name="keywords"
                        label="Tópicos"
                        placeholder="Descrição breve do que é este conteúdo"
                        value={selectedLive.keywords}
                        onTagsChange={(tags: string[]) => editSelectedLive([...tags], 'keywords')}
                        type="tag"
                      />
                    </SelectContainer>
                    <ToggleSwitchContainer>
                      <h3>Visibilidade</h3>
                      <div>
                        <ToggleSwitch onClick={handleCheck} />
                        <span>{isVisible}</span>
                      </div>
                    </ToggleSwitchContainer>
                    {/* <ToggleSwitchContainer>
                      <h3>Status</h3>
                      <div>
                        <ToggleSwitch onClick={handleCheckDraft} />
                        <span>{draftLabel}</span>
                      </div>
                    </ToggleSwitchContainer> */}
                    <DeleteContainer onClick={() => deleteLive(selectedLive.id)}>
                      <span>Excluir live</span>
                      <Trash />
                    </DeleteContainer>
                  </SelectsContainer>
                </AsideEdit>
              )}

              <FooterAside>
                <ButtonContainer>
                  {edit ? (
                    <button onClick={() => editLive()}>Salvar</button>
                  ) : (
                    <button onClick={() => setEdit(true)}>Editar</button>
                  )}
                  <Button to={`/lives/${lives[currentLiveIndex]?.id}/new/schedule`} edit={edit}>
                    <Calendar />
                    Agendar
                  </Button>
                </ButtonContainer>
              </FooterAside>
            </AsideContent>
          ) : (
            <TextContainer>
              <p>Clique nos cards de live ao lado para visualizar mais detalhes aqui</p>
            </TextContainer>
          )}
        </AsideContainer>
      )}

      {listItemActive === 'schedules' && (
        <AsideContainer>
          {showSchedule ? (
            <AsideContent>
              <div onClick={() => setShowSchedule(!showSchedule)}>
                <RightArrow />
              </div>
              {editSchedule === false && (
                <>
                  <span>
                    {typeof schedules[currentScheduleIndex].type === 'object'
                      ? schedules[currentScheduleIndex].type?.label
                      : LiveType[schedules[currentScheduleIndex].type]}
                  </span>
                  <h4>{schedules[currentScheduleIndex].liveName}</h4>
                  <span>
                    Criado em{' '}
                    {schedules[currentScheduleIndex]?.createdAt
                      ? formattedDate(schedules[currentScheduleIndex].createdAt)
                      : '00/00/00'}{' '}
                    | Atualizado em{' '}
                    {schedules[currentScheduleIndex]?.updatedAt
                      ? formattedDate(schedules[currentScheduleIndex].updatedAt)
                      : '00/00/00'}
                  </span>
                  <p>{schedules[currentScheduleIndex].liveDescription}</p>

                  <EditorialContainer>
                    <OutcomesContainer>
                      <h4>Objetivos de aprendizagem</h4>
                      <ul>
                        {schedules[currentScheduleIndex]?.outcomes
                          ? schedules[currentScheduleIndex]?.outcomes.map((outcome, index) => (
                              <li key={index}>{outcome}</li>
                            ))
                          : 'Nenhum'}
                      </ul>
                    </OutcomesContainer>
                    <EditoriasContainer>
                      <h4>Data da Live</h4>
                      <ul>{`${schedules[currentScheduleIndex]?.date}`}</ul>
                    </EditoriasContainer>
                    <TopicsContainer>
                      <h4>Início</h4>
                      <ul>
                        {`${schedules[currentScheduleIndex]?.startDate?.hours}:${schedules[currentScheduleIndex]?.startDate?.minutes}`}
                      </ul>
                    </TopicsContainer>
                    <TopicsContainer>
                      <h4>Encerramento</h4>
                      <ul>
                        {`${schedules[currentScheduleIndex]?.endDate?.hours}:${schedules[currentScheduleIndex]?.endDate?.minutes}`}
                      </ul>
                    </TopicsContainer>
                    <OutcomesContainer>
                      <h4>Experts</h4>
                      <ul>
                        {schedules[currentScheduleIndex]?.experts.length > 0
                          ? schedules[currentScheduleIndex]?.experts?.map((expert, index: number) => (
                              <div key={index}>
                                <li>
                                  {expert.name || expert.label} -{' '}
                                  {ExpertStatusType[expert.status] ||
                                    ExpertStatusType[selectedSchelude?.experts[index].status]}
                                </li>
                              </div>
                            ))
                          : 'Nenhum'}
                      </ul>
                    </OutcomesContainer>
                    <OutcomesContainer>
                      <h4>Facilitadores</h4>
                      <ul>
                        <li>
                          {schedules[currentScheduleIndex]?.facilitator
                            ? `${
                                schedules[currentScheduleIndex]?.facilitator.label ||
                                schedules[currentScheduleIndex]?.facilitator.name
                              } - ${
                                ExpertStatusType[schedules[currentScheduleIndex]?.facilitator.status] ||
                                ExpertStatusType[selectedSchelude?.facilitator.status]
                              }`
                            : 'Nenhum'}
                        </li>
                      </ul>
                    </OutcomesContainer>
                    <ZoomContainer>
                      <h4>Link do Zoom</h4>
                      <span>{`${schedules[currentScheduleIndex]?.url || 'O link não foi criado'}`}</span>
                    </ZoomContainer>
                    <br />
                    <ZoomContainer>
                      <h4>Link de Gravação</h4>
                      <span>{`${schedules[currentScheduleIndex]?.recordingUrl || 'O link não foi gerado'}`}</span>
                    </ZoomContainer>
                    <br />
                    <ZoomContainer>
                      <h4>Status</h4>
                      <span>{`${StatusType[schedules[currentScheduleIndex]?.status] || 'Rascunho'}`}</span>
                    </ZoomContainer>
                  </EditorialContainer>
                </>
              )}

              {editSchedule && (
                <AsideEdit>
                  <AutosizeContainer>
                    <AutosizeInput
                      type="text"
                      name="title"
                      placeholder="Escreva o título da live"
                      value={selectedSchelude.liveName}
                      defaultValue={selectedSchelude.liveName}
                      onChange={(event) => editSelectedScheduleLive(event.target.value, 'liveName')}
                      required
                    />
                  </AutosizeContainer>
                  <AutosizeContainer>
                    <AutosizeInput
                      type="text"
                      name="title"
                      placeholder="Escreva o título da live"
                      value={selectedSchelude.liveDescription}
                      defaultValue={selectedSchelude.liveDescription}
                      onChange={(event) => editSelectedScheduleLive(event.target.value, 'liveDescription')}
                      required
                    />
                  </AutosizeContainer>
                  <br />
                  <SelectsContainer>
                    <SelectContainer>
                      <TagsInput
                        name="outcomes"
                        label="Objetivos de aprendizagem"
                        placeholder="Descrição breve do que é este conteúdo"
                        value={selectedSchelude.outcomes}
                        onTagsChange={(tags: string[]) => editSelectedScheduleLive([...tags], 'outcomes')}
                        type="tag"
                      />
                    </SelectContainer>
                  </SelectsContainer>
                  <InputDate
                    type="date"
                    name="date"
                    value={dateScheduleEdit || selectedSchelude.dateCalendar}
                    onChange={setDateScheduleEdit}
                    required
                  />
                  <HoursContainer>
                    <label>Horário</label>
                    <section>
                      <InputSelect
                        placeholder="Começa às"
                        options={hoursOptions}
                        onChange={(event) => editSelectedScheduleLive(event, 'startTime')}
                        value={{
                          value: selectedSchelude?.startTime?.value || null,
                          label: selectedSchelude?.startTime?.value ? selectedSchelude?.startTime?.value : 'Começa às'
                        }}
                      />
                    </section>
                    <section>
                      <InputSelect
                        placeholder="Termina às"
                        options={hoursOptions}
                        onChange={(event) => editSelectedScheduleLive(event, 'endTime')}
                        value={{
                          value: selectedSchelude?.endTime?.value || null,
                          label: selectedSchelude?.endTime?.value ? selectedSchelude?.endTime?.value : 'Começa às'
                        }}
                      />
                    </section>
                  </HoursContainer>
                  {selectedSchelude?.experts.length === 0 ? (
                    <ExpertsContainer>
                      <div>
                        <InputSelect
                          unshiftExpert
                          type="expert"
                          label={'Experts'}
                          placeholder="Selecione o expert"
                          options={expertsOptions}
                          onChange={(event) => {
                            handleAddExpert(event, 0)
                          }}
                          value={{
                            value: selectedSchelude?.experts.length > 0 ? selectedSchelude?.experts[0]?.value : '1',
                            label:
                              selectedSchelude?.experts.length > 0
                                ? selectedSchelude?.experts[0]?.label
                                : 'Selecione o expert'
                          }}
                        />
                        <InputSelect
                          type="expert"
                          label="Status do convite"
                          placeholder="Selecione o expert"
                          options={statusOptions}
                          onChange={(event) => editSelectedScheduleStatus(event, 'experts', 0)}
                          value={{
                            value: selectedSchelude.experts[0]?.status ? selectedSchelude.experts[0]?.status : '1',
                            label: selectedSchelude.experts[0]?.status
                              ? ExpertStatusType[selectedSchelude.experts[0]?.status]
                              : 'Selecione o status'
                          }}
                        />
                      </div>

                      <button type="button" onClick={addExpert}>
                        <PlusCircle color="var(--gray-100)" size={22} />
                      </button>
                    </ExpertsContainer>
                  ) : (
                    selectedSchelude?.experts.map((expert, index) => (
                      <ExpertsContainer key={index}>
                        <div key={index}>
                          <InputSelect
                            unshiftExpert
                            type="expert"
                            label={index === 0 ? 'Experts' : ''}
                            placeholder="Selecione o expert"
                            options={expertsOptions}
                            onChange={(event) => {
                              handleAddExpert(event, index)
                            }}
                            value={{
                              value: expert.value ? expert.value : '1',
                              label: expert.label ? expert.label : 'Selecione o expert'
                            }}
                          />
                          <InputSelect
                            type="expert"
                            label={''}
                            placeholder="Selecione o expert"
                            options={statusOptions}
                            onChange={(event) => editSelectedScheduleStatus(event, 'experts', index)}
                            value={{
                              value: selectedSchelude.experts[index]?.status
                                ? selectedSchelude.experts[index]?.status
                                : '1',
                              label: selectedSchelude.experts[index]?.status
                                ? ExpertStatusType[selectedSchelude.experts[index]?.status]
                                : 'Selecione o status'
                            }}
                          />
                        </div>
                        {index === 0 ? (
                          <button type="button" onClick={addExpert}>
                            <PlusCircle color="var(--gray-100)" size={22} />
                          </button>
                        ) : (
                          <button onClick={() => removeExpert(index)}>
                            <Trash color="var(--red)" size={22} />
                          </button>
                        )}
                      </ExpertsContainer>
                    ))
                  )}
                  <div>
                    <InputSelect
                      label="Facilitador"
                      placeholder="Selecione o facilitador"
                      options={facilitatorOptions}
                      onChange={(event) => editSelectedScheduleLive(event, 'facilitator')}
                      value={{
                        value: selectedSchelude?.facilitator?.value ? selectedSchelude?.facilitator?.value : '1',
                        label: selectedSchelude?.facilitator?.label
                          ? selectedSchelude?.facilitator?.label
                          : 'Selecione o facilitador'
                      }}
                    />
                    <InputSelect
                      type="expert"
                      label={''}
                      placeholder="Selecione o expert"
                      options={statusOptions}
                      onChange={(event) => editSelectedScheduleStatus(event, 'facilitator')}
                      value={{
                        value: selectedSchelude?.facilitator?.status ? selectedSchelude?.facilitator?.status : '1',
                        label: selectedSchelude?.facilitator?.status
                          ? ExpertStatusType[selectedSchelude?.facilitator?.status]
                          : 'Selecione o status'
                      }}
                    />
                  </div>
                  <AutosizeContainer>
                    <AutosizeInput
                      label="Link de Gravação"
                      type="text"
                      name="recordingUrl"
                      placeholder="Link de gravação"
                      value={selectedSchelude.recordingUrl}
                      defaultValue={selectedSchelude.recordingUrl}
                      onChange={(event) => editSelectedScheduleLive(event.target.value, 'recordingUrl')}
                      required
                    />
                  </AutosizeContainer>

                  <ZoomInputContainer>
                    <section>
                      <AutosizeInput
                        label="Link do Zoom"
                        type="text"
                        name="title"
                        placeholder="Link do Zoom"
                        value={selectedSchelude.url}
                        defaultValue={selectedSchelude.url}
                        onChange={(event) => editSelectedScheduleLive(event.target.value, 'url')}
                        required
                        width={154}
                      />
                    </section>
                    <ButtonContainer>
                      <button
                        // disabled={!selectedSchelude?.facilitator || !selectedSchelude?.facilitator?.status}
                        onClick={() => {
                          newLinkZoom()
                        }}
                      >
                        {newLinkText}
                      </button>
                    </ButtonContainer>
                  </ZoomInputContainer>

                  <br />
                  <ToggleSwitchContainer>
                    <h3>Status</h3>
                    <div>
                      <ToggleSwitch onClick={handleCheckDraftSchedule} />
                      <span>{draftLabelSchedule}</span>
                    </div>
                  </ToggleSwitchContainer>
                </AsideEdit>
              )}

              <FooterAside>
                <ButtonContainer>
                  {editSchedule ? (
                    <button
                      onClick={() => {
                        setEditSchedule(false)
                        setNewLinkText('Novo Link')
                      }}
                    >
                      Voltar
                    </button>
                  ) : (
                    <button onClick={() => setEditSchedule(true)}>Editar</button>
                  )}
                  {editSchedule && (
                    <Button
                      onClick={() => {
                        handleSchedule()
                        handleSendInvite()
                      }}
                    >
                      Salvar
                    </Button>
                  )}
                </ButtonContainer>
              </FooterAside>
            </AsideContent>
          ) : (
            <TextContainer>
              <p>Clique nos cards de agendamentos da live ao lado para visualizar mais detalhes aqui</p>
            </TextContainer>
          )}
        </AsideContainer>
      )}
    </Container>
  )
}
