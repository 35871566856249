import React, { forwardRef, InputHTMLAttributes } from 'react'

import { Label, CheckboxContainer, HiddenCheckbox, StyledCheckbox, Icon } from './styles'

interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  labelText?: string
}

export const Checkbox = forwardRef(({ labelText, checked, disabled, ...rest }: CheckboxProps) => {
  return (
    <Label>
      <CheckboxContainer>
        <HiddenCheckbox type="checkbox" disabled={disabled} checked={checked} {...rest} />
        <StyledCheckbox checked={checked} disabled={disabled}>
          <Icon />
        </StyledCheckbox>
      </CheckboxContainer>
      {labelText && <span>{labelText}</span>}
    </Label>
  )
})
