import React, { useCallback, useState } from 'react'

import { ConfirmationModal as RemoveBuildingBlockConfirmationModal } from 'core/components/ConfirmationModal'
import { UpdateModal as UpdateBuildingBlockConfirmationModal } from 'core/components/UpdateModal'
import { NoUpdateModal as NoUpdateBuildingBlockModal } from 'core/components/NoUpdateModal'

import { useGreenModal } from 'core/hooks/useGreenModal'

import { Container, RemoveBuildingBlockButton, UpdateBuildingBlockButton } from './styles'
import { useParams } from 'react-router-dom'
import { useBuildingBlock } from '../../hooks/useBuildingBlock'
import { findBlocksAfterToday, updateJourneyBlocksAfterToday } from '../../services/journey'

export const SettingsBuildingBlock = () => {
  const [isConfirmRemoveBuildingBlockModalOpen, setIsConfirmRemoveBuildingBlockModalOpen] = useState(false)
  const [isConfirmUpdateBuildingBlockModalOpen, setIsConfirmUpdateBuildingBlockModalOpen] = useState(false)
  const [isConfirmNoUpdateBuildingBlockModalOpen, setIsConfirmNoUpdateBuildingBlockModalOpen] = useState(false)
  const [messageUpdateBlockModal, setMessageUpdateBlockModal] = useState<any[]>([])

  const { blocktype, blockId } = useParams()
  const { prepareDataToSave } = useBuildingBlock()

  const { openGreenModal } = useGreenModal()

  const handleOpenConfirmRemoveBuildingBlockModal = () => {
    setIsConfirmRemoveBuildingBlockModalOpen(true)
  }
  const handleOpenConfirmUpdateBuildingBlockModal = async () => {
    const blocks = await findBlocksAfterToday(blockId)

    if (blocks && blocks.length > 0) {
      const teraIds = blocks.map((doc) => {
        return doc.teraId
      })

      setMessageUpdateBlockModal(teraIds)

      setIsConfirmUpdateBuildingBlockModalOpen(true)
    } else {
      setIsConfirmNoUpdateBuildingBlockModalOpen(true)
    }
  }

  const handleRemoveBuildingBlock = () => {
    setIsConfirmRemoveBuildingBlockModalOpen(false)
    openGreenModal('Building block não está mais disponível para uso em templates')
  }

  const handleCloseConfirmRemoveBuildingBlockModal = useCallback(() => {
    setIsConfirmRemoveBuildingBlockModalOpen(false)
  }, [])

  const handleCloseConfirmUpdateBuildingBlockModal = useCallback(() => {
    setIsConfirmUpdateBuildingBlockModalOpen(false)
  }, [])
  const handleUpdateBuildingBlock = async () => {
    setIsConfirmUpdateBuildingBlockModalOpen(false)

    const blockData = prepareDataToSave(blocktype)

    await updateJourneyBlocksAfterToday(blockId, blockData)

    openGreenModal('Building block atualizado em todos as turmas listadas')
  }

  const handleCloseConfirmNoUpdateBuildingBlockModal = useCallback(() => {
    setIsConfirmNoUpdateBuildingBlockModalOpen(false)
  }, [])

  return (
    <>
      <RemoveBuildingBlockConfirmationModal
        confirmButtonText="Sim, excluir"
        confirmButtonColor="var(--black)"
        cancelButtonText="Cancelar"
        messageType="YellowWarning"
        messageTitle="Tem certeza disso?"
        messageDescription="Esta ação pode impactar a operação dos cursos na Tera."
        onRequestClose={handleCloseConfirmRemoveBuildingBlockModal}
        isOpen={isConfirmRemoveBuildingBlockModalOpen}
        onCancel={handleCloseConfirmRemoveBuildingBlockModal}
        onConfirm={handleRemoveBuildingBlock}
      />

      <UpdateBuildingBlockConfirmationModal
        confirmButtonText="Sim, atualizar"
        confirmButtonColor="var(--black)"
        cancelButtonText="Não, voltar"
        messageType="YellowWarning"
        messageTitle="Deseja atualizar as turmas?"
        messageDescription="As turmas listadas a seguir serão atualizadas ao prosseguir com a ação:"
        complementMessageDescription={messageUpdateBlockModal}
        onRequestClose={handleCloseConfirmUpdateBuildingBlockModal}
        isOpen={isConfirmUpdateBuildingBlockModalOpen}
        onCancel={handleCloseConfirmUpdateBuildingBlockModal}
        onConfirm={handleUpdateBuildingBlock}
      />

      <NoUpdateBuildingBlockModal
        cancelButtonText="Fechar"
        messageType="BlackWarning"
        messageTitle="Não há jornadas para atualização"
        messageDescription="Este buildingblock não está sendo usado em nenhuma turma ativa, portanto nenhuma atualização foi necessária."
        isOpen={isConfirmNoUpdateBuildingBlockModalOpen}
        onCancel={handleCloseConfirmNoUpdateBuildingBlockModal}
      />
      <Container>
        <section>
          <h3> ATUALIZAR TURMAS </h3>
          <p> Atualizar aulas que ainda estão para acontecer, criadas a partir desse Building Block. </p>

          <UpdateBuildingBlockButton type="button" onClick={() => handleOpenConfirmUpdateBuildingBlockModal()}>
            atualizar
          </UpdateBuildingBlockButton>
          <hr />

          <h3> EXCLUIR BUILDING BLOCK </h3>
          <p>
            {' '}
            Esta ação irá <b>deletar permanentemente</b> o building block de jornadas, templates e de nosso banco de
            dados.{' '}
          </p>

          <RemoveBuildingBlockButton type="button" onClick={() => handleOpenConfirmRemoveBuildingBlockModal()}>
            excluir aula
          </RemoveBuildingBlockButton>
        </section>
      </Container>
    </>
  )
}
