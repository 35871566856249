import styled from 'styled-components'
import { Button } from 'core/components/Button'

export const PageHeader = styled.header`
  align-items: center;
  background: var(--white);
  border-bottom: 1px solid var(--gray-20);
  display: flex;
  min-height: 5rem;
  padding-top: 1rem;
  position: sticky;
  top: 3.75rem;
  z-index: 1;

  > div {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    width: 90%;
  }
`

export const Container = styled.main`
  margin: 2rem auto;
  width: 90%;

  > select {
    margin-bottom: 1rem;
  }
`

export const ButtonsPanel = styled.div`
  display: flex;
`

export const TitlePanel = styled.div`
  display: flex;
  flex-direction: column;

  h1 {
    font-size: 1.5rem;
  }

  h4 {
    font-size: 0.75rem;
    font-weight: normal;
    line-height: 1.25rem;
    text-transform: uppercase;

    a {
      color: var(--gray-60);
      text-decoration: none;
    }
  }
`

export const CancelButton = styled(Button)`
  background: var(--white);
  border: 2px solid var(--gray-80);
  height: 2.25rem;
  margin-right: 1rem;
  width: 7.5rem;
`

export const SaveButton = styled(Button)`
  color: var(--white);
  height: 2.25rem;
  width: 6rem;
`

export const FeelingLuckyContainer = styled.div`
  align-items: flex-start;
  background: var(--gray-10);
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 9.375rem;
  margin-bottom: 1.5rem;
  padding: 1.5rem;

  h3 {
    color: var(--black);
    font-weight: bold;
    font-size: 1rem;
    line-height: 1.25rem;
    text-transform: uppercase;
  }

  p {
    color: var(--gray-100);
    font-size: 1rem;
    line-height: 1.5rem;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  button {
    align-items: center;
    background: var(--black);
    border-radius: 0.25rem;
    color: var(--white);
    display: flex;
    font-size: 0.625rem;
    font-weight: bold;
    height: 1.5rem;
    justify-content: center;
    line-height: 0.625rem;
    text-transform: uppercase;
    width: 7.875rem;

    &:disabled {
      background: var(--black);
      color: var(--white);
    }

    svg {
      animation: spin 2s linear infinite;
      margin-left: 0.25rem;
    }
  }
`

export const FeelingLuckySucceedContainer = styled.div`
  align-items: center;
  background: var(--gray-10);
  border-radius: 0.25rem;
  display: flex;
  height: 4.375rem;
  justify-content: flex-start;
  margin-bottom: 1.5rem;
  padding: 1.5rem;
  position: relative;
  text-transform: uppercase;

  h1 {
    font-size: 1rem;
    font-weight: bold;
    line-height: 1.25rem;
    margin-left: 0.625rem;
  }

  button {
    background: transparent;
    border: 0;
    cursor: pointer;
    position: absolute;
    right: 1rem;
    top: 1rem;
    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.8);
    }
  }
`
