import styled, { css } from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`

interface SelectContainerProps {
  $width?: string
  isInvalid: boolean
  disabled: boolean
}
export const SelectContainer = styled.select<SelectContainerProps>`
  background: ${(props) => (props.disabled ? 'var(--gray-20)' : 'transparent')};
  border: 1.5px solid ${(props) => (props.disabled ? 'transparent' : 'var(--gray-80)')};
  color: ${(props) => (props.disabled ? 'var(--gray-60)' : 'var(--black)')};
  border-radius: 0.25rem;
  font-size: 0.875rem;
  height: 2rem;
  line-height: 1rem;
  min-height: 3rem;
  padding: 0 1rem 0 0.5rem;
  width: ${(props) => (props.$width ? props.$width : '')};
  ${(props) =>
    props.isInvalid &&
    css`
      border-color: var(--orange);
    `}
  &::placeholder {
    color: var(--gray-60);
  }
`

export const SelectInformationContainer = styled.div`
  align-items: baseline;
  display: flex;
  justify-content: space-between;

  label {
    color: var(--gray-60);
    display: block;
    font-size: 0.75rem;
    font-weight: bold;
    line-height: 1.25rem;
    text-transform: uppercase;
    margin: 1.5rem 0 0.25rem;
  }
`
export const Error = styled.p`
  color: var(--orange);
  font-size: 1rem;
  line-height: 1.25rem;
  margin-top: 0.5rem;
`
