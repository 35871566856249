import { BuildingBlock } from '@somostera/tera-database'

export interface State {
  draft: BuildingBlock
  isLoading: boolean
  teraId: string
  buildingblock: BuildingBlock[]
  error?: unknown
  selectedRows: BuildingBlock[]
  buildingBlockToUpdate: BuildingBlock[]
}

export type Action =
  | { type: 'ASYNC_CALL' }
  | { type: 'ASYNC_CALL_SUCCEED' }
  | { type: 'ASYNC_CALL_FAILED'; payload: { error: unknown } }
  | { type: 'SET_BUILDINGBLOCK'; payload: { buildingblock: BuildingBlock[] } }
  | { type: 'UPDATE_SELECTED_ROWS'; payload: { selectedRows: BuildingBlock[] } }
// | { type: 'UPDATE_CURRENT_CHAPTER_TO_BE_REMOVED'; payload: { index: BuildingBlock } }

export const buildingblockReducer = (draft: State, action: Action): State => {
  switch (action.type) {
    case 'ASYNC_CALL':
      draft.isLoading = true
      break
    case 'ASYNC_CALL_SUCCEED':
      draft.isLoading = false
      break
    case 'ASYNC_CALL_FAILED':
      draft.isLoading = false
      draft.error = action.payload.error
      break
    case 'SET_BUILDINGBLOCK':
      draft.buildingblock = action.payload.buildingblock
      break
    case 'UPDATE_SELECTED_ROWS':
      draft.selectedRows = action.payload.selectedRows // .map((row) => row.id ?? '')
      break
    // case 'UPDATE_CURRENT_CHAPTER_TO_BE_REMOVED':
    //   draft.buildingBlockToDelete = action.payload.index
    //   break
  }
  return draft
}
