import styled from 'styled-components'

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 1.5rem auto;
  margin-top: 5rem;
  width: 80%;

  h3 {
    color: var(--black);
    font-size: 1rem;
    line-height: 1.25rem;
    margin: 2rem 0 0.5rem;
    text-transform: uppercase;
  }

  p {
    color: var(--gray-80);
    font-size: 1rem;
    line-height: 1.5rem;
  }
`

export const Header = styled.header`
  width: 35rem;

  > div {
    align-items: center;
    display: flex;
    justify-content: space-between;

    > div {
      span {
        color: var(--gray-60);
        font-size: 0.75rem;
        line-height: 1.25rem;
        margin-bottom: 0.5rem;
        text-transform: uppercase;
      }

      h1 {
        color: var(--black);
        font-size: 1.5rem;
        line-height: 1.5rem;
        text-transform: uppercase;
      }
    }

    button {
      align-items: center;
      background: var(--blue-primary);
      border-radius: 0.25rem;
      color: var(--white);
      display: flex;
      font-size: 0.875rem;
      font-weight: bold;
      height: 2rem;
      justify-content: center;
      line-height: 120%;
      padding: 0.5rem 1rem;
      text-transform: uppercase;
      width: 5.4rem;

      &:disabled {
        background: var(--gray-20);
        cursor: not-allowed;
      }
    }
  }
`
export const Form = styled.form`
  > hr {
    background: var(--gray-20);
    height: 0.125rem;
    margin-top: 2.25rem;
    width: 35rem;
  }
`

export const Label = styled.label`
  color: var(--gray-60);
  display: block;
  font-size: 0.75rem;
  font-weight: bold;
  line-height: 1.25rem;
  text-transform: uppercase;
  margin: 1.5rem 0 0.25rem;
`

export const AddFilesPanel = styled.div`
  > button {
    align-items: center;
    background: var(--black);
    border-radius: 0.25rem;
    color: var(--white);
    display: flex;
    font-weight: bold;
    font-size: 0.875rem;
    height: 2rem;
    justify-content: space-between;
    line-height: 120%;
    margin-top: 1.5rem;
    padding: 0.5rem 1rem;
    text-transform: uppercase;
    width: 8rem;
  }

  .rdt_Table {
    > div {
      max-width: 35rem;
    }
  }
`

export const RemoveClassButton = styled.button`
  background: var(--red);
  border-radius: 0.5rem;
  color: var(--white);
  font-weight: bold;
  font-size: 1rem;
  height: 3rem;
  line-height: 1.25rem;
  margin-top: 1.5rem;
  text-transform: uppercase;
  width: 10.25rem;
`

export const RemoveMediaButton = styled.button`
  align-items: center;
  background: transparent;
  border-radius: 0.25rem;
  display: flex;
  justify-content: center;
  padding: 0.5rem;
  transition: opacity 0.2s;

  &:active {
    background: var(--gray-20);
  }

  &:hover {
    opacity: 0.7;
  }
`

export const NoMaterialsPanel = styled.div`
  align-items: center;
  background: var(--yellow-pastel);
  color: var(--black);
  display: flex;
  font-size: 1rem;
  height: 3.5rem;
  line-height: 1.5rem;
  padding: 1rem;
  width: 35rem;

  svg {
    margin-right: 0.625rem;
  }
`

export const LoadingContainer = styled.div`
  width: 80%;
  height: 70%;
  min-height: 10rem;
  margin-top: 4.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  background: rgba(255, 255, 255, 0.75);
  z-index: 4;
`
