import styled from 'styled-components'
import { NodeViewWrapper } from '@tiptap/react'

export const NodeContainer = styled(NodeViewWrapper)`
  display: flex;
  flex-direction: column;

  hr {
    border: 0;
    border-top: 2px solid var(--gray-20);
    border-bottom: none;
    width: 100%;
  }

  button {
    align-self: flex-end;
    background: var(--black);
    box-shadow: 0 6px 12px rgba(51, 51, 51, 0.25);
    border: 0;
    border-radius: 8px;
    color: var(--white);
    cursor: pointer;
    font-family: Rubik;
    font-size: 16px;
    font-weight: 700;
    height: 48px;
    line-height: 20px;
    margin-top: 16px;
    padding: 8px;
    text-transform: uppercase;
    width: 146px;

    &:hover {
      transform: translate(1px, 1px);
    }
  }
`
