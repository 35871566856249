import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 1.5rem auto;
  margin-top: 5rem;
  width: 80%;
  h1 {
    color: var(--black);
    font-size: 1rem;
    line-height: 1.25rem;
    text-transform: uppercase;
  }
  p {
    color: var(--black);
    font-size: 1rem;
    line-height: 1.5rem;
    text-transform: uppercase;
    font-weight: bold;
    margin: 2rem 0 0.5rem;
  }
`

export const Header = styled.header`
  width: 35rem;

  > div {
    span {
      color: var(--gray-60);
      font-size: 0.75rem;
      line-height: 1.25rem;
      margin-bottom: 0.5rem;
      text-transform: uppercase;
    }

    h1 {
      color: var(--black);
      font-size: 1.5rem;
      line-height: 1.5rem;
      margin-top: 0.5rem;
      text-transform: uppercase;
    }
  }
`
export const Form = styled.form`
  display: flex;
  flex-direction: column;

  > hr {
    background: var(--gray-20);
    height: 0.125rem;
    margin-top: 3.6rem;
  }

  button {
    background: var(--blue-primary);
    border-radius: 0.5rem;
    color: var(--white);
    font-weight: bold;
    font-size: 1rem;
    height: 3rem;
    line-height: 1.25rem;
    margin-top: 1.5rem;
    text-transform: uppercase;
    align-self: flex-end;
    border-radius: 0.5rem;
    box-shadow: 0px 6px 12px rgba(51, 51, 51, 0.25);
    width: 14rem;

    &:disabled {
      background: var(--gray-20);
      cursor: not-allowed;
    }
  }
`
export const HorizontalPanel = styled.div`
  display: flex;
  line-height: 1.5rem;
  color: var(--gray-80);
  font-size: 1rem;

  > div {
    margin-top: 0.25rem;
  }

  > div + div,
  > label + label {
    margin-left: 1.5rem;
    color: var(--gray-100);
  }
`

export const Label = styled.label`
  color: var(--gray-60);
  display: block;
  font-size: 0.75rem;
  font-weight: bold;
  line-height: 1rem;
  text-transform: uppercase;
  margin: 1.5rem 0 0.25rem;
  height: 1.5rem;
`
