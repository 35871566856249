import styled from 'styled-components'

interface ColouredCardProps {
  background: string
  foreground: string
}

export const Container = styled.div<ColouredCardProps>`
  ol {
    li {
      background: ${(props) => `var(--${props.background})`};
      color: ${(props) => `var(--${props.foreground})`};
      line-height: 1.5rem;
      list-style-position: inside;
      margin: 1rem 0;
      padding: 1rem;

      & + li {
        margin-top: 1rem;
      }

      &::marker {
        font-weight: bold;
      }

      p {
        display: inline;
      }
    }

    &.white-list {
      counter-reset: list-counter;
      list-style: none;
      margin: 0;
      padding: 0;

      & + p {
        margin-top: 24px;
      }

      li {
        counter-increment: list-counter;
        display: flex;
        margin-top: 16px;

        &:first-child {
          margin-top: 0;
        }

        &:nth-child(n + 10)::before {
          content: counter(list-counter);
        }

        &::before {
          content: '0' counter(list-counter);
          font-size: 32px;
          font-family: 'Rubik';
          font-weight: bold;
          letter-spacing: -2%;
          line-height: 32px;
          margin-right: 8px;
          min-width: 48px;
        }

        > p {
          margin: 0;
        }
      }
    }
  }
`
