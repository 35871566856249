import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const Container = styled(Link)`
  display: flex;
  flex: 1;
  height: 100%;
  align-items: center;
  text-decoration: none;
  color: var(--gray-80);
  cursor: pointer;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;

  display: flex;
  align-items: center;

  color: #171717;
`
