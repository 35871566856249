import axios from 'axios'

interface Course {
  name: string | undefined
  description: string
  career: string[] | undefined
  availableForSchool: string[] | undefined
  visibility: string
}

const getUrl = `${process.env.REACT_APP_TERA_API}/courses`
export abstract class CoursesService {
  static async getAll(token: string) {
    return await axios.get(getUrl, {
      headers: { __session: token, 'Access-Control-Allow-Origin': '*' }
    })
  }

  static async saveCourse(token: string, data: Course) {
    return await axios.post(getUrl, data, {
      headers: { __session: token, 'Access-Control-Allow-Origin': '*' }
    })
  }

  static async updateCourse(token: string, courseId: string, data: Course) {
    return await axios.put(`${getUrl}/${courseId}`, data, {
      headers: { __session: token, 'Access-Control-Allow-Origin': '*' }
    })
  }

  static async getFullCourseById(token: string, courseId: string) {
    return await axios.get(`${getUrl}/${courseId}/full`, {
      headers: { __session: token, 'Access-Control-Allow-Origin': '*' }
    })
  }

  static async getCourseById(token: string, courseId: string) {
    return await axios.get(`${getUrl}/${courseId}`, {
      headers: { __session: token, 'Access-Control-Allow-Origin': '*' }
    })
  }

  static async getCourseItemById(token: string, courseItemId: string) {
    return await axios.get(`${getUrl}/${courseItemId}/items`, {
      headers: { __session: token, 'Access-Control-Allow-Origin': '*' }
    })
  }

  static async getArticleById(token: string, articleRef: string) {
    return await axios.get(`${process.env.REACT_APP_TERA_API}/${articleRef}`, {
      headers: { __session: token, 'Access-Control-Allow-Origin': '*' }
    })
  }

  static async getArticles(token: string) {
    return await axios.get(`${process.env.REACT_APP_TERA_API}/articles`, {
      headers: { __session: token, 'Access-Control-Allow-Origin': '*' }
    })
  }

  static async saveCourseItems(token: string, courseId: string, data: []) {
    return await axios.post(`${getUrl}/${courseId}/items`, data, {
      headers: { __session: token, 'Access-Control-Allow-Origin': '*' }
    })
  }
}
