import React, { createContext, ReactNode, useContext, useState } from 'react'

interface CourseContextData {
  currentCourseName: string
  updateCurrentCourseName: (courseName: string) => void
}

interface CourseProviderProps {
  children: ReactNode
}

const CourseContext = createContext<CourseContextData>({} as CourseContextData)

export const CourseProvider = ({ children }: CourseProviderProps) => {
  const [currentCourseName, setCurrentCourseName] = useState('')

  const updateCurrentCourseName = (courseName: string) => {
    setCurrentCourseName(courseName)
  }

  return (
    <CourseContext.Provider
      value={{
        currentCourseName,
        updateCurrentCourseName
      }}
    >
      {children}
    </CourseContext.Provider>
  )
}

export const useCourse = (): CourseContextData => {
  return useContext(CourseContext)
}
