import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { ArticleDraft, CareersType } from '@somostera/tera-database'

import { useContent } from 'modules/contents/hooks/useContent'
import { findArticleById, updateArticle, updateArticleDraft } from 'modules/contents/services/article'

import { getDateInformation, monthNames } from 'core/utils/date'

import { AutosizeInput } from 'modules/contents/components/datasheet/AutosizeInput'
import { TagsInput } from 'modules/contents/components/datasheet/TagsInput'
import { Input } from 'modules/contents/components/datasheet/Input'
import { MultiSelect } from 'modules/contents/components/datasheet/MultiSelect'
import { InputSelect } from 'modules/contents/components/datasheet/Select'
import { ListInput } from 'modules/contents/components/datasheet/ListInput'
import ToggleSwitch from 'modules/contents/components/datasheet/ToggleSwitch'

import { PlusCircle, Trash } from '@somostera/tera-icons'
import Pen from 'core/components/Icon/Pen'
import { Loading } from 'core/components/Animation/Loading'

import {
  EditContainer,
  Footer,
  HeaderContainer,
  Button,
  ToggleSwitchContainer,
  LoadingContainer,
  ExpertsContainer
} from './styles'
import { ArticleVisibility } from '@somostera/tera-database/dist/Articles/Domain/enum/ArticleVisibility'
import { ArticleType } from '@somostera/tera-database/dist/Articles/Domain/enum/ArticleType'
interface FacilitatorData {
  value: string
  label: string
}
interface ExpertData {
  value: string
  label: string
}

const ProductType = {
  CLASS: 'Aula Digital',
  REPLAY: 'Replay',
  BRANDEDCHALLENGE: 'Desafio de Mercado',
  CHALLENGE: 'Desafio Tera',
  TUTORIAL: 'Projeto/tutorial',
  CASE: 'Estudo de Caso',
  ARTICLE: 'Artigo',
  PODCAST: 'Podcast',
  NARRATIVE: 'Narrativa da Jornada',
  INTERNAL: 'Interno',
  DEBATE: 'Debate (ou talk)'
}

const AvailableForSchool = {
  PRODUCT: 'Plataforma',
  SOLAR: 'Solar'
}

const Levels = {
  BEGINNER: 'Iniciante',
  INTERMEDIATE: 'Intermediário',
  ADVANCED: 'Avançado'
}

export function DataSheetEdit() {
  const navigate = useNavigate()
  const { articleId } = useParams()

  const {
    updateCurrentArticleDraftName,
    getExpertsNameByParticipantsIds,
    getFacilitatorNameByFacilitatorIds,
    allPeopleData,
    allValidExperts,
    allValidFacilitators
  } = useContent()

  const [selectableExperts, setSelectableExperts] = useState<ExpertData[]>([])
  const [selectedsExperts, setSelectedsExperts] = useState<ExpertData[]>([{ value: '1', label: 'Selecione o expert' }])

  const [currentFacilitators, setCurrentFacilitators] = useState<FacilitatorData>({
    value: '1',
    label: 'Selecione o facilitador'
  })

  const [currentArticleInfo, setCurrentArticleInfo] = useState<ArticleDraft>({} as ArticleDraft)
  const [currentArticleDraft, setCurrentArticleDraft] = useState<ArticleDraft>({} as ArticleDraft)

  const [isLoading, setIsLoading] = useState(true)

  const [dateToShow, setDateToShow] = useState<Date | null>(null)

  const [title, setTitle] = useState<string | undefined>('')
  const [outcomes, setOutcomes] = useState<string[]>([])
  const [description, setDescription] = useState('')
  const [careers, setCareers] = useState<string[] | undefined>([])
  const [schools, setSchools] = useState<string[] | undefined>([])
  const [levels, setLevels] = useState<string[] | undefined>([])
  const [type, setType] = useState<string | undefined>('')
  const [driveLink, setDriveLink] = useState<string | null>(null)
  const [videoLink, setVideoLink] = useState<string | null>(null)
  const [keywords, setKeywords] = useState<string[] | undefined>([])
  const [tools, setTools] = useState<string[] | undefined>([])
  const [isChecked, setIsChecked] = useState(false)
  const [isVisible, setIsVisible] = useState('')
  const [publishedUpdatedAt, setPublishedUpdatedAt] = useState()

  const careersOptions = Object.keys(CareersType).map((key) => ({
    value: key,
    label: CareersType[key]
  }))
  const productOptions = Object.keys(ProductType).map((key) => ({
    value: key,
    label: ProductType[key]
  }))
  const schoolsOptions = Object.keys(AvailableForSchool).map((key) => ({
    value: key,
    label: AvailableForSchool[key]
  }))
  const levelsOptions = Object.keys(Levels).map((key) => ({
    value: key,
    label: Levels[key]
  }))

  let dateText = ''

  if (dateToShow) {
    const { day, hours, minutes, month, year } = getDateInformation(dateToShow)
    dateText = `ÚLTIMA EDIÇÃO SALVA EM ${day} de ${monthNames[month]} DE ${year} ÀS ${hours}H${minutes}`
  }

  useEffect(() => {
    async function getExperts() {
      if (articleId) {
        const articleInfo = await findArticleById(articleId, true)
        const articleDraft = await findArticleById(articleId, false)

        console.log(articleInfo)

        setCurrentArticleInfo(articleInfo)
        setCurrentArticleDraft(articleDraft)
        setTitle(articleInfo.name)
        setOutcomes(articleInfo.editorial?.outcomes || [])
        setDescription(articleInfo.description)
        setSchools(articleInfo?.availableForSchool || [])
        setLevels(articleInfo?.level || [])
        setCareers(articleInfo.editorial?.careers || [])
        setDriveLink(articleInfo.editorial?.driveLink)
        setVideoLink(articleInfo.editorial?.videoLink)
        setType(
          articleInfo.type?.toUpperCase().replaceAll('-', '') || articleDraft.type?.toUpperCase().replaceAll('-', '')
        )
        setKeywords(articleInfo.editorial?.keywords || [])
        setTools(articleInfo?.tools || [])
        setDateToShow(articleInfo.updatedAt)
        setIsChecked(articleInfo.visibility === 'public')
        setPublishedUpdatedAt(articleInfo.updatedAt)

        const expertNames = getExpertsNameByParticipantsIds(articleInfo.participantsIds)

        const selectExperts = expertNames.map((expert) => ({
          value: expert.id,
          label: expert.name
        }))

        const facilitatorsName = getFacilitatorNameByFacilitatorIds(articleInfo.facilitatorsIds)

        setSelectedsExperts(selectExperts)
        setCurrentFacilitators(facilitatorsName)
        updateCurrentArticleDraftName(articleInfo.name)
      }
    }

    getExperts()
  }, [articleId, getExpertsNameByParticipantsIds, getFacilitatorNameByFacilitatorIds])

  useEffect(() => {
    if (allValidExperts) {
      const selectableExperts = allValidExperts.map((expert) => ({
        value: expert.value,
        label: expert.name
      }))

      setSelectableExperts(selectableExperts)
      setIsLoading(false)
    }
  }, [allValidExperts])

  useEffect(() => {
    isChecked ? setIsVisible('Público') : setIsVisible('Privado')
  }, [isChecked])

  async function handleSave() {
    setIsLoading(true)

    const validExperts = selectedsExperts.filter((expert) => expert.value !== '1')

    setSelectedsExperts(validExperts)

    const newExperts = validExperts.map((expert) => expert.value)

    delete currentArticleInfo.updatedAt

    const newArticleInfo: ArticleDraft = {
      ...currentArticleInfo,
      name: title,
      participantsIds: newExperts,
      availableForSchool: schools.map((school) => school.value || school),
      facilitatorsIds: 'value' in currentFacilitators ? [currentFacilitators.value] : undefined,
      description,
      visibility: isChecked ? ArticleVisibility.PUBLIC : ArticleVisibility.PRIVATE,
      tools,
      level: levels.map((level) => level.value || level),
      updatedAt: publishedUpdatedAt,
      editorial: {
        outcomes,
        keywords,
        careers: careers.map((career) => career.value || career)
      }
    }

    if (type?.value) newArticleInfo.type = ArticleType[type.value].replace('-', '_').toUpperCase()
    if (driveLink) newArticleInfo.editorial.driveLink = driveLink
    if (videoLink) newArticleInfo.editorial.videoLink = videoLink

    try {
      articleId && (await updateArticle(articleId, newArticleInfo))
      articleId && (await updateArticleDraft(articleId, newArticleInfo))

      updateCurrentArticleDraftName(title)
      navigate(`/contents/${articleId}/datasheet`, {
        state: { currentArticleDraft: newArticleInfo }
      })
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  async function handleCancel() {
    try {
      navigate(`/contents/${articleId}/datasheet`, {
        state: { currentArticleDraft }
      })
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  function addExpert() {
    setSelectedsExperts([...selectedsExperts, { value: '1', label: 'Selecione o expert' }])
  }

  function removeExpert(index: number) {
    const newSelectedsExperts = [...selectedsExperts]
    newSelectedsExperts.splice(index, 1)
    setSelectedsExperts(newSelectedsExperts)
  }

  function handleAddExpert(expert: ExpertData, index: number) {
    const expertData = allPeopleData.find((personData) => personData.id === expert.value)

    const newSelectedsExperts = [...selectedsExperts]

    newSelectedsExperts[index] = {
      value: expertData?.id || '1',
      label: expertData?.name || ''
    }

    setSelectedsExperts(newSelectedsExperts)
  }

  const handleCheck = () => {
    setIsChecked(!isChecked)
  }

  if (isLoading) {
    return (
      <LoadingContainer>
        <Loading />
      </LoadingContainer>
    )
  }

  return (
    <>
      <HeaderContainer>
        <div>
          <h2>{currentArticleInfo.name}</h2>
          <h3>{dateText}</h3>
        </div>
        <Button disabled>
          <Pen />
          Editar
        </Button>
      </HeaderContainer>
      <EditContainer>
        <main>
          <h3>Especificações</h3>

          <Input
            type="text"
            name="title"
            label="TÍTULO DO CONTEÚDO"
            placeholder="Escreva o título do conteúdo"
            value={title}
            onChange={(event: string) => setTitle(event.target.value)}
            required
          />

          {!selectedsExperts?.length && (
            <InputSelect
              type="expert"
              index={0}
              label="Expert"
              placeholder="Selecione o expert"
              options={[{ value: '1', label: 'Selecione o expert' }, ...selectableExperts]}
              onChange={handleAddExpert}
              value={selectedsExperts[0]}
            />
          )}

          {selectedsExperts.map((expert, index) => (
            <ExpertsContainer key={index}>
              <div>
                <InputSelect
                  type="expert"
                  index={index}
                  label={index === 0 ? 'Experts' : ' '}
                  placeholder="Selecione o expert"
                  options={[{ value: '1', label: 'Selecione o expert' }, ...selectableExperts]}
                  onChange={handleAddExpert}
                  value={expert}
                />
              </div>

              {index === 0 ? (
                <button onClick={addExpert}>
                  <PlusCircle color="var(--gray-100)" size={22} />
                </button>
              ) : (
                <button onClick={() => removeExpert(index)}>
                  <Trash color="var(--red)" size={22} />
                </button>
              )}
            </ExpertsContainer>
          ))}

          <InputSelect
            label="Facilitador"
            placeholder="Selecione o facilitador"
            options={[{ value: '1', label: 'Selecione o facilitador' }, ...allValidFacilitators]}
            onChange={setCurrentFacilitators}
            value={currentFacilitators}
          />

          <ListInput
            name="outcomes"
            label="Objetivos de Aprendizagem"
            placeholder="Escreva em tópicos os objetivos de aprendizagem do conteúdo"
            value={outcomes}
            onTagsChange={(tags: string[]) => setOutcomes([...tags])}
            type="tag"
            required
          />

          <AutosizeInput
            type="text"
            name="description"
            label="Descrição"
            placeholder="Descrição breve do que é este conteúdo"
            value={description}
            minHeight={'6.75rem'}
            onChange={(event: string) => setDescription(event.target.value)}
            required
          />

          <MultiSelect
            options={careersOptions}
            onChange={setCareers}
            value={careers?.map((career) => ({
              value: career?.value || career,
              label: career?.label || CareersType[career]
            }))}
            label="Carreiras"
            required
          />

          <MultiSelect
            options={schoolsOptions}
            onChange={setSchools}
            value={schools?.map((school) => ({
              value: school?.value || school,
              label: school?.label || AvailableForSchool[school]
            }))}
            label="Escola"
            required
          />

          <MultiSelect
            options={levelsOptions}
            onChange={setLevels}
            value={levels?.map((level) => ({
              value: level?.value || level,
              label: level?.label || Levels[level]
            }))}
            label="Nível"
            required
          />

          <TagsInput
            name="keywords"
            label="Tópicos"
            placeholder="Palavras-chaves relacionadas ao conteúdo (conceitos, ferramentas, etc)"
            value={keywords}
            onTagsChange={(tags: string[]) => setKeywords([...tags])}
            type="tag"
            required
          />

          <TagsInput
            name="tools"
            label="Ferramentas"
            placeholder="Ferramentas relacionadas ao conteúdo (chatGPT, Zapier, etc)"
            value={tools}
            onTagsChange={(tags: string[]) => setTools([...tags])}
            type="tag"
            required
          />

          <InputSelect
            label="Tipos de Conteúdo"
            placeholder="Selecione quais os tipos de conteúdo"
            options={[{ value: '1', label: 'Selecione quais os tipos de conteúdo' }, ...productOptions]}
            onChange={(event) => {
              console.log(event)
              setType(event)
            }}
            value={{
              value: type?.value || type,
              label: type?.value ? ProductType[type?.value] : ProductType[type]
            }}
          />

          <ToggleSwitchContainer>
            <h3>Visibilidade</h3>
            <div>
              <ToggleSwitch onClick={handleCheck} />
              <span>{isVisible}</span>
            </div>
          </ToggleSwitchContainer>
        </main>
        <section>
          <h3>Materiais Utilizados</h3>

          <Input
            type="text"
            name="driveVideo"
            label="link da pasta do drive"
            placeholder="Inclua o link do Google Drive"
            value={driveLink}
            onChange={(event: string) => setDriveLink(event.target.value)}
          />

          <Input
            type="text"
            name="description"
            label="Link do vídeo"
            placeholder="Inclua o link da pasta com os vídeos"
            value={videoLink}
            onChange={(event: string) => setVideoLink(event.target.value)}
          />
        </section>
        <Footer>
          <button onClick={handleCancel}>Cancelar</button>
          <button onClick={handleSave}>Salvar</button>
        </Footer>
      </EditContainer>
    </>
  )
}
