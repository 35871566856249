import React from 'react'

import { Chip, Container } from './styles'

interface NameCellProps {
  roles: string[]
}

export const RolesCell = ({ roles }: NameCellProps) => {
  const rolesDictionary = {
    expert: 'Expert',
    facilitator: 'Facilitadora',
    manager: 'Gerente',
    tutor: 'Tutoria',
    mentor: 'Mentora'
  } as { [key: string]: string }

  return (
    <Container>
      {roles.map((role) => {
        if (rolesDictionary[role]) {
          return (
            <Chip key={role} role={role}>
              {rolesDictionary[role]}
            </Chip>
          )
        } else {
          return null
        }
      })}
    </Container>
  )
}
