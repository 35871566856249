import React, { forwardRef, InputHTMLAttributes } from 'react'

import { Label, Icon, RoundCheckboxContainer, HiddenRoundCheckbox, StyledRoundCheckbox } from './styles'

interface RoundCheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  labelText?: string
}

export const RoundCheckbox = forwardRef(({ labelText, checked, ...rest }: RoundCheckboxProps) => {
  return (
    <Label>
      <RoundCheckboxContainer>
        <HiddenRoundCheckbox type="checkbox" checked={checked} {...rest} />
        <StyledRoundCheckbox checked={checked}>
          <Icon />
        </StyledRoundCheckbox>
      </RoundCheckboxContainer>
      {labelText && <span>{labelText}</span>}
    </Label>
  )
})
