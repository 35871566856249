import styled from 'styled-components'

export const MenuType = styled.div`
  background: var(--white);
  box-shadow: 0.1875rem 0.1875rem 0.5rem rgba(0, 0, 0, 0.16);
  width: 15.125rem;
  text-align: initial;
  display: inline-block;
  text-decoration: none;
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.16);

  .title {
    border-bottom: 1px solid var(--gray-20);
    > h2 {
      color: var(--gray-60);
      margin: 0.8rem;
      font-size: 0.89rem;
      text-transform: uppercase;
    }
  }

  .options {
    padding: 0.5rem 0;
  }

  .options p {
    display: flex;
    flex: 1;
  }

  .options p a {
    color: var(--gray-100);
    font-size: 0.95rem;
    margin: 0;
    text-decoration: none;
    display: flex;
    flex: 1;
    padding: 0.5rem 1.5rem;
  }

  .options p:hover {
    color: var(--gray-100);
    background-color: var(--blue-pastel);
  }
`
