import React, { useState, useEffect } from 'react'
import { Tera, Camera } from '@somostera/tera-icons'
import { useAuth } from '../../hooks/useAuth'

import { sidebarData } from './data'
import { SubMenu } from './SubMenu'

import { SidebarHeader, SidebarNav, SidebarWrap, SidebarFooter, ProfileImage, ProfileData } from './styles'
import OptionsVertical from '../Icon/OptionsVertical'

export const Sidebar = () => {
  const { userInfoTera, googleSignOut } = useAuth()
  const [selectedMenuItem, setSelectedMenuItem] = useState('Home')
  const [username, setUsername] = useState('')

  function formattedName(name: string) {
    const nameToShow = name.split(' ').slice(0, 2).join(' ')
    setUsername(nameToShow)
  }

  useEffect(() => {
    if (userInfoTera.name) formattedName(userInfoTera.name)
  }, [userInfoTera])

  return (
    <SidebarNav isSidebarOpen={true}>
      <SidebarWrap>
        {sidebarData.map((menuItem, index) => (
          <SubMenu
            key={index}
            item={menuItem}
            selectedMenuItem={selectedMenuItem}
            onMenuItemSelected={(itemTitle) => setSelectedMenuItem(itemTitle)}
          />
        ))}
        <SidebarFooter>
          <>
            <ProfileImage>
              {userInfoTera.pictureUrl ? (
                <img src={userInfoTera.pictureUrl} alt={userInfoTera.name} />
              ) : (
                <p>{userInfoTera.name?.charAt(0)}</p>
              )}
            </ProfileImage>
            <ProfileData>
              {/* <p>{formattadedName(userInfoTera.name)}</p> */}
              <p>{username}</p>
              {userInfoTera.jobTitle ? <p>{userInfoTera.jobTitle}</p> : <p>Tera</p>}
            </ProfileData>
            <div onClick={googleSignOut}>
              <OptionsVertical />
            </div>
          </>
        </SidebarFooter>
      </SidebarWrap>
    </SidebarNav>
  )
}
