import styled from 'styled-components'
import { Tabs } from 'react-tabs'

export const PageHeader = styled.header`
  display: flex;
  padding: 0 10rem;
  width: 80%;
  margin-top: 3.75rem;
`

export const ProfileContainer = styled.div`
  display: flex;
  margin: 1.5rem 0;
  margin-top: 5rem;

  label {
    background-color: var(--gray-20);
    border-radius: 50%;
    height: 5rem;
    width: 5rem;
    overflow: hidden;
    position: relative;
  }

  label span input {
    z-index: 999;
    line-height: 0;
    font-size: 50px;
    position: absolute;
    top: -2px;
    left: -700px;
    opacity: 0;
    filter: alpha(opacity = 0);
    -ms-filter: 'alpha(opacity=0)';
    cursor: pointer;
    _cursor: hand;
    margin: 0;
    padding: 0;
  }

  > p {
    color: var(--gray-80);
    font-size: 1rem;
    line-height: 1.5rem;

    & + p {
      margin-top: 1.5rem;
    }
  }
`

export const ProfilePictureInput = styled.div`
  align-self: center;
  position: relative;

  img {
    border-radius: 50%;
    height: 4rem;
    width: 4rem;
  }

  label {
    align-items: center;
    justify-content: center;
    background: none;
    border: 0;
    border-radius: 50%;
    bottom: 0;
    cursor: pointer;
    display: flex;
    height: 4rem;
    width: 4rem;

    &:hover {
      opacity: 0.9;
    }

    input {
      display: none;
    }
  }
`

export const ProfileData = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-left: 1rem;

  h4 {
    color: var(--black);
    font-weight: 300;
    font-size: 0.875rem;
    line-height: 1.25rem;
    text-transform: uppercase;
  }

  p {
    color: var(--black);
    font-size: 2rem;
    font-weight: bold;
    line-height: 2rem;
    letter-spacing: -0.02em;
    text-transform: uppercase;
  }

  span {
    color: var(--gray-80);
    font-size: 0.875rem;
    line-height: 130%;
  }
`
export const CustomTabs = styled(Tabs)`
  > ul {
    border-bottom: 1px solid var(--gray-20);
    display: flex;
    /* list-style: none; */
    padding: 0 10rem;

    > li a {
      flex: 1;
      text-decoration: none;
      color: var(--gray-60);
    }

    > li {
      background: var(--white);
      border-bottom: 0.25rem solid transparent;
      color: var(--gray-60);

      font-size: 1rem;
      height: 1.75rem;
      min-width: 3rem;
      text-transform: uppercase;
      width: 11rem;
      text-align: center;
      display: flex;

      &.react-tabs__tab--selected {
        border-bottom: 0.25rem solid var(--black);
        color: var(--black);
        font-weight: bold;
        padding-bottom: 0.25rem;

        > a {
          color: var(--black);
        }
      }
    }
  }
`
export const ProfilePicture = styled.img`
  width: 100%;
  object-fit: cover;
  height: 100%;
  align-items: center;
`
export const LoadingContainer = styled.div`
  width: 100%;
  height: 100%;
  min-height: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  background: rgba(255, 255, 255, 0.75);
  z-index: 4;
`
export const Container = styled.div`
  margin: 2rem auto 0;
  width: 90%;
  position: relative;

  h1 {
    margin-bottom: 1rem;
    text-transform: uppercase;
  }

  a {
    color: var(--gray-80);
  }
`
