import styled from 'styled-components'

export const Form = styled.form``

export const FindZipCodePanel = styled.div`
  align-items: center;
  display: flex;
  margin-top: 0.25rem;

  a {
    color: var(--blue-primary);
    font-size: 0.875rem;
    line-height: 1.25rem;
    margin-left: 0.5rem;
    text-decoration-line: underline;
  }
`

export const HorizontalPanel = styled.div`
  display: flex;

  > div {
    margin-top: 0.25rem;
  }

  > div + div,
  > label + label {
    margin-left: 1rem;
  }
`

interface NextButtonProps {
  width: string
}

export const SaveButton = styled.button<NextButtonProps>`
  background: var(--black);
  border-radius: 0.25rem;
  color: var(--white);
  font-size: 0.875rem;
  font-weight: bold;
  height: 2rem;
  line-height: 120%;
  margin-top: 1.5rem;
  text-transform: uppercase;
  width: ${(props) => props.width};
`
interface SearchAddressContainerProps {
  status: string
}

export const SearchAddressContainer = styled.div<SearchAddressContainerProps>`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin-top: 2.625rem !important;

  p {
    color: ${({ status }) =>
      status === 'loadingAdress'
        ? 'var(--gray-60)'
        : status === 'verifiedAdress'
        ? 'var(--green-60)'
        : status === 'notFoundedAddress'
        ? 'var(--yellow)'
        : 'var(--gray-80)'};
    font-size: 0.875rem;
    line-height: 1.125rem;
    margin-left: 0.5rem;
  }
`
