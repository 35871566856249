import styled, { css } from 'styled-components'

export const InputInformationContainer = styled.div`
  align-items: baseline;
  display: flex;
  gap: 0.25rem;
  margin-top: 1rem;

  label {
    font-family: 'Rubik';
    color: '#000';
    display: block;
    font-size: 0.75rem;
    font-weight: bold;
    line-height: 0.75rem;
    text-transform: uppercase;
    margin-bottom: 0.5rem;
    letter-spacing: 0.01;
  }

  span {
    color: #cc3314;
    font-weight: 700;
    font-size: 0.75rem;
    line-height: 0.75rem;
  }

  p {
    color: var(--gray-80);
    font-weight: 300;
    font-size: 1rem;
    line-height: 1.25rem;
  }
`

export const Description = styled.p`
  color: var(--gray-60);
  margin-bottom: 0.3rem;
  font-size: 0.875rem;
`

interface ContainerProps {
  isFocused: boolean
  isInvalid: boolean
}

export const Container = styled.div<ContainerProps>`
  border: 1px solid #dbdbdb;
  display: flex;
  min-height: 76px;
  padding: 12px 16px;
  position: relative;
  border-radius: 0.125rem;
  align-items: flex-start;
  background: #fcfcfc;

  ${(props) =>
    props.isInvalid &&
    css`
      border-color: var(--orange);
    `}

  ${(props) =>
    props.isFocused &&
    css`
      border-color: #3af221;
    `}

  ul {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 8px;

    li {
      font-size: 14px;
      align-items: flex-start;

      display: flex;
      width: 100%;

      span {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
      }

      button {
        background: transparent;
        border-radius: 50%;
        color: var(--gray-20);
        cursor: pointer;
        width: 1.125rem;

        display: flex;
        align-items: center;

        svg {
          width: 100%;
        }

        & + button {
          margin-left: 0.5rem;
        }

        &:hover {
          color: var(--gray-20);
          opacity: 0.8;
        }
      }

      &:last-child {
        background: transparent;
        border-radius: 0;
        flex: 1;
        min-width: 10rem;

        textarea {
          background: transparent;
          font-size: 0.875rem;
          line-height: 1.313rem;
          border: none;
          outline: 0;
          overflow-y: hidden;
          position: relative;
          resize: none;
          width: 100%;
          color: #292929;
          font-weight: 400;
          font-family: 'Inter';
          width: 100%;
          min-height: 100px;
          height: 100%;

          &::placeholder {
            color: #707070;
            font-size: 16px;
          }
        }
      }
    }
  }

  > button {
    background: transparent;
    border-radius: 50%;
    height: 1.5rem;
    position: absolute;
    right: 0.5rem;
    top: calc(50% - 0.75rem);
    width: 1.5rem;

    &:hover {
      opacity: 0.8;
    }
  }
`

const colors = {
  1: 'var(--green-pastel)',
  2: 'var(--blue-pastel)',
  3: 'var(--yellow-pastel)',
  4: 'var(--purple-pastel)'
} as { [key: string]: string }

interface TagProps {
  tagKey?: number
  type: string
}

export const Tag = styled.li<TagProps>`
  display: flex;
  gap: 8px;
  word-break: break-all;
  display: red;
`

export const Error = styled.p`
  color: var(--orange);
  font-size: 1rem;
  line-height: 1.25rem;
  margin-top: 0.5rem;
`
