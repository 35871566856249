import React, { useRef, useState } from 'react'
import ReactSelect, { components, IndicatorProps, OptionProps } from 'react-select'
import { ChevronDown } from '@somostera/tera-icons'

import { Tooltip } from '../Tooltip'

import { Container, OpenSelectButton, OptionContainer } from './styles'

export interface MenuOption {
  label: string
  description: string
  value: string
  action: () => boolean
}

export interface GroupOption {
  label: string
  description?: string
  options: MenuOption[]
}

export interface DropdownMenuProps {
  label: string
  tooltipTitle?: string
  tooltipDescription?: string
  groups?: GroupOption[]
  options?: MenuOption[]
  width?: number | string
  isDisabled?: boolean
}

const CustomOption = (props: OptionProps<MenuOption, false, GroupOption>) => {
  const { label, description } = props.data
  return (
    <components.Option {...props}>
      <OptionContainer>
        <p>{label}</p>
        <span>{description}</span>
      </OptionContainer>
    </components.Option>
  )
}

const DropdownIndicator = (props: IndicatorProps<MenuOption, false, GroupOption>) => (
  <components.DropdownIndicator {...props}>
    <ChevronDown color="var(--gray-60)" size={16} />
  </components.DropdownIndicator>
)

export function DropdownMenu({
  groups = [],
  width = '4rem',
  label,
  tooltipTitle,
  tooltipDescription,
  isDisabled = false
}: DropdownMenuProps) {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const menuRef = useRef<ReactSelect<MenuOption, false, GroupOption>>(null)

  const handleToggleMenu = () => {
    setIsMenuOpen(!isMenuOpen)
    if (menuRef?.current) {
      return !isMenuOpen ? menuRef.current?.focus() : menuRef.current.blur()
    }
  }

  const handleMenuOption = (option: MenuOption) => {
    if (option) {
      option.action()
    }
    setIsMenuOpen(false)
  }

  if (!groups.length) {
    return <div />
  }

  return (
    <Container width={`${width}`}>
      <Tooltip title={tooltipTitle || ''} description={tooltipDescription || ''}>
        <OpenSelectButton disabled={isDisabled} type="button" onClick={handleToggleMenu} width={`${width}`}>
          {label} <ChevronDown color="var(--gray-60)" size={16} />
        </OpenSelectButton>
      </Tooltip>

      <ReactSelect<MenuOption, false, GroupOption>
        ref={menuRef}
        menuIsOpen={isMenuOpen}
        options={groups}
        components={{
          Option: CustomOption,
          DropdownIndicator
        }}
        onChange={(option) => option && handleMenuOption(option)}
        onBlur={() => setIsMenuOpen(false)}
        isSearchable={false}
        styles={{
          container: (base) => ({
            ...base,
            borderRadius: '0',
            height: '100%',
            position: 'relative',
            width
          }),
          control: (base) => ({
            ...base,
            borderColor: 'transparent',
            borderRadius: '0',
            minHeight: '1rem',
            height: '100%',
            boxShadow: '0 0 0 1px transparent'
          }),
          groupHeading: (base) => ({
            ...base,
            color: 'var(--gray-60)',
            fontFamily: 'Rubik',
            fontSize: '0.75rem',
            fontWeight: 'bold',
            paddingBottom: '0.5rem'
          }),
          menu: (base) => ({ ...base, width: '15rem' }),
          option: (base) => ({
            ...base,
            backgroundColor: 'var(--white)',
            padding: '0.4rem 1.5rem 0.4rem 1rem',
            '&:hover': {
              backgroundColor: 'var(--gray-20)',
              cursor: 'pointer'
            }
          })
        }}
        minMenuHeight={30}
        escapeClearsValue={true}
        defaultMenuIsOpen={true}
      />
    </Container>
  )
}
