import React, { useEffect } from 'react'

import { ContactInformationFormValues } from '../../../@types/network'
import { useNetwork } from '../../../hooks/useNetwork'
import { InformationCircle } from '@somostera/tera-icons'

import { useForm } from 'core/hooks/useForm'
import countryData from './countryData.json'

import { Input } from 'core/components/Input'
import { Select } from 'core/components/Select'
import { Form, HorizontalPanel, Label, SaveButton, FindZipCodePanel } from './styles'

export const ContactInformationComponent = () => {
  const { personContactInformation, updatePersonContactInformation, email, isNewExpert, setCanShowDialogLeavingPage } =
    useNetwork()

  const handleSaveForm = () => {
    updatePersonContactInformation(data)
    setCanShowDialogLeavingPage(false)
  }

  const findedCountry = Object.values(countryData).find(
    (country) => country.pais.toLowerCase() === personContactInformation?.country?.toLowerCase()
  )

  const validCountry = findedCountry?.pais ? findedCountry.pais : 'Brasil'

  const { data, handleChange, handleSubmit, setValue, setFullData } = useForm<ContactInformationFormValues>({
    initialValues: {
      country: validCountry || 'Brasil',
      phoneNumber: personContactInformation?.phoneNumber || '',
      email: email || personContactInformation?.email
    },
    onSubmit: handleSaveForm
  })

  useEffect(() => {
    if (personContactInformation && data.email !== personContactInformation.email) {
      setFullData({
        country: validCountry || 'Brasil',
        phoneNumber: personContactInformation.phoneNumber,
        email: personContactInformation.email
      })
    }

    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personContactInformation])
  useEffect(() => {
    if (isNewExpert && personContactInformation.email === undefined) {
      setFullData({
        country: 'Brasil',
        phoneNumber: '',
        email: email
      })
    }
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNewExpert])

  const handleFormChange = (inputName: any) => (e: any) => {
    setValue(inputName, e.target.value)
    if (e.target.value !== '') setCanShowDialogLeavingPage(true)
    else {
      setCanShowDialogLeavingPage(false)
      handleChange(inputName)
    }
  }

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Label>Celular</Label>
        <HorizontalPanel>
          <Select
            name="countryCode"
            items={Object.values(countryData).map((country) => ({
              value: country.pais,
              name: country.pais
            }))}
            placeholder="Escolha uma das opções"
            selectedValue={data.country}
            onChange={handleFormChange('country')}
            selectStyle={{ height: '3rem', width: '10rem' }}
          />
          <Input
            name="phone"
            type="tel"
            placeholder="(XX) XXXXX-XXXX"
            onChange={handleFormChange('phoneNumber')}
            value={data.phoneNumber}
            containerStyle={{ width: '18rem' }}
          />
        </HorizontalPanel>
        <Input
          name="email"
          type="email"
          label="E-mail*"
          placeholder="Digite o e-mail"
          onChange={handleFormChange('email')}
          value={data.email}
          containerStyle={{ width: '35rem' }}
          readOnly={true}
          disabled={true}
        />

        <FindZipCodePanel>
          <InformationCircle color="var(--blue-primary)" size={20} />
          <span style={{ marginLeft: '0.25rem' }}>
            O e-mail não é editável. Caso precise trocá-lo, faça sua solicitação
            <a href="https://forms.gle/64cQQUscUxhapRmH8" target="blank">
              aqui.
            </a>
          </span>
        </FindZipCodePanel>
        <SaveButton width={isNewExpert ? '5.5rem' : '12rem'} type="submit">
          {isNewExpert ? 'Próximo' : 'Salvar e Continuar'}
        </SaveButton>
      </Form>
    </>
  )
}
