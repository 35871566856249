import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const Container = styled.div`
  background-color: #ededed;
  width: 100%;
  display: flex;
  min-height: calc(100vh - 158px);
  height: 100%;
  gap: 60px;
`

export const BoardAside = styled.aside`
  background-color: #dbdbdb;
  width: 448px;
  height: calc(100vh - 289px);

  margin-top: 10px;
  margin-left: 12px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  border-radius: 4px;

  position: fixed;

  section {
    display: flex;
    flex-direction: column;
    gap: 12px;

    overflow-x: hidden;
    overflow-y: scroll;
    padding-right: 12px;

    &::-webkit-scrollbar-track {
      background-color: #f2f2f2;
    }

    &::-webkit-scrollbar {
      width: 4px;
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #171717;
      width: 4px;
      height: 112.25px;
    }
  }
`

export const Content = styled.div`
  margin-top: 18px;
  margin-bottom: 51px;
  width: 640px;

  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-left: 520px;
`
export const FilterContiner = styled.div`
  display: flex;

  > button {
    background: #8ffe81;
    width: 171px;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
`
export const MoreFiltersContainer = styled.div`
  background: #fff;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  /* padding: 1rem; */
  position: absolute;
  z-index: 0;
  width: 350px;
  top: -45px;
  left: 0px;
  max-height: 450px;
  overflow-y: scroll;
  overflow-x: none;
  &::-webkit-scrollbar-track {
      background-color: #f2f2f2;
    }

    &::-webkit-scrollbar {
      width: 4px;
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #171717;
      width: 4px;
      height: 10px;
    }
  }

  section {
    display: flex;
    align-items: center;
    gap: 10px;

    font-size: 16px;
    font-family: 'Inter';
    font-weight: 700;
    line-height: 24px;

    text-transform: uppercase;

    /* margin-bottom: 16px; */

    color: #171717;
    padding: 1rem;
    background: #fcfcfc;

    > svg {
      width: 10px;
    }
  }

  h3 {
    color: var(--neutral12);
    font-weight: 900;
    font-size: 14px;
    line-height: 1rem;
    /* margin: 2rem 0 1rem; */
    text-transform: uppercase;
    font-family: 'Inter';
    letter-spacing: 0.01rem;
    padding: 1rem;
    background: #fcfcfc;

    display: flex;
    align-items: center;
    gap: 12px;

    &:first-of-type {
      margin-top: 0;
    }
  }

  > div {
    // margin: 2rem 0 1rem 0;

    &:first-of-type {
      margin-top: 0;
    }

    label {
      margin: 1rem 0;
    }
  }
`
interface CheckboxContainerProps {
  showFilterActive: boolean
}

export const CheckboxContainer = styled.div<CheckboxContainerProps>`
  padding: 0 1rem;
  border: ${(props) => (props.showFilterActive ? '1px solid #e8e8e8' : 'none')};
`
export const ButtonFilterContainer = styled.div`
  padding: 0.5rem 1rem;
`
export const ButtonFilter = styled.button`
  width: 100%;
  padding: 0.5rem;

  background: #000;
  color: #fff;
  text-transform: uppercase;
  font-weight: 700;
  font-family: 'Rubik';
  font-size: 14px;
  line-height: 24px;
`
export const Button = styled(Link)`
  background: #8ffe81;
  text-transform: uppercase;

  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  font-family: 'Rubik';

  text-decoration: none;
  color: #000;

  min-width: 171px;
  padding: 8px 24px 8px 24px;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  /* text-decoration: none;
  text-transform: uppercase;
  font-size: 0.875rem;
  line-height: 1.5rem;
  font-weight: 700;
  color: white;
  background: #000;
  padding: 0.5rem 1.5rem 0.5rem 1.75rem;

  display: flex;
  align-items: center;
  gap: 12px; */
`

export const ResetFilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;

  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;

  p {
    margin-bottom: 6px;
    padding: 8px 12px;
    margin-right: 8px;
    text-transform: capitalize;

    border: 2px solid #000000;
    border-radius: 16px;
    font: 400 14px/16px 'Inter';

    display: flex;
    align-items: center;
    gap: 5px;

    span {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 1px;
      margin: 0;
      border-radius: 50%;
      background-color: #171717;
      color: var(--white);
      /* width: 13px;
  height: 13px; */
      margin-left: 4px;
      padding: 4px;
    }
  }
`

export const ResetButton = styled.button`
  outline-offset: 0px;
  display: flex;
  /* flex-direction: row;
  align-items: center; */
  background-color: var(--black);
  color: var(--white);
  padding: 8px 8px 8px 12px;
  border-radius: 16px;
  cursor: pointer;
  font-size: 0.875rem;
  line-height: 1.125rem;
  border: 2px solid #000;

  align-items: center;

  gap: 5px;
  margin: 0;
  font-family: 'Inter';
  font-size: 14px;
  line-height: 16px;
  margin-top: 0px;

  font-weight: 400;
`

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 215px);
  background-color: #fff;
  width: 100%;

  h1 {
    color: #000;
    z-index: 3;
  }
`
