import styled, { css } from 'styled-components'
import TextareaAutosize from 'react-textarea-autosize'

interface ContainerProps {
  isfocused: 'true' | 'false'
  minheight: string
}

export const Container = styled(TextareaAutosize)<ContainerProps>`
  background: transparent;
  border: 1px solid #dbdbdb;
  border-radius: 0.125rem;
  font-size: 0.875rem;
  height: 100%;
  line-height: 1.313rem;
  min-height: ${(props) => props.minheight};
  outline: 0;
  overflow-y: hidden;
  padding: 0.75rem 1rem;
  position: relative;
  resize: none;
  width: 100%;
  color: #292929;
  font-weight: 400;
  font-family: 'Inter';

  &::placeholder {
    color: #707070;
    font-family: 'Rubik';
    font-size: 1rem;
  }

  ${(props) =>
    props.isfocused &&
    props.isfocused === 'true' &&
    css`
      border-color: #3af221;
    `}

  svg {
    position: absolute;
    right: 0.625rem;
    top: calc(50% - 12px);

    &:hover {
      filter: brightness(0.8);
    }
  }
`

export const InputInformationContainer = styled.div`
  align-items: baseline;
  display: flex;
  gap: 0.25rem;

  margin-top: 16px;

  label {
    font-family: 'Rubik';
    color: '#000';
    display: block;
    font-size: 0.75rem;
    font-weight: bold;
    line-height: 0.75rem;
    text-transform: uppercase;
    margin-bottom: 0.5rem;
    letter-spacing: 0.01;
  }

  p {
    color: var(--gray-80);
    font-weight: 300;
    font-size: 1rem;
    line-height: 1.25rem;
  }

  span {
    color: #cc3314;
    font-weight: 700;
    font-size: 0.75rem;
    line-height: 0.75rem;
  }
`

export const Error = styled.p`
  color: var(--orange);
  font-size: 1rem;
  line-height: 1.25rem;
  margin-top: 0.5rem;
`
