import { Container } from './styles'
import { PortfolioData } from 'modules/network/@types/portfolio'
import React, { ReactNode } from 'react'

interface PortfolioCellProps {
  row: PortfolioData
  children: ReactNode
}

export const PortfolioCell = ({ row, children }: PortfolioCellProps) => {
  const portfolioClassUrl = `${row.id}`

  return <Container to={portfolioClassUrl}>{children}</Container>
}
