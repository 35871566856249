import { Node } from '@tiptap/core'
import { ReactNodeViewRenderer } from '@tiptap/react'
import { PageBreakComponent } from './PageBreakComponent'

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    addPageBreak: {
      addPageBreak: () => ReturnType
    }
  }
}

export const PageBreak = Node.create({
  name: 'pageBreak',
  group: 'block',
  atom: true,
  addCommands() {
    return {
      addPageBreak:
        () =>
        ({ commands }) =>
          commands.insertContent('<page-break></page-break>')
    }
  },
  parseHTML() {
    return [{ tag: 'page-break' }]
  },
  renderHTML() {
    return ['page-break']
  },
  addNodeView() {
    return ReactNodeViewRenderer(PageBreakComponent)
  }
})
