import React, { ReactNode } from 'react'

import { Container } from './styles'

interface BlockCellProps {
  url: string
  children: ReactNode
  onClick: () => void
}

export const ContainerCell = ({ url, children, onClick }: BlockCellProps) => {
  return (
    <Container to={url} onClick={onClick}>
      {children}
    </Container>
  )
}
