import { InitializeBuildBlockService, BuildingBlock, BlockType } from '@somostera/tera-database'

import { db } from 'core/config/firebase'

const buildingblockService = InitializeBuildBlockService.initialize(db)

export const findBuildingBlockById = async (buildingBlockId: string) => {
  return buildingblockService.findById(buildingBlockId)
}

export const findAllBuildingBlock = async () => {
  return await buildingblockService.findAll()
}

export const updateBuildingBlock = async (buildingBlock: BuildingBlock) => {
  await buildingblockService.update(buildingBlock)
}

export const FindBuildingBlockByType = async (type: BlockType) => {
  return buildingblockService.findByType(type)
}

export const createBuildingBlock = async (buildingBlock: BuildingBlock) => {
  return await buildingblockService.create(buildingBlock)
}

export const deleteBuildingBlock = async (blockId: string) => {
  await buildingblockService.delete(blockId)
}
