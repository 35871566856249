import styled from 'styled-components'

export const Container = styled.div`
  background: #fcfcfc;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 56px);
  width: 100%;
  > section {
    width: 640px;
    margin-left: 280px;

    h2 {
      font-family: 'Inter';
      font-size: 32px;
      line-height: 32px;
      letter-spacing: -0.02;
      font-weight: 900;
      text-transform: uppercase;

      padding-top: 40px;
    }
  }
`

export const FormContainer = styled.form`
  background: #fff;
  border: 1px solid #f2f2f2;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.04);
  padding: 20px;
  margin-top: 40px;

  > h3 {
    text-transform: uppercase;
    font-family: 'Rubik';
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.01em;
    margin-bottom: 16px;
  }
`

export const ToggleSwitchContainer = styled.div`
  display: flex;
  flex-direction: column;
  > h3 {
    margin-top: 16px;
    font-size: 12px;
    font-weight: 600;
    line-height: 12px;
    letter-spacing: 0.01;
    text-transform: uppercase;
    color: #000;
  }

  > div {
    margin-top: 16px;
    display: flex;
    gap: 8px;
    align-items: center;

    > label {
      margin-top: 0;
    }

    > span {
      font-size: 14px;
      line-height: 16px;
      font-weight: 400;
    }
  }
`
export const Footer = styled.footer`
  background-color: #000;
  width: 100%;
  height: 56px;

  display: flex;
  justify-content: flex-end;

  position: sticked;
  top: 50px;

  > button {
    height: 100%;
    padding: 12px 64px 12px 64px;
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 700;
    background-color: #000;
    color: #fff;

    &:last-child {
      background-color: #8ffe81;
      color: #000;
    }
  }
`
export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
`
