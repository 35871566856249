import styled from 'styled-components'
interface ContainerProps {
  size: 'small' | 'large'
}

export const Container = styled.div<ContainerProps>`
  align-items: center;
  background: var(--gray-100);
  display: flex;
  height: 10rem;
  justify-content: space-between;
  width: ${(props) => (props.size === 'small' ? '20.5rem' : '35rem')};

  > div {
    display: flex;
    flex-direction: column;
    font-size: 1rem;
    font-weight: 300;
    height: 100%;
    justify-content: space-between;
    line-height: 1.25rem;
    padding: 1rem;
    text-transform: uppercase;
    width: 86%;

    p {
      color: var(--white);
    }

    span {
      color: var(--gray-60);
    }
  }

  button {
    background: var(--gray-80);
    align-self: flex-end;
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 5rem;
  }
`
