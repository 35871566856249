import styled from 'styled-components'

export const Container = styled.div`
  border-radius: 0.25rem;
  width: 22rem;
  padding: 2rem 0 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  svg {
    margin-bottom: 1rem;
  }

  h2 {
    font-size: 1rem;
    margin-bottom: 0.5rem;
    text-transform: uppercase;
    padding: 0 2rem;
  }

  p {
    line-height: 1.5rem;
    color: var(--gray-60);
    padding: 0 2rem;
  }
`
