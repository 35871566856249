import React from 'react'

import { NetworkData } from '../../@types/network'

import { Container, ProfileData, ProfilePicture, ContainerProfilePicture } from './styles'

import { ProfileIcon } from '@somostera/tera-icons'

interface NameCellProps {
  row: NetworkData
}

export const NameCell = ({ row }: NameCellProps) => {
  return (
    <Container>
      {row.profilePicture.length === 0 ? (
        <ProfileIcon size={44} />
      ) : (
        <>
          <ContainerProfilePicture>
            <ProfilePicture src={row.profilePicture} alt={row.name} />
          </ContainerProfilePicture>
        </>
      )}

      <ProfileData>
        <p>{row.name}</p>
        <span>{row.email}</span>
      </ProfileData>
    </Container>
  )
}
