import styled from 'styled-components'

export const Container = styled.div`
  height: 100vh;
  width: 100%;
  background: #ededed;

  display: flex;
`

export const Aside = styled.aside`
  background: #dbdbdb;
  width: 448px;

  margin-top: 12px;
  margin-left: 12px;
  margin-bottom: 12px;

  padding: 12px;

  display: flex;
  flex-direction: column;
  gap: 20px;
`

export const Content = styled.div`
  background: #ededed;
  width: 100%;

  margin-top: 12px;
  padding: 12px 20px;
`
export const Box = styled.div`
  border: 1px solid white;
  background-color: white;
  padding: 12px;
`
export const Footer = styled.footer`
  background-color: #000;
  width: 100%;
  height: 56px;

  display: flex;
  justify-content: flex-end;

  position: sticky;
  bottom: 0;

  > button {
    height: 100%;
    padding: 12px 64px 12px 64px;
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 700;
    background-color: #000;
    color: #fff;

    &:last-child {
      background-color: #8ffe81;
      color: #000;
    }
  }
`
