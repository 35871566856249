import React, { useCallback, useEffect, useState } from 'react'
// import { MediaStatus, MediaType } from '@somostera/tera-models-ts'
import { X } from '@somostera/tera-icons'

import { InitializeMediaService, Media } from '@somostera/tera-database'
import { MediaStatus } from '@somostera/tera-database/dist/Medias/Domain/enum/MediaStatus'
import { MediaType } from '@somostera/tera-database/dist/Medias/Domain/enum/MediaType'
import { db } from 'core/config/firebase'
import { useForm } from 'core/hooks/useForm'

import { MediaFormValues } from 'modules/contents/@types/contents'

import { Button } from 'core/components/Button'
import { Input } from 'core/components/Input'
import { AutosizeInput } from 'core/components/AutosizeInput'
import { FileInput } from 'core/components/FileInput'
import { TagsInput } from 'core/components/TagsInput'

import {
  ButtonsContainer,
  Container,
  ContentContainer,
  Form,
  FormTitle,
  UploadContainer,
  UploadContentContainer,
  VideoTabsContainer
} from './styles'

type MediaInfo = {
  url: string
  videoDuration?: number
  fileName?: string
  fileSize?: number
  mimeType?: string
  thumbnail?: string
}

interface AddMediaModalProps {
  isOpen: boolean
  onRequestClose: () => void
  fileType: MediaType
  acceptedFiles: string
  articleId: string
  onAddMedia: (mediaInfo: MediaInfo) => void
}

const mediaService = InitializeMediaService.initialize(db)

export function AddMediaModal({
  isOpen,
  onRequestClose,
  fileType,
  acceptedFiles,
  articleId,
  onAddMedia
}: AddMediaModalProps) {
  const [tabIndex, setTabIndex] = useState(1)
  const [videoTabIndex, setVideoTabIndex] = useState(0)

  const [videoDuration, setVideoDuration] = useState(0)
  const [thumbnailUrl, setThumbnailUrl] = useState('')
  const [fileUrl, setFileUrl] = useState('')
  const [fileSize, setFileSize] = useState(0)
  const [mimeType, setMimeType] = useState('')
  const [fileName, setFileName] = useState('')

  const {
    data: { name, description, tags },
    handleChange,
    handleSubmit,
    errors,
    setValue,
    reset
  } = useForm<MediaFormValues>({
    initialValues: {
      name: '',
      description: '',
      tags: []
    },
    onSubmit: () => {
      onSubmit({
        name,
        description,
        tags
      })

      onRequestClose()
    },
    validations: {
      name: { required: { value: true, message: 'Preencha o campo obrigatório.' } },
      description: { required: { value: true, message: 'Preencha o campo obrigatório.' } },
      tags: {
        custom: {
          isValid: (values) => values?.length > 0,
          message: 'Preencha o campo obrigatório.'
        }
      }
    }
  })

  const onSubmit = async (data: MediaFormValues) => {
    const { name, description, tags } = data

    if (name !== '' && description !== '' && tags.length > 0 && fileUrl !== '') {
      const media: Media = {
        name,
        description,
        tags,
        type: fileType,
        status: MediaStatus.PUBLISHED,
        url: fileUrl,
        articleId: [articleId],
        size: `${fileSize}`,
        duration: `${videoDuration}`,
        fileName,
        mimetype: mimeType,
        createdAt: new Date(),
        thumbnail: thumbnailUrl
      }

      await mediaService.save(media)

      switch (fileType) {
        case MediaType.IMAGE || MediaType.THUMBNAIL_VIDEO:
          onAddMedia({ url: fileUrl })
          break
        case MediaType.VIDEO:
          if (!videoDuration) {
            return
          }
          onAddMedia({ url: fileUrl, videoDuration, thumbnail: thumbnailUrl })
          break
        default:
          onAddMedia({ url: fileUrl, fileName: name, fileSize: fileSize || 0, mimeType: mimeType || '' })
          break
      }

      resetFormState()
      onRequestClose()
    }
  }

  const resetFormState = useCallback(() => {
    setFileName('')
    setVideoDuration(0)
    setThumbnailUrl('')
    setFileUrl('')
    setFileSize(0)
    setMimeType('')
    reset()
    setVideoTabIndex(0)
    setTabIndex(1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setFileName('')
    setVideoDuration(0)
    setThumbnailUrl('')
    setFileUrl('')
    setFileSize(0)
    setMimeType('')
    resetFormState()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleCancelAddMedia = useCallback(() => {
    setFileName('')
    setVideoDuration(0)
    setThumbnailUrl('')
    setFileUrl('')
    setFileSize(0)
    setMimeType('')
    resetFormState()
    onRequestClose()
  }, [onRequestClose, resetFormState])

  return (
    <Container
      isOpen={isOpen}
      onRequestClose={handleCancelAddMedia}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
      tabIndex={tabIndex}
    >
      <button type="button" onClick={handleCancelAddMedia} className="react-modal-close">
        <X color="var(--gray-40)" size={24} />
      </button>

      <UploadContainer isVisible={true}>
        <Form
          onSubmit={(event) => {
            handleSubmit(event)
          }}
        >
          <UploadContentContainer>
            <VideoTabsContainer>
              <button
                type="button"
                className={videoTabIndex === 0 ? 'selected' : ''}
                onClick={() => setVideoTabIndex(0)}
              >
                {fileType === 'video'
                  ? 'Preview do Vídeo'
                  : fileType === 'image'
                  ? 'Preview da Imagem'
                  : 'Preview do Download'}
              </button>
              {fileType === 'video' && (
                <button
                  type="button"
                  className={videoTabIndex === 1 ? 'selected' : ''}
                  onClick={() => setVideoTabIndex(1)}
                >
                  Thumbnail
                </button>
              )}
            </VideoTabsContainer>

            <ContentContainer isVisible={videoTabIndex === 0}>
              <FileInput
                name="fileUrl"
                fileType={fileType}
                acceptedFilesRegex={acceptedFiles}
                onFileUrlChange={(fileUrl: string) => setFileUrl(fileUrl)}
                onFileSizeChange={(size: number) => setFileSize(size)}
                onMimeTypeChange={(mime: string) => setMimeType(mime)}
                onVideoDurationChange={(duration: number) => setVideoDuration(duration)}
                onFileNameChange={(fileName: string) => setFileName(fileName)}
              />
            </ContentContainer>

            <ContentContainer isVisible={videoTabIndex === 1}>
              <FileInput
                name="fileUrl"
                fileType={MediaType.THUMBNAIL_VIDEO}
                acceptedFilesRegex="image/*"
                onFileUrlChange={(thumbnail: string) => setThumbnailUrl(thumbnail)}
                onFileSizeChange={(size: number) => setFileSize(size)}
                onMimeTypeChange={(mime: string) => setMimeType(mime)}
                onVideoDurationChange={(duration: number) => setVideoDuration(duration)}
              />
            </ContentContainer>

            <hr />

            <ContentContainer isVisible>
              <FormTitle>
                <h2>Inserir informações das mídias</h2>
                <span>
                  Preencha os dados abaixo. Use palavras-chaves que vão te ajudar a encontrar a mídia depois na
                  biblioteca
                </span>
              </FormTitle>
              <Input
                type="text"
                name="name"
                label={`Título ${
                  fileType === 'video' ? 'do vídeo' : fileType === 'image' ? 'da imagem' : 'do arquivo'
                }`}
                placeholder={`Digite o título ${
                  fileType === 'video' ? 'do vídeo' : fileType === 'image' ? 'da imagem' : 'do arquivo'
                }`}
                value={name}
                onChange={handleChange('name')}
                errors={errors.name}
              />
              <AutosizeInput
                type="text"
                name="description"
                label={`Descrição ${
                  fileType === 'video' ? 'do vídeo' : fileType === 'image' ? 'da imagem' : 'do arquivo'
                }`}
                placeholder="Escreva uma descrição simples que te ajude a encontrar a mídia depois :)"
                characterCount={true}
                maxLength={140}
                value={description}
                onChange={handleChange('description')}
                minHeight={'4rem'}
                errors={errors.description}
              />
              <TagsInput
                name="tags"
                label="Tags"
                placeholder="Ex: '#aprendizagem' '#discussão' '#post-it'..."
                value={tags || []}
                onTagsChange={(tags: string[]) => setValue('tags', tags)}
                errors={errors.tags}
              />
            </ContentContainer>
          </UploadContentContainer>

          <ButtonsContainer>
            <Button
              buttonStyle={{
                background: 'white',
                border: '2px solid var(--gray-80)',
                height: '3rem',
                marginRight: '1rem',
                width: '11rem'
              }}
              onClick={handleCancelAddMedia}
            >
              Cancelar
            </Button>
            <Button
              disabled={!fileUrl || !name || !description || tags?.length === 0 || !tags?.length}
              type="submit"
              buttonStyle={{
                color: 'var(--white)',
                height: '3rem',
                width: '11rem'
              }}
            >
              Adicionar
            </Button>
          </ButtonsContainer>
        </Form>
      </UploadContainer>
    </Container>
  )
}
