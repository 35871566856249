/* eslint-disable prettier/prettier */
import React, { useCallback, useState } from 'react'
import { Footer } from './styles'
import { Board } from 'modules/courses/components/Board'
import { useParams } from 'react-router-dom'
import { CoursesService } from 'modules/courses/services/courses'
import { useAuth } from 'core/hooks/useAuth'
import {
  ConfirmationModal as PublishConfirmationModal,
  ConfirmationModal as CancelModal
} from 'modules/courses/components/ConfirmationModal'

interface EditCourseProps {
  handleCancelEdit: () => void
}

export function EditCourse({ handleCancelEdit }: EditCourseProps) {
  const { userFirebase } = useAuth()
  const { courseId } = useParams()
  const [itemsToSave, setItemsToSave] = useState([])
  const [isConfirmPublishModalOpen, setIsConfirmPublishModalOpen] = useState(false)
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false)

  const handleCloseConfirmPublishModal = useCallback(() => {
    setIsConfirmPublishModalOpen(false)
  }, [])

  const handleCloseCancelModal = useCallback(() => {
    setIsCancelModalOpen(false)
  }, [])

  function handleAddOrRemoveItems(itemsToSave: []) {
    setItemsToSave(itemsToSave)
  }

  async function handleSaveItems() {
    const token = await userFirebase.getIdToken()
    const courseItems = itemsToSave.map((item, index) => ({
      index,
      itemType: 'article',
      itemId: item.id,
      courseId,
      articleRef: `/articles/${item.id}`,
      liveRef: null,
      isOptional: item?.isOptional || false
    }))

    await CoursesService.saveCourseItems(token, courseId!, courseItems)
    setIsConfirmPublishModalOpen(true)
  }

  return (
    <>
      <PublishConfirmationModal
        confirmButtonText="ok, fechar"
        messageType="Confirmation"
        messageTitle="Itens adicionados com sucesso"
        messageDescription="O conteúdo do curso foi atualizado. "
        onRequestClose={handleCloseConfirmPublishModal}
        isOpen={isConfirmPublishModalOpen}
        onConfirm={handleCancelEdit}
      />
      <CancelModal
        confirmButtonText="Voltar para edição"
        cancelButtonText="Sair sem salvar"
        messageType="RedWarning"
        messageTitle="Deseja sair sem salvar?"
        messageDescription="As alterações feitas serão perdidas."
        onRequestClose={handleCloseConfirmPublishModal}
        isOpen={isCancelModalOpen}
        onCancel={() => handleCancelEdit()}
        onConfirm={() => {
          handleCloseCancelModal()
        }}
      />
      <Board handleAddOrRemoveItems={handleAddOrRemoveItems} />{' '}
      <Footer>
        <button onClick={() => setIsCancelModalOpen(true)}>Cancelar</button>
        <button onClick={handleSaveItems}>Salvar</button>
      </Footer>
    </>
  )
}
