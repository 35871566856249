import styled from 'styled-components'
import { NodeViewWrapper } from '@tiptap/react'

export const NodeContainer = styled(NodeViewWrapper)`
  display: flex;
  flex-direction: column;
  position: relative;

  .expert-tip-area {
    border: 2px solid var(--gray-20);
    width: 100%;
    background-color: #07006e;
    color: var(--white);
    padding: 2rem;
    border-radius: 1rem;

    &.place-with-expert {
      width: 70%;
      border-radius: 1rem 0 1rem 1rem;
    }
  }

  .expert-image {
    display: block;
    width: 100px !important;
    height: 100px !important;
    border-radius: 50%;
    position: absolute;
    right: 0;
  }

  .arrow-right {
    width: 0;
    height: 0;
    border-top: 0px solid transparent;
    border-bottom: 30px solid transparent;
    border-left: 38px solid #07006e;
    position: absolute;
    right: 110px;
    top: 2px;
  }

  span {
    display: block;
    font-size: 1rem;
    font-weight: 700;
    color: var(--white);
    margin-bottom: 1rem;
  }

  button {
    align-self: flex-end;
    background: var(--black);
    box-shadow: 0 6px 12px rgba(51, 51, 51, 0.25);
    border: 0;
    border-radius: 8px;
    color: var(--white);
    cursor: pointer;
    font-family: Rubik;
    font-size: 16px;
    font-weight: 700;
    height: 48px;
    line-height: 20px;
    margin-top: 16px;
    padding: 8px;
    text-transform: uppercase;
    width: 146px;

    &:hover {
      transform: translate(1px, 1px);
    }
  }
`
