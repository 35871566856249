import styled from 'styled-components'

export const Form = styled.form`
  hr {
    background: var(--gray-20);
    height: 1px;
    margin: 2rem 0 1.5rem;
    width: 100%;
  }
`

export const HorizontalPanel = styled.div`
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;

  > div {
    margin-top: 0.25rem;
  }
`

interface LabelProps {
  errors?: string
}

export const Label = styled.label<LabelProps>`
  color: ${(props) => (props.errors !== undefined ? 'var(--orange)' : 'var(--gray-60)')};
  display: block;
  font-size: 0.75rem;
  font-weight: bold;
  line-height: 1.25rem;
  text-transform: uppercase;
  margin: 1.5rem 0 0.25rem;
`

interface NextButtonProps {
  width: string
}

export const SaveButton = styled.button<NextButtonProps>`
  background: var(--black);
  border-radius: 0.25rem;
  color: var(--white);
  font-size: 0.875rem;
  font-weight: bold;
  height: 2rem;
  line-height: 120%;
  margin-top: 1.5rem;
  text-transform: uppercase;
  width: ${(props) => props.width};
`

interface CheckboxContainerProps {
  checked: boolean
  width: number | string
  errors?: string
  disabled?: boolean
}

export const CheckboxContainer = styled.div<CheckboxContainerProps>`
  height: 2rem;
  margin-right: 1rem;
  width: ${(props) => props.width && props.width};

  label {
    cursor: ${(props) => (props.disabled !== undefined && props.disabled ? 'not-allowed' : 'pointer')};
    background-color: ${(props) => (props.disabled !== undefined && props.disabled ? 'var(--gray-40)' : '')};
    border-color: ${(props) => (props.disabled !== undefined && props.disabled ? 'var(--gray-60)' : '')};
  }

  label span {
    font-weight: ${(props) => (props.checked ? 'bold' : 'normal')};
  }
`
export const Error = styled.p`
  color: var(--orange);
  font-size: 1rem;
  line-height: 1.25rem;
  margin-top: 0.5rem;
`
